/*
 * ____USAGE:___
 * input: array of ojects called as 'haystack'
 * returns: array objects
 *
 * filterReplace({ id: 'xyz123', key: 'style', replaceWith: { newProp: 'Newly added property' } })([ { id: 'xyz123', style: {} } ])
 */

const findAndReplaceByKey = ({
  id,
  key,
  replaceString = '',
  replaceObj = {},
}) => haystack => {
  const result = haystack.reduce((acc, currentItem) => {
    if (currentItem.id === id) {
      if (replaceString) {
        const newItem = {
          ...currentItem,
          [key]: replaceString,
        };
        return [...acc, newItem];
      }
      if (replaceObj && Object.keys(replaceObj).length) {
        const newItem = {
          ...currentItem,
          [key]: {
            ...replaceObj,
          },
        };
        return [...acc, newItem];
      }
    }
    return [...acc, currentItem];
  }, []);
  return result;
};

export default findAndReplaceByKey;
