/* eslint-disable */
import customElement from '../../ui/shadow';
import { factory } from '../../ui/dom';
import { validateNode, hasAttr } from '../../ui/utils';
import showPause from './showPause';
import nodes from './nodes';

const adControls = ({
  isPaused: IS_PAUSED,
  active: ADBREAK_START,
}) => element => {
  // Node to attach onto
  const foundElement = document.querySelector(element);

  // Early return prevent element from rendering
  if (!foundElement) {
    return;
  }

  customElement(element)(target => {
    // Validate nodes
    const foundNodesInTree = validateNode(hasAttr(nodes))(target);
    if (foundNodesInTree) {
      if (!ADBREAK_START || !IS_PAUSED) {
        showPause(false)(target);
      }
      if (ADBREAK_START && IS_PAUSED) {
        showPause(true)(target);
      }
      return;
    }
    factory(target)(nodes);
    if (IS_PAUSED) {
      showPause(true)(target);
    }
    if (!IS_PAUSED) {
      showPause(false)(target);
    }
  });
};

export default adControls;
