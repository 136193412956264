const showOverlay = isActive => target => {
  const overlayTarget = target.querySelector('#ad__overlay');
  if (!isActive && overlayTarget) {
    overlayTarget.style.setProperty('display', 'none');
  }
  if (isActive && overlayTarget) {
    overlayTarget.style.setProperty('display', 'block');
  }
};

export default showOverlay;
