import get from 'lodash/get';
import {
  SessionLive,
  SessionVOD,
  SessionNLSO,
  Session,
  SessionResult,
  SessionProperties,
  DEBUG_ALL,
  YoLog,
  SessionDVRLive,
} from '@yospace/admanagement-sdk';
import yospaceSessionManager from './yospaceSessionManager';
import YospaceAnalyticEventObserver from './yospaceAnalyticEventObserver';
import yospaceLog from './yospaceLog';
import DefaultPlaybackPolicy from './DefaultPlaybackPolicy';
import { getPlaybackItemData } from '../../playbackUtils';
import { NODE_ENV } from '../../constants';
import { isReady } from '../../utils';
import { getYSSourceUrl } from './utils';

class YospaceServiceV3 extends EventTarget {
  ysSessionManager = null;
  ysPlayerListenersReady = false;
  storeMetaDataQueue = [];
  pollerTimer = null;
  isStarted = false;
  isBuffer = false;
  isMuted = false;
  lastPosition = 0;
  logData = null;
  timeoutLimitTime = 5500;
  // bufferTimeoutWorkaround = null;

  getSessionManager = () => {
    return this.ysSessionManager;
  };

  getUpdatedCastData = () => {
    return this.castData;
  };

  setYospaceReady = () => {
    this.isYospaceReady = true;
  };

  close() {
    if (this.ysSessionManager) {
      this.ysSessionManager.handleComplete();
      this.ysSessionManager = null;
    }

    // Reset to initial values except ysPlayerListenersReady
    if (this.pollerTimer) {
      clearInterval(this.pollerTimer);
      this.pollerTimer = null;
    }
    this.storeMetaDataQueue = [];
    this.isStarted = false;
    this.isBuffer = false;
    this.isMuted = false;
    this.lastPosition = 0;
  }

  onYospaceError = (session, reason, yspErrorCallback) => {
    session && session.shutdown();
    yospaceLog(this.logData, this.segmentService).error('init', this.logData);
    yspErrorCallback(reason);
  };

  onYospaceReady = (session, yspSuccessCallback, yspErrorCallback) => {
    const state = session.getSessionResult();
    const result = session.getResultCode();

    if (state === SessionResult.INITIALISED) {
      if (session instanceof Session) {
        // add reportPlayerEvent method to session object for backwards compatibility to v1
        session.reportPlayerEvent = (event, playhead) => {
          session.onPlayerEvent(event, playhead);
        };

        this.ysSessionManager = yospaceSessionManager({
          sessionManager: session,
          yspSdkV3: this.yspSdkV3,
        });

        const adListenerCallback = new YospaceAnalyticEventObserver({
          player: this.player,
          playbackData: this.playbackData,
          setState: this.setState,
          getState: this.getState,
          sessionManager: session,
          segmentService: this.segmentService,
          logData: this.logData,
        });

        session.addAnalyticObserver(adListenerCallback);
        session.setPlaybackPolicyHandler(new DefaultPlaybackPolicy());

        // Player check before continuing
        // setup of yopspace session manager.
        // Do not continue if player has been destroyed
        try {
          const playbackUrl = get(session, 'playbackUrl', '');
          if (playbackUrl) {
            this.castData.media.contentUrl = playbackUrl;
            // eslint-disable-next-line no-use-before-define
            this.addPlayerListeners();
            yspSuccessCallback(session);
          }
        } catch (e) {
          // console.log('==========ERROR===========', e);
          this.onYospaceError(session, e, yspErrorCallback);
          yospaceLog(this.logData, this.segmentService).error('init', this.logData);
        }
      } else {
        console.log('Shutting down SDK on non-yospace stream');
        this.onYospaceError(session, null, yspErrorCallback);
      }
    } else if (state === SessionResult.NO_ANALYTICS) {
      console.log(`No analytics. Code: ${result}`);
      this.onYospaceError(session, null, yspErrorCallback);
    } else {
      console.log(`Failed. Code: ${result}`);
      this.onYospaceError(session, null, yspErrorCallback);
    }
  };

  init = async ({
    player,
    userData,
    assetData = {},
    playbackData,
    castData,
    setState,
    getState,
    segmentService,
    yspSdkV3,
    yspSuccessCallback,
    yspErrorCallback,
    config,
  }) => {
    try {
      this.player = player;
      this.setState = setState;
      this.getState = getState;
      this.segmentService = segmentService;
      this.yspSdkV3 = yspSdkV3;
      this.castData = castData;
      this.playbackData = playbackData;
      this.isYospaceReady = false;

      const isNonProd = NODE_ENV !== 'production';
      YoLog.setDebugFlags(isNonProd && DEBUG_ALL);

      this.close();
      const { protocol, url: sourceUrl } = getPlaybackItemData(playbackData);
      const url = getYSSourceUrl(protocol.toLowerCase(), sourceUrl);
      // TODO: change to DVRLIVE once backend fixes cmaf time issues
      // const playbackMode = 'DVRLIVE';
      const { live: isLive } = playbackData;
      this.isLive = isLive;
      const playbackMode = isLive ? 'Live' : 'VoD';

      this.logData = {
        playbackData,
        userData,
        assetData,
        castData,
      };

      const timeout = config && config.getConfig && config.getConfig('yspSdkTimeout');

      const newmode = playbackMode;
      const newurl = url;

      if (newmode && newurl) {
        const properties = new SessionProperties();
        properties.setRequestTimeout(timeout || this.timeoutLimitTime);
        properties.setAllowCorsForAnalytics(true);
        properties.setUserAgent(window.navigator.userAgent);
        switch (newmode) {
          case 'VoD': {
            SessionVOD.create(newurl, properties).then(
              session =>
                this.onYospaceReady(
                  session,
                  yspSuccessCallback,
                  yspErrorCallback,
                ),
              session => this.onYospaceError(session, null, yspErrorCallback),
            );
            break;
          }
          case 'NLSO': {
            SessionNLSO.create(newurl, properties).then(
              session =>
                this.onYospaceReady(
                  session,
                  yspSuccessCallback,
                  yspErrorCallback,
                ),
              session => this.onYospaceError(session, null, yspErrorCallback),
            );
            break;
          }
          case 'Live': {
            SessionLive.create(newurl, properties).then(
              session =>
                this.onYospaceReady(
                  session,
                  yspSuccessCallback,
                  yspErrorCallback,
                ),
              session => this.onYospaceError(session, null, yspErrorCallback),
            );
            break;
          }
          case 'DVRLIVE': {
            SessionDVRLive.create(
              `${newurl}${'&yo.pdt=true&yo.lpa=dur'}`,
              properties,
            ).then(
              session =>
                this.onYospaceReady(
                  session,
                  yspSuccessCallback,
                  yspErrorCallback,
                ),
              session => this.onYospaceError(session, null, yspErrorCallback),
            );
            break;
          }
          case 'Pause':
            /* YSSessionManager.DEFAULTS.LOW_FREQ = 5000;
                  sessMgr = YSSessionManager.createForLivePause(
                    url,
                    null,
                    onYospaceReady,
                  );
                  return Promise.resolve(sessMgr); */
            break;
          default:
            yospaceLog(this.logData, this.segmentService).error('init', this.logData);
            this.onYospaceError();
        }

        await isReady(() => this.isYospaceReady, {
          delay: 100,
          timeout: this.timeoutLimitTime,
        });
      }
    } catch (e) {
      this.onYospaceError(null, e, yspErrorCallback);
      yospaceLog(this.logData, this.segmentService).error('init', this.logData);
    }
  };

  addPlayerListeners = () => {
    // Only ever occur once in a single cast session to avoid
    // dupe event listeners getting attached to the current player
    if (!this.ysPlayerListenersReady) {
      this.player.onAll(() => {});

      this.player.onTimeUpdate(() => {});

      this.player.onLoadStart(() => {
        this.ysSessionManager?.handleLoadStart();
      });

      this.player.onMediaStatus(event => {
        const { volume: { muted } = {} } = event;
        if (
          this.isStarted &&
          typeof muted === 'boolean' &&
          this.isMuted !== muted
        ) {
          this.isMuted = muted;
          this.ysSessionManager?.handleMute(this.isMuted);
        }
      });

      this.player.onBuffering(event => {
        const { isBuffering } = event;
        if (this.isStarted) {
          if (isBuffering) {
            this.ysSessionManager?.handleBuffer();
          } else if (this.isBuffer && !isBuffering) {
            this.ysSessionManager?.handleResume({ fromBuffering: true });
          }

          this.isBuffer = isBuffering;
        }
      });

      this.player.onPlaying(() => {
        if (!this.isStarted) {
          this.ysSessionManager?.handlePlaying();
          this.isStarted = true;
        }
      });

      this.player.onPause(() => {
        if (this.isStarted) {
          this.ysSessionManager?.handlePause();
        }
      });

      this.player.onPlay(() => {
        if (this.isStarted) {
          this.ysSessionManager?.handleResume({ fromBuffering: false });
        }
      });

      this.player.onMeta(event => {
        const metaData = this.ysSessionManager?.getMetaData(event);
        if (metaData) {
          const { currentTime } = this.player.getPlayerElement();
          const { startTime } = metaData;
          if (
            this.player.getState() === 'PLAYING' &&
            this.isStarted &&
            !this.isBuffer &&
            currentTime >= startTime
          ) {
            // We need to clear the previous emsgs if there is
            // before reporting latest emsg
            if (this.storeMetaDataQueue.length) {
              this.storeMetaDataQueue.forEach((item, key) => {
                this.ysSessionManager?.handleMeta(item);
                this.storeMetaDataQueue.splice(key, 1);
              });
            }

            this.ysSessionManager?.handleMeta(metaData);
          } else {
            this.storeMetaDataQueue.push(metaData);
          }
        }
      });

      this.player.onSeeking(event => {
        if (this.isStarted) {
          // For live assets, manually fetch the current position of seek start
          // as we need the DVR time
          const { currentPosition } =
            (this.isLive
              ? { currentPosition: this.player.getDvrTime() }
              : event) || {};

          this.ysSessionManager?.handleSeeking({ currentPosition });
        }
      });

      this.player.onSeeked(event => {
        if (this.isStarted) {
          // For live assets, manually fetch the current position of seek end
          // as we need the DVR time
          const { currentPosition } =
            (this.isLive
              ? { currentPosition: this.player.getDvrTime() }
              : event) || {};

          this.ysSessionManager?.handleSeeked({ currentPosition });
        }
      });

      this.player.onComplete(event => {
        if (this.isStarted) {
          this.ysSessionManager?.handleComplete(event);
          this.isStarted = false;
          clearInterval(this.pollerTimer);
          this.pollerTimer = null;
        }
      });

      this.ysPlayerListenersReady = true;
    }

    // Start the poller
    this.pollerTimer = setInterval(() => {
      const playerState = this.player.getState();

      if (
        this.storeMetaDataQueue.length &&
        playerState === 'PLAYING' &&
        this.isStarted &&
        !this.isBuffer
      ) {
        this.storeMetaDataQueue.forEach((item, key) => {
          const { currentTime } = this.player.getPlayerElement();
          const { startTime } = item;
          if (currentTime >= startTime) {
            this.ysSessionManager?.handleMeta(item);
            this.storeMetaDataQueue.splice(key, 1);
          }
        });
      }
      if (playerState !== 'PAUSED') {
        let currentPosition = null;
        if (this.isLive) {
          // currentPosition = this.player.getDvrTime();
          if (this.player.getPlayerElement()) {
            this.ysSessionManager?.updatePosition({
              currentPosition: this.player.getPlayerElement().currentTime,
            });
          } else {
            currentPosition = this.player.getDvrTime();
          }
        } else {
          currentPosition = this.player.getCurrentTime();
        }
        if (currentPosition && this.lastPosition !== currentPosition) {
          this.lastPosition = currentPosition;
          this.ysSessionManager?.updatePosition({ currentPosition });
        }
      }

      /* const currentPosition = this.isLive
        ? this.player.getPlayerElement()
          ? this.player.getPlayerElement().currentTime
          : this.player.getDvrTime()
        : this.player.getCurrentTime();
      if (this.lastPosition !== currentPosition && playerState !== 'PAUSED') {
        this.lastPosition = currentPosition;
        this.ysSessionManager.updatePosition({ currentPosition });
      } */
    }, 250);
  };
}

export default YospaceServiceV3;
