import includes from 'lodash/includes';
import Conviva from '../vendors/conviva/Conviva_Chromecast_2.137.0.35367';
import {
  PLAYER_NAME,
  NODE_ENV,
  CONVIVA_TOUCHSTONE_SERVICE_URL,
  CONVIVA_CUSTOMER_KEY,
  SIMULATE_ERROR,
} from './constants';
import deviceInfo from './utils/deviceInfo';

function getSettings() {
  const gatewayUrl = CONVIVA_TOUCHSTONE_SERVICE_URL && {
    gatewayUrl: CONVIVA_TOUCHSTONE_SERVICE_URL, // set gatewayUrl during test phase only
  };
  return {
    ...gatewayUrl,
  };
}

const isNonProd = NODE_ENV !== 'production';

function init() {
  const settings = getSettings();
  Conviva.LivePass.setConnectionType('WiFi');
  Conviva.LivePass.toggleTraces(isNonProd);
  Conviva.LivePass.initWithSettings(CONVIVA_CUSTOMER_KEY, settings);
}

class ConvivaAnalytics {
  constructor({ playerManager, startImmedietly = true }) {
    this.playerManager = playerManager;
    this.startImmedietly = startImmedietly;
    this.streamer = null;
    init();
  }

  inSession() {
    return this.sessionId != null;
  }

  getContentInfo(metadata = {}) {
    const defaultTags = {}; // TODO: Figure out default tags
    const parsedDeviceName = deviceInfo();
    const isChromecastLegacy = includes(['Chromecast 1'], parsedDeviceName)
      ? 'chromecast_legacy'
      : false;
    const {
      contentId,
      contentUrl,
      customData: { assetTitle, categoryTitle, userId, analyticUserId },
      streamType,
    } = this.playerManager.getMediaInformation();
    const { initialBandwidth } = this.playerManager.getPlaybackConfig();
    // When metadata is not provided, use the playerManager's media information
    const {
      assetName = assetTitle,
      defaultReportingResource = '',
      tags = defaultTags,
      viewerId = analyticUserId || userId,
      streamUrl = contentUrl || contentId,
      duration,
      isLive = streamType === 'LIVE',
      playerName = PLAYER_NAME,
    } = metadata;
    const extraDescriptor = `${
      isNonProd ? ` - ${isChromecastLegacy || playerName}` : ''
    }`;
    const contentInfo = new Conviva.ConvivaContentInfo();
    contentInfo.assetName = `${contentId} [${categoryTitle}] ${assetName}${extraDescriptor}`;
    contentInfo.category = categoryTitle; // Depends on what works - Remove one or the other
    contentInfo.assetCategory = categoryTitle; // Depends on what works - Remove one or the other
    contentInfo.defaultReportingResource = defaultReportingResource;
    contentInfo.tags = {
      ...tags,
      isLoggedIn: tags.isLoggedIn
        ? tags.isLoggedIn.toString().toUpperCase()
        : 'FALSE',
    };
    contentInfo.duration = duration; // in seconds
    contentInfo.isLive = isLive;
    contentInfo.streamUrl = streamUrl;
    // OSN-1165 - chromecast gen 1 devices should be separated into different player name
    contentInfo.playerName = isChromecastLegacy || playerName;
    contentInfo.viewerId = viewerId && viewerId.toString(); // Conviva requires this to be a string
    contentInfo.defaultBitrateReportingKbps = initialBandwidth;

    return contentInfo;
  }

  start(metadata) {
    const contentInfo = this.getContentInfo(metadata);
    try {
      this.streamer = this.startImmedietly
        ? new Conviva.ConvivaCastV3StreamerProxy(this.playerManager)
        : null;
      this.sessionId = Conviva.LivePass.createSession(
        this.streamer,
        contentInfo,
      );
      if (SIMULATE_ERROR) {
        throw new Error('>>> Simulated Error - Conviva');
      }
    } catch (err) {
      this.sendError(err);
    }
  }

  resume() {
    if (!this.inSession()) {
      return;
    }
    if (this.startImmedietly) {
      return;
    }
    this.streamer = new Conviva.ConvivaCastV3StreamerProxy(this.playerManager);
    Conviva.LivePass.attachStreamer(this.sessionId, this.streamer);
  }

  stop() {
    if (!this.inSession()) {
      return;
    }
    Conviva.LivePass.cleanupSession(this.sessionId);
    this.sessionId = null;
  }

  updateMetadata(metadata) {
    if (!this.inSession()) {
      return;
    }
    if (!this.streamer) {
      this.resume();
    }
    const newMetadata = this.getContentInfo(metadata);
    Conviva.LivePass.updateContentMetadata(this.sessionId, newMetadata);
  }

  // eslint-disable-next-line class-methods-use-this, no-unused-vars
  processMessage(message) {
    // const { type } = message;
    // Keeping this processMessage function for other message types.
    // By default, Conviva already captures bitrate changes. So no need to handle it here.
    // if (type === 'BITRATE_CHANGED') {
    //   const { totalBitrate } = message;
    //   Conviva.LivePass.setBitrate(this.sessionId, totalBitrate / 1000);
    // }
  }

  sendMessage(message) {
    if (!this.inSession()) {
      return;
    }
    this.processMessage(message);
  }

  sendError(err) {
    if (!this.inSession()) {
      return;
    }
    // err is of type cast.framework.events.ErrorEvent
    // https://developers.google.com/cast/docs/reference/caf_receiver/cast.framework.events.ErrorEvent
    const {
      detailedErrorCode = '',
      reason = '',
      message = '',
      severity = Conviva.StreamerError.SEVERITY_FATAL,
    } = err;
    const errReason = `${reason ? `- ${reason}` : ''}`;
    const errMessage = `${message ? `- ${message}` : ''}`;
    const errFullMessage = `${detailedErrorCode} ${errReason} ${errMessage}`;
    const errSeverity = Conviva.StreamerError[severity];
    Conviva.LivePass.reportError(this.sessionId, errFullMessage, errSeverity);
    Conviva.LivePass.cleanupSession(this.sessionId);
  }
}

export default ConvivaAnalytics;
