// const senderPayload = {
//   requestId: 2,
//   type: `LOAD`,
//   media: {
//     contentId: `https://livemedia-sport-optus.akamaized.net/hls/live/693609/OS1/master.m3u8`,
//     streamType: `LIVE`,
//     contentType: `application/vnd.apple.mpegurl`,
//     metadata: {
//       metadataType: 0,
//       images: [
//         {
//           url: `https://d2ilvueyw6rrf9.cloudfront.net/posters/OS1.jpg`,
//           width: 0,
//           height: 0
//         },
//         {
//           url: ``,
//           width: 0,
//           height: 0
//         }
//       ],
//       title: `Optus Sport 1`,
//       subtitle: `Casting to Office TV`
//     },
//     duration: 0,
//     customData: {
//       api: {},
//       assetTitle: `Optus Sport 1`,
//       authToken: ``,
//       castDeviceId: `62c8d275cc09ea2bdec1515234c6dc11`,
//       castDeviceVersion: `5`,
//       deviceId: `bb513fdcaa42500b`,
//       idToken: ``,
//       imagePackId: ``,
//       liveBroadcastDateTime: ``,
//       modelName: `Chromecast`,
//       optaMatchId: ``,
//       playerEventInterval: 0,
//       quality: `auto`,
//       refreshToken: ``,
//       senderDeviceId: `bb513fdcaa42500b`,
//       senderDeviceLabel: `samsung SM-T350`,
//       senderOS: `android`,
//       senderVersion: `2.9.0`,
//       stadiumName: ``,
//       startAtLive: true,
//       thinclientUrl: `https://livemedia-sport-optus.akamaized.net/hls/live/693609/OS1/master.m3u8`,
//       userId: -1,
//       videoSource: `vimond`
//     }
//   },
//   autoplay: true,
//   currentTime: 0,
//   playbackRate: 1
// };

// export default senderPayload;
