/* eslint-disable */
/*
 * ___USAGE:___
 * input: node (ex: use query selector)
 * output: node
 *
 * addAttribute('id', 'some-attribute')(document.querySelector('thing'))
 */

const addAttribute = attr => nodeElem => {
  if (!nodeElem || !attr) {
    return;
  }
  nodeElem.setAttribute('id', attr);
  return nodeElem;
};

export default addAttribute;
