// const drModeAssets = [
//   {
//     channel: {
//       id: `OS1`,
//       name: `Optus Sport 1`,
//       logo: ``
//     },
//     title: `Optus Sport 1`,
//     description: `24/7 channel 1`,
//     poster: `https://d2ilvueyw6rrf9.cloudfront.net/posters/OS1.jpg`,
//     video: {
//       hls: `https://livemedia-sport-optus.akamaized.net/hls/live/693609/OS1/master.m3u8`,
//       dash: `https://livemedia-sport-optus.akamaized.net/hls/live/693609/OS1/master.m3u8`
//     }
//   },
//   {
//     channel: {
//       id: `OS2`,
//       name: `Optus Sport 2`,
//       logo: ``
//     },
//     title: `Optus Sport 2`,
//     description: `24/7 channel 2`,
//     poster: `https://d2ilvueyw6rrf9.cloudfront.net/posters/OS2.jpg`,
//     video: {
//       hls: `https://livemedia-sport-optus.akamaized.net/hls/live/693614/OS2/master.m3u8`,
//       dash: `https://livemedia-sport-optus.akamaized.net/hls/live/693614/OS2/master.m3u8`
//     }
//   },
//   {
//     channel: {
//       id: `OS3`,
//       name: `Optus Sport 3`,
//       logo: ``
//     },
//     title: `Optus Sport 3`,
//     description: `24/7 channel 3`,
//     poster: `https://d2ilvueyw6rrf9.cloudfront.net/posters/OS3.jpg`,
//     video: {
//       hls: `https://livemedia-sport-optus.akamaized.net/hls/live/693622/OS3/master.m3u8`,
//       dash: `https://livemedia-sport-optus.akamaized.net/hls/live/693622/OS3/master.m3u8`
//     }
//   },
//   {
//     channel: {
//       id: `OS4`,
//       name: `Optus Sport 4`,
//       logo: ``
//     },
//     title: `Optus Sport 4`,
//     description: `24/7 channel 4`,
//     poster: `https://d2ilvueyw6rrf9.cloudfront.net/posters/OS4.jpg`,
//     video: {
//       hls: `https://livemedia-sport-optus.akamaized.net/hls/live/693623/OS4/master.m3u8`,
//       dash: `https://livemedia-sport-optus.akamaized.net/hls/live/693623/OS4/master.m3u8`
//     }
//   }
// ];

// export default drModeAssets;
