/* eslint-disable no-param-reassign */
import { fromPairs, includes, each, get, set, sortBy, toPairs } from 'lodash';
import {
  PlayerEvent,
  TimedMetadata,
  ViewSize,
} from '@yospace/admanagement-sdk';

const yospaceSessionManager = ({ sessionManager, yspSdkV3 }) => {
  let YSPlayerEvents = null;
  if (yspSdkV3) {
    YSPlayerEvents = PlayerEvent;
  } else {
    YSPlayerEvents = window.YSPlayerEvents;
  }

  const shutdown = () => {
    sessionManager?.shutdown();
    sessionManager = null;
  };

  const getMetaData = ({ type, messageData, startTime }) => {
    if (type.toLowerCase() === 'emsg') {
      let messageDataDecoded = '';
      let messageDataDecodedArr;
      let emsgMetaDataObj = {};

      // Transform messageData arraybuffer into string if cue is a raw ArrayBuffer
      if (typeof messageData === 'object' && messageData.byteLength) {
        if (messageData.length > 0) {
          for (let i = 0; i < messageData.length; ++i) {
            if (messageData[i] >= 32 && messageData[i] < 127) {
              messageDataDecoded += String.fromCharCode(messageData[i]);
            }
          }
        }
      } else {
        messageDataDecoded = messageData;
      }
      if (messageDataDecoded) {
        if (includes(messageDataDecoded, ',')) {
          // i.e. messageDataDecoded = 'YMID=xxx,YDUR=xxx,...'
          messageDataDecodedArr = messageDataDecoded.split(',');

          // i.e. messageDataDecodedArr  = [ 'YMID=xxx', 'YDUR=xxx', ... ]
          each(messageDataDecodedArr, value => {
            const messageArr = value.split('=');
            const messageKey = get(messageArr, '0');
            const messageValue = get(messageArr, '1');

            if (messageKey && messageValue) {
              set(emsgMetaDataObj, messageKey, messageValue);
            }
          });
        } else {
          // i.e. messageDataDecoded = 'ID3QYMID459099922YCSP459099922YTYPSYSEQ2:8YDUR0.00'
          emsgMetaDataObj = {
            YMID: '',
            YCSP: '',
            YSEQ: '',
            YTYP: '',
            YDUR: '',
          };

          Object.keys(emsgMetaDataObj).forEach(key => {
            emsgMetaDataObj[key] = messageDataDecoded.indexOf(key);
          });
          // sort object by descending order of value
          emsgMetaDataObj = fromPairs(
            sortBy(toPairs(emsgMetaDataObj), 1).reverse(),
          );
          // i.e. emsgMetaDataObj = {YDUR: 42, YSEQ: 35, YTYP: 30, YCSP:17, YMID:4}

          Object.keys(emsgMetaDataObj).forEach(key => {
            const index = emsgMetaDataObj[key];
            emsgMetaDataObj[key] = messageDataDecoded.slice(index + 4);
            messageDataDecoded = messageDataDecoded.substring(0, index);
          });
          // i.e. emsgMetaDataObj = {YDUR: '0.00', YSEQ: '2:8', YTYP: 'S', YCSP: '459099922', YMID: '459099922'}
        }
      }

      emsgMetaDataObj.startTime = startTime;

      return emsgMetaDataObj;
    }

    return null;

    // const encoder = new TextDecoder('utf-8');
    // const emsg =
    //   typeof messageData === 'string'
    //     ? messageData
    //     : encoder.decode(messageData);
    //
    // let emsgData;
    //
    // if (/,/.test(emsg) && /=/.test(emsg)) {
    //   emsgData = { startTime: startTime.toString() };
    //   emsg.split(',').forEach(item => {
    //     const [key, val] = item.split('=');
    //     if (checkIsNeededId3Type(key)) {
    //       emsgData[key] = val;
    //     }
    //   });
    // }
    // // console.log('<<<<<<<<<< emsgData >>>>>>>>>>>', emsgData);
    // return emsgData;
  };

  const handleMeta = data => {
    if (yspSdkV3) {
      const id3 = data;
      const startTime = id3.startTime * 1000;
      id3.startTime = startTime;
      const meta = TimedMetadata.createFromMetadata(
        id3.YMID,
        id3.YSEQ,
        id3.YTYP,
        id3.YDUR,
        id3.startTime,
      );
      sessionManager.onTimedMetadata(meta);
    } else {
      sessionManager?.reportPlayerEvent(YSPlayerEvents.METADATA, data);
    }
  };

  const handlePlaying = () => {
    sessionManager?.reportPlayerEvent(YSPlayerEvents.START);
  };

  const handlePause = () => {
    sessionManager?.reportPlayerEvent(YSPlayerEvents.PAUSE);
  };

  const handleComplete = () => {
    sessionManager?.reportPlayerEvent(YSPlayerEvents.END);
    shutdown();
  };

  const handleResume = ({ fromBuffering }) => {
    if (fromBuffering) {
      sessionManager?.reportPlayerEvent(YSPlayerEvents.CONTINUE);
    } else {
      sessionManager?.reportPlayerEvent(YSPlayerEvents.RESUME);
    }

    // Never report START event for resume from buffering/paused events
    // sessionManager.reportPlayerEvent(YSPlayerEvents.START);
  };

  const handleMute = isMute => {
    if (yspSdkV3) {
      sessionManager.onVolumeChange(isMute);
    } else {
      sessionManager?.reportPlayerEvent(YSPlayerEvents.MUTE, isMute);
    }
  };

  const handleBuffer = () => {
    sessionManager?.reportPlayerEvent(YSPlayerEvents.STALL);
  };

  const handleSeeking = ({ currentPosition }) => {
    if (!yspSdkV3) {
      sessionManager?.reportPlayerEvent(
        YSPlayerEvents.SEEK_START,
        currentPosition,
      );
    }
  };

  const handleSeeked = ({ currentPosition }) => {
    if (yspSdkV3) {
      sessionManager.reportPlayerEvent(
        YSPlayerEvents.SEEK,
        currentPosition * 1000,
      );
    } else {
      sessionManager?.reportPlayerEvent(
        YSPlayerEvents.SEEK_END,
        currentPosition,
      );
    }
  };

  const updatePosition = ({ currentPosition }) => {
    if (yspSdkV3) {
      sessionManager.onPlayheadUpdate(Math.round(currentPosition * 1000));
    } else {
      sessionManager?.reportPlayerEvent(
        YSPlayerEvents.POSITION,
        currentPosition,
      );
    }
  };

  const handleLoadStart = () => {
    if (yspSdkV3) {
      sessionManager.onViewSizeChange(ViewSize.MAXIMISED);
    } else {
      sessionManager?.reportPlayerEvent(YSPlayerEvents.FULLSCREEN, true);
    }
  };

  return {
    ...sessionManager,
    //
    getMetaData,
    updatePosition,
    //
    handleLoadStart,
    handleMeta,
    handlePlaying,
    handlePause,
    handleResume,
    handleComplete,
    handleBuffer,
    handleSeeking,
    handleSeeked,
    handleMute,
  };
};

export default yospaceSessionManager;
