import { PLAYER_NAME, PLATFORM, APP_VERSION, NODE_ENV } from '../../constants';
import {
  // createCustomTags,
  getUserId,
  getWatchMode,
  getModelName,
  getContentId,
  getMediaCustomData,
} from '../../requestUtils';
// import { userAgentInfo } from '../../uiUtils';

function toUIIDformat(str) {
  return str.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
}

function getCastDeviceId(str) {
  return str
    ? toUIIDformat(str.replace('com.google.cast.CastDevice:', ''))
    : '';
}

export function getPlaybackItem(playbackData = {}) {
  const { items: { item = [{}] } = {} } = playbackData;
  return item;
}

export function getPlaybackItemData(playbackData) {
  const [{ license = {}, ...rest }] = getPlaybackItem(playbackData);

  const licenseData = {};
  Object.keys(license).forEach(key => {
    let newKey = key;
    if (/^@/.test(key)) {
      newKey = key.substring(1);
    }
    licenseData[newKey] = license[key];
  });

  return {
    ...rest,
    license: licenseData,
  };
}

export function getUserProps({ userData, castData }) {
  const {
    analyticUserId,
    userId,
    subscriptions: [
      { snapshotOffer: { tags: userTags, type: userType } = {} } = {},
    ] = [{}],
  } = userData;

  const userIdfromCast = getUserId(castData);
  const { analyticUserId: analyticUserIdFromMedia } = getMediaCustomData(
    castData,
  );

  return {
    userId: analyticUserId || userId || userIdfromCast,
    userTags,
    userType,
    analyticUserId: analyticUserId || analyticUserIdFromMedia,
  };
}

// export function getPlayerProps() {
//   const playerVersion = getPlayerInfo();
//   const playerType = 'Optus_JWPlayer';

//   return {
//     video_player: {
//       playerVersion,
//       playerType
//     }
//   };
// }

export function getAppProps() {
  return {
    appName: PLAYER_NAME,
    platform: PLATFORM,
    environment: NODE_ENV,
    appVersion: APP_VERSION,
    advertConsent: false,
  };
}

export function getDeviceProps(castData) {
  const watchMode = getWatchMode(castData);
  const modelName = getModelName(castData);

  const {
    castDeviceId: advertId,
    deviceId,
    senderOS,
    senderVersion,
    senderDeviceLabel: castDevice,
  } = getMediaCustomData(castData);

  const os = `${senderOS} ${senderVersion}`;

  return {
    deviceId,
    senderOS,
    senderVersion,
    OSVersion: os,
    os,
    modelName,
    deviceTime: new Date().getTime().toString(),
    castDevice,
    advertId: getCastDeviceId(advertId),
    watchMode,
  };
}

export function getAssetProps({
  assetData = {},
  castData = {},
  playbackData = {},
}) {
  const assetId = getContentId(castData);
  const {
    license: { uri: drmLicenseUrl },
  } = getPlaybackItemData(playbackData);
  const {
    media: { contentUrl: streamUrl },
  } = castData;
  const { id: asset_id } = assetData;
  const {
    accessGroups,
    broadcastStartTime,
    categoryId: categorySlug,
    live: isLive,
    startDate: publishStartDate,
    //
    broadcastEndTime,
    categoryTitle,
    channel,
    createdAt,
    description,
    duration,
    externalId = asset_id,
    id,
    isInTransition,
    seoMetaDescription,
    seoTitle,
    slug,
    source,
    tags,
    title,
    type,
    updatedAt,
    useVccVod,
  } = assetData;

  const { title: assetTitle } = assetData;

  return {
    //
    assetId: asset_id || assetId,
    assetTitle,
    streamUrl,
    drmLicenseUrl,
    //
    accessGroups,
    broadcastEndTime,
    broadcastStartTime,
    categorySlug,
    categoryTitle,
    channel,
    createdAt,
    description: description || '',
    duration,
    externalId,
    id,
    isInTransition,
    isLive,
    publishStartDate,
    seoMetaDescription,
    seoTitle,
    slug,
    source,
    tags,
    title,
    type,
    updatedAt,
    useVccVod,
  };
}

export function getCommonProps({
  castData = {},
  playbackData = {},
  assetData = {},
  userData = {},
}) {
  const assetProps = getAssetProps({
    assetData,
    playbackData,
    castData,
  });

  const appProps = getAppProps(castData);
  const deviceProps = getDeviceProps(castData);
  const userProps = getUserProps({
    userData,
    castData,
  });
  return {
    appProps,
    deviceProps,
    userProps,
    assetProps,
  };
}
