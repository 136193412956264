/* eslint-disable */
const hasAttr = (elements = []) => {
  const initialValue = elements[0];
  if (!initialValue) {
    return null;
  }
  const foundAttr = initialValue.attr;
  if (foundAttr) {
    return foundAttr;
  }
};

export default hasAttr;
