/* eslint-disable */
import customElement from '../../ui/shadow';
import { factory, addTextNode } from '../../ui/dom';
import {
  validateNode,
  hasAttr,
  timeFormatter,
  calculateRemainingTime,
} from '../../ui/utils';
import { showTimeDuration } from '../../uiUtils';
import showOverlay from './showOverlay';
import showNativeControls from './showNativeControls';
import nodes from './nodes';

const adCountDown = ({
  start: AD_START_TIME,
  duration: AD_DURATION,
  time: CURRENT_TIME,
  active: ADBREAK_START,
}) => element => {
  // Node to attach onto
  const foundElement = document.querySelector(element);

  // Early return prevent element from rendering
  if (!foundElement) {
    return;
  }

  customElement(element)(target => {
    if (!ADBREAK_START) {
      // Hide Overlay
      showOverlay(false)(target);
      // Show native controls
      showNativeControls(true)(target);
      // Show / Hide Time Duration
      showTimeDuration(true);
      return;
    }

    // Perform time calculations
    const remaining = calculateRemainingTime({
      duration: AD_DURATION,
      time: CURRENT_TIME,
      start: AD_START_TIME,
    });

    if (remaining <= 0) {
      // Hide Overlay
      showOverlay(false)(target);
      // Show native controls
      showNativeControls(true)(target);
      // Show / Hide Time Duration
      showTimeDuration(true);
      return;
    }

    showNativeControls(false)(target);
    showOverlay(true)(target);
    showTimeDuration(false);

    // Get Text Element re-render text within node
    const getTextElement = target.querySelector('#ad__text');
    if (getTextElement && ADBREAK_START) {
      const HTMLDot = `Ad &#183; &nbsp;`;
      addTextNode(`${HTMLDot}${timeFormatter(Math.round(remaining))}`)(
        getTextElement,
      );
    }

    // Determine if inital node already exist
    const shouldNotRender = validateNode(hasAttr(nodes))(target);
    if (shouldNotRender) {
      return;
    }

    factory(target)(nodes);
  });
};

export default adCountDown;
