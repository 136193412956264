const store = () => {
  let maxLength = 0;
  let localStore = [];

  const getStore = () => localStore;

  const getItem = (key = 0) => {
    if (localStore.length > 0) {
      return localStore[key];
    }

    return null;
  };

  const getLastItem = () => {
    const localStoreLength = localStore.length;
    if (localStoreLength > 0) {
      return getItem(localStoreLength - 1);
    }

    return null;
  };

  const addItem = item => {
    if (!maxLength) {
      return;
    }

    if (localStore.length >= maxLength) {
      localStore.shift();
    }

    localStore.push(item);
  };

  const reset = () => {
    localStore.length = 0;
    localStore = [];
  };

  const init = ({ number }) => {
    reset();

    maxLength = number;
  };

  return {
    init,
    reset,
    getItem,
    getLastItem,
    addItem,
    getStore,
  };
};

export default store;
