/* eslint-disable */
const calculateRemainingTime = ({ duration = 0, time = 0, start = 0 }) => {
  const getRemainder = start - time;
  const remaining = getRemainder + duration;
  if (remaining <= 0 || isNaN(remaining)) {
    return 0;
  }
  return remaining;
};

export default calculateRemainingTime;
