const nodes = [
  {
    elem: 'div',
    attr: 'ad__controls',
    text: '',
    style: {
      display: 'none',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      height: '100%',
    },
  },
  {
    elem: 'div',
    attr: 'pause_container',
    text: '',
    style: {
      position: 'absolute',
      left: 'calc(50% - 50px)',
      bottom: '2%',
      width: '120px',
      height: '120px',
      background: 'transparent',
      'z-index': '1',
    },
  },
  {
    elem: 'div',
    attr: 'pause_button',
    text: '',
    style: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 'auto',
      height: '48px',
      'border-style': 'double',
      'border-width': '0px 0px 0px 34px',
      'border-color': 'white',
    },
  },
];

export default nodes;
