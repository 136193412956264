export default async function isReady(fn, config) {
  let res;
  let err;

  const { delay = 500, timeout } = config || {};
  try {
    res = await (() => {
      return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
          const result = fn();
          if (result) {
            clearInterval(timer);
            resolve(result);
          }
        }, delay);
        if (timeout) {
          setTimeout(() => {
            clearInterval(timer);
            reject(new Error('Error timeout'));
          }, timeout);
        }
      });
    })();
  } catch (e) {
    err = e;
  }

  return [res, err];
}
