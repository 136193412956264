import { getPlaybackItemData } from '../../playbackUtils';
import { getYSSourceUrl, playbackMode } from './utils';
import yospaceStart from './yospaceStart';
import yospaceLog from './yospaceLog';
import yospaceSessionManager from './yospaceSessionManager';
import yospaceSessionManagerCallback from './yospaceSessionManagerCallback';

/* global YSLiveSession */

class YospaceService extends EventTarget {
  ysSessionManager = null;
  ysPlayerListenersReady = false;
  storeMetaDataQueue = [];
  pollerTimer = null;
  isStarted = false;
  isBuffer = false;
  isMuted = false;
  lastPosition = 0;
  // bufferTimeoutWorkaround = null;

  getSessionManager() {
    return this.ysSessionManager;
  }

  async start({
    player,
    userData,
    assetData = {},
    playbackData,
    castData,
    timeoutLimit,
    setState,
    getState,
    segmentService,
    config,
  }) {
    // Shutdown and remove any existing ys session manager before starting a new one
    this.close();

    const { live: isLive } = playbackData;
    const { protocol, url: sourceUrl } = getPlaybackItemData(playbackData);
    const url = getYSSourceUrl(protocol.toLowerCase(), sourceUrl);

    const timeout = config?.getConfig?.('yspSdkTimeout');

    const updateCastData = castData;
    const { sessionManager, isTimeout, isInitError } = await yospaceStart({
      url,
      playbackMode: playbackMode(isLive),
      timeoutLimit: timeoutLimit || timeout,
    });

    if (sessionManager) {
      updateCastData.media.contentUrl = sessionManager.masterPlaylist();

      // Set the new ys session manager
      this.ysSessionManager = yospaceSessionManager({
        sessionManager,
      });

      const logData = {
        playbackData,
        userData,
        assetData,
        castData: updateCastData,
      };

      const ysSessionManagerCallback = yospaceSessionManagerCallback({
        player,
        playbackData,
        setState,
        getState,
        sessionManager: this.ysSessionManager,
        segmentService,
        logData,
      });

      sessionManager.registerPlayer(ysSessionManagerCallback);
      if (!(sessionManager.session instanceof YSLiveSession)) {
        ysSessionManagerCallback.UpdateTimeline(
          sessionManager.session.timeline,
        );
      }

      this.addPlayerListeners({
        player,
        isLive,
      });
    }

    const yospaceLogService = yospaceLog({
      playbackData,
      userData,
      assetData,
      castData: updateCastData,
    }, segmentService);

    let ysInitError;
    if (isTimeout) {
      ysInitError = 'Error Yospace initialisation timeout';
      yospaceLogService.error('timeout');
    } else if (isInitError) {
      ysInitError = 'Error Yospace initialisation';
      yospaceLogService.error('init');
    }

    return [ysInitError, updateCastData];
  }

  close() {
    if (this.ysSessionManager) {
      this.ysSessionManager.handleComplete();
      this.ysSessionManager = null;
    }

    // Reset to initial values except ysPlayerListenersReady
    if (this.pollerTimer) {
      clearInterval(this.pollerTimer);
      this.pollerTimer = null;
    }
    this.storeMetaDataQueue = [];
    this.isStarted = false;
    this.isBuffer = false;
    this.isMuted = false;
    this.lastPosition = 0;
  }

  addPlayerListeners({ player, isLive }) {
    // Only ever occur once in a single cast session to avoid
    // dupe event listeners getting attached to the current player
    if (!this.ysPlayerListenersReady) {
      player.onAll(() => {
        // const { type } = event;
        // console.log(event);
      });

      player.onTimeUpdate(() => {
        // if (this.isBuffer) {
        //   this.isBuffer = false;
        //   this.getSessionManager()?.handleResume(true);
        // }
        // this.getSessionManager()?.updatePosition(event);
        // if (
        //   this.storeMetaDataQueue.length &&
        //   player.getState() === 'PLAYING' &&
        //   this.isStarted
        // ) {
        //   this.storeMetaDataQueue.forEach((item, key) => {
        //     this.getSessionManager()?.handleMeta(item);
        //     this.storeMetaDataQueue.splice(key, 1);
        //   });
        // }
      });

      player.onLoadStart(() => {
        this.getSessionManager()?.handleLoadStart();
      });

      player.onMediaStatus(event => {
        const { volume: { muted } = {} } = event;
        if (
          this.isStarted &&
          typeof muted === 'boolean' &&
          this.isMuted !== muted
        ) {
          this.isMuted = muted;
          this.getSessionManager()?.handleMute(this.isMuted);
        }
      });

      player.onBuffering(event => {
        const { isBuffering } = event;
        if (this.isStarted) {
          if (isBuffering) {
            this.getSessionManager()?.handleBuffer();
          } else if (this.isBuffer && !isBuffering) {
            this.getSessionManager()?.handleResume({ fromBuffering: true });
          }

          this.isBuffer = isBuffering;
        } else {
          // Workaround where video does not start and sticks in buffering state
          // so force to x position
          // if (isBuffering) {
          //   this.bufferTimeoutWorkaround = setTimeout(() => {
          //     if (player.getState() === 'BUFFERING' && player.getCurrentTime() === 0) { console.log('timeout called so force seeking');
          //       player.seek(2);
          //     }
          //   }, 6000)
          // } else {
          //   clearTimeout(this.bufferTimeoutWorkaround)
          // }
        }
      });

      player.onPlaying(() => {
        if (!this.isStarted) {
          this.getSessionManager()?.handlePlaying();
          this.isStarted = true;
        }

        // this.getSessionManager()?.handlePlaying();
      });

      player.onPause(() => {
        if (this.isStarted) {
          this.getSessionManager()?.handlePause();
        }
      });

      player.onPlay(() => {
        if (this.isStarted) {
          this.getSessionManager()?.handleResume({ fromBuffering: false });
        }
      });

      player.onMeta(event => {
        const metaData = this.getSessionManager()?.getMetaData(event);

        if (metaData) {
          const { currentTime } = player.getPlayerElement();
          const { startTime } = metaData;
          if (
            player.getState() === 'PLAYING' &&
            this.isStarted &&
            !this.isBuffer &&
            currentTime >= startTime
          ) {
            // We need to clear the previous emsgs if there is
            // before reporting latest emsg
            if (this.storeMetaDataQueue.length) {
              this.storeMetaDataQueue.forEach((item, key) => {
                this.getSessionManager()?.handleMeta(item);
                this.storeMetaDataQueue.splice(key, 1);
              });
            }

            this.getSessionManager()?.handleMeta(metaData);
          } else {
            this.storeMetaDataQueue.push(metaData);
          }
        }
      });

      // Replaced with onPlay
      // player.onResume(() => {
      //   if (this.isStarted) {
      //     this.getSessionManager()?.handleResume({fromBuffering: false});
      //   }
      // });

      player.onSeeking(event => {
        if (this.isStarted) {
          // For live assets, manually fetch the current position of seek start
          // as we need the DVR time
          const { currentPosition } =
            (isLive ? { currentPosition: player.getDvrTime() } : event) || {};

          this.getSessionManager()?.handleSeeking({ currentPosition });
        }
      });

      player.onSeeked(event => {
        if (this.isStarted) {
          // For live assets, manually fetch the current position of seek end
          // as we need the DVR time
          const { currentPosition } =
            (isLive ? { currentPosition: player.getDvrTime() } : event) || {};

          this.getSessionManager()?.handleSeeked({ currentPosition });
        }
      });

      player.onComplete(event => {
        if (this.isStarted) {
          this.getSessionManager()?.handleComplete(event);
          this.isStarted = false;
          clearInterval(this.pollerTimer);
          this.pollerTimer = null;
        }
      });

      this.ysPlayerListenersReady = true;
    }

    // Start the poller
    this.pollerTimer = setInterval(() => {
      const playerState = player.getState();

      if (
        this.storeMetaDataQueue.length &&
        playerState === 'PLAYING' &&
        this.isStarted &&
        !this.isBuffer
      ) {
        this.storeMetaDataQueue.forEach((item, key) => {
          const { currentTime } = player.getPlayerElement();
          const { startTime } = item;
          if (currentTime >= startTime) {
            this.getSessionManager()?.handleMeta(item);
            this.storeMetaDataQueue.splice(key, 1);
          }
        });
      }

      const currentPosition = isLive
        ? player.getDvrTime()
        : player.getCurrentTime();
      if (this.lastPosition !== currentPosition && playerState !== 'PAUSED') {
        this.lastPosition = currentPosition;
        this.getSessionManager()?.updatePosition({ currentPosition });
      }
    }, 250);
  }
}

export default YospaceService;
