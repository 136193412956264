export function createProgressUpdateResponse({ start, end }) {
  return {
    responseText: {
      progressUpdate: {
        start,
        end,
      },
    },
  };
}

export function getSeekTime({ seekTo, within }) {
  const { start = 0, end = 0 } = within || {};
  if (seekTo === 0) {
    return end;
  }
  if (seekTo === -1) {
    return start;
  }
  // From sender when tapping LIVE, sender seek time is a negative number
  // which means delay by `N` seconds from live edge.
  // WARN: Need to hard code the -10 which is what is sent as from sender.
  if (seekTo === -10) {
    return Infinity;
  }
  return end + seekTo;
}
