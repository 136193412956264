import Http from '../../Http';
import { constantsConfig } from '../../constants';

const Config = () => {
  let config;

  const fetch = async url => {
    const { apiUrls } = constantsConfig;
    const fetchUrl =
      url || `${apiUrls.newML}/api/metadata/systemConfigs/chromecastConfig`;

    try {
      config = await Http.get(fetchUrl);
      return config;
    } catch (error) {
      return null;
    }
  };

  const getConfig = param => {
    if (!param) {
      return config || constantsConfig;
    }

    if (config && typeof config[param] !== 'undefined') {
      return config[param];
    }

    return constantsConfig[param];
  };

  return {
    fetch,
    getConfig,
  };
};

export default Config;
