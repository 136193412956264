import { createCustomEvent, events } from './events';

class MessageBus extends EventTarget {
  subscribers = [];

  addSubscriber(subscriber) {
    this.subscribers = [...this.subscribers, subscriber];
  }

  setSubscribers(subscribers) {
    this.subscribers = [...this.subscribers, ...subscribers];
  }

  broadcast(data) {
    this.subscribers.forEach(subscriber => {
      subscriber.dispatchEvent(
        createCustomEvent(events.messageBus.MESSAGE, data),
      );
    });
  }
}

export default MessageBus;
