/* eslint-disable */
import { NODE_ENV } from '../../constants';
import { isReady } from '../../utils';

const isNonProd = NODE_ENV !== 'production';

const yospaceStart = ({ url, playbackMode, timeoutLimit = 5000 }) => {
  // Enable debug output, and eliminate the use of XML namespaces

  // TODO: Is this a window property // should be a variable otherwise
  YSSessionManager.DEFAULTS.DEBUGGING = isNonProd;
  YSParseUtils.NAMESPACES = false;

  const newmode = playbackMode;
  const newurl = url;
  // const newurl = getParameterByName('url') || url;
  const bypass = '';
  // const bypass = getParameterByName('bypass') || '';
  // let tlDelta = 0; // Offset of start of playback timeline
  const intervalCheckYospaceState = 100;
  const isReadyConfig = {
    delay: intervalCheckYospaceState,
    timeout: timeoutLimit,
  };

  let sessMgr = null;
  let isYospaceReady = false;
  let yospaceInitialised = false;
  let isInitError;
  let res;

  const onYospaceReady = state => {
    // console.log('SessionManager is Ready. State: ', state);

    // Set the flag to notify Yospace session ready
    isYospaceReady = true;

    if (state === YSSessionResult.INITIALISED) {
      // bindSDK();
      // const playlist = sessMgr.masterPlaylist();

      if (
        sessMgr.isYospaceStream() ||
        sessMgr.session instanceof YSVoDSession
      ) {
        // bindSDK();
        yospaceInitialised = true;

        if (newmode !== 'Live') {
          // cb_obj.UpdateTimeline(sessMgr.session.timeline);
        }
      } else {
        // console.log('Shutting down SDK on non-yospace stream');
        sessMgr.shutdown();
        sessMgr = null;
      }
    } else if (state === YSSessionResult.NO_ANALYTICS) {
      // console.log('No analytics. Code: ' + result);
      sessMgr.shutdown();
      sessMgr = null;
    } else {
      // console.log('Failed. Code: ' + result);
      sessMgr.shutdown();
      sessMgr = null;
    }

    return new Promise(resolve => {
      resolve(sessMgr);
    });
  };

  const initYospaceSdk = async () => {
    const ysArgs = [url, null, onYospaceReady];

    if (newmode === 'VoD') {
      sessMgr = YSSessionManager.createForVoD(...ysArgs);
    } else if (newmode === 'NLSO') {
      sessMgr = YSSessionManager.createForNonLinear(...ysArgs);
    } else if (newmode === 'Live') {
      sessMgr = YSSessionManager.createForLive(...ysArgs);
    } else if (newmode === 'Pause') {
      YSSessionManager.DEFAULTS.LOW_FREQ = 5000;
      sessMgr = YSSessionManager.createForLivePause(...ysArgs);
    }

    const [sessionManager, isTimeout] = await isReady(
      () => (isYospaceReady ? sessMgr : null),
      isReadyConfig,
    );

    if (res && !yospaceInitialised) {
      isInitError = true;
    }

    return {
      sessionManager,
      isTimeout,
      isInitError,
    };
  };

  if (newmode && newurl && bypass.length <= 1) {
    res = initYospaceSdk();
  }
  return res;
};

export default yospaceStart;
