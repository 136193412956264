/* eslint-disable */
import { FETCH_TIMEOUT } from './constants';

// AWS Lambda API timeout has default of 6 seconds as per Yixin
const defaultFetchTimeout = FETCH_TIMEOUT || 6000;

const defaultErrorStatus = '403';
const defaultErrorData = {
  error: {
    code: 'session_not_authenticated_blocked',
  },
};

class Http {
  static createAuthHeaders(token) {
    return {
      Authorization: token,
    };
  }

  static fetch(url, options = {}) {
    return new Promise((resolve, reject) => {
      const {
        timeout = defaultFetchTimeout,
        body,
        text,
        segmentParams,
        headers: requestHeaders,
        ...restOptions
      } = options;

      const { segmentService, segmentEvent } = segmentParams || {};

      const fetchOptions = {
        headers: requestHeaders,
        ...restOptions,
        body: body && JSON.stringify(body),
      };

      // No need to clear timeout. https://github.com/github/fetch/issues/175#issuecomment-284787564
      setTimeout(() => {
        const { segmentParams, ...optionsNoSegment } = options;

        const optionsAsString = JSON.stringify(optionsNoSegment);
        const errorMessage = `>>> Request timed out: ${url}, ${optionsAsString}`;
        reject(new Error(errorMessage));
      }, timeout);

      let clonedResponse;
      fetch(url, fetchOptions)
        .then(response => {
          clonedResponse = response.clone();

          return text ? response.text() : response.json();
        })
        .then(data => {
          const {
            ok: responseOk,
            status: responseStatus,
            statusText: responseStatusText,
            url: responseUrl,
            headers: responseHeaders,
          } = clonedResponse;

          const responseData = data || defaultErrorData;
          const statusName = !responseOk ? 'errorStatus' : 'status';

          // Segment API event
          if (segmentService && segmentEvent) {
            let finalResponseHeaders = {};
            responseHeaders &&
              responseHeaders.forEach &&
              responseHeaders.forEach(
                (val, key) => (finalResponseHeaders[key] = val),
              );

            segmentService?.handleApiEvent({
              apiUrl: responseUrl,
              segmentEventName: segmentEvent,
              requestParams: { headers: requestHeaders },
              response: {
                [statusName]: responseStatus,
                data: responseData,
                headers: finalResponseHeaders,
              },
            });
          }

          if (!responseOk) {
            // Segment API error event
            if (segmentService && segmentEvent) {
              segmentService?.handleApiErrorEvent({
                segmentEventName: 'videoPlaybackApiError',
                [statusName]: responseStatus,
              });
            }

            reject(new Error(responseStatusText));
          }

          resolve(data);
        })
        .catch(err => {
          // Segment API events
          if (segmentService && segmentEvent) {
            if (!clonedResponse) {
              // failure occurred without grabbing response data - fatal network

              // Segment API event
              segmentService?.handleApiEvent({
                apiUrl: url,
                segmentEventName: segmentEvent,
                requestParams: { headers: requestHeaders },
                response: {
                  errorStatus: defaultErrorStatus,
                  data: {
                    rawError: err,
                    ...defaultErrorData,
                  },
                  headers: {},
                },
              });
            }

            // Segment API error event
            segmentService?.handleApiErrorEvent({
              segmentEventName: 'videoPlaybackApiError',
              errorStatus: defaultErrorStatus,
            });
          }

          reject(new Error(err));
        });
    });
  }

  static get(url, options = {}) {
    return this.fetch(url, {
      ...options,
      method: 'GET',
    });
  }

  static post(url, options = {}) {
    return this.fetch(url, {
      ...options,
      method: 'POST',
    });
  }

  static put(url, options = {}) {
    return this.fetch(url, {
      ...options,
      method: 'PUT',
    });
  }

  static delete(url, options = {}) {
    return this.fetch(url, {
      ...options,
      method: 'DELETE',
    });
  }

  static patch(url, options = {}) {
    return this.fetch(url, {
      ...options,
      method: 'PATCH',
    });
  }
}

export default Http;
