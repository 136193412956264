/* eslint-disable */
/*
 * __USAGE:__
 * addTextNode('Hello world')(document.querySelector('#text'))
 */

const addTextNode = text => nodeElem => {
  // Allow to pass nodeElem through if no text is supplied as an argument
  if (!nodeElem) {
    return;
  }
  if (!text || !text.length) {
    return nodeElem;
  }
  const node = nodeElem;
  node.innerHTML = text;
  return node;
};

export default addTextNode;
