const isYspSdkEnable = ({
  assetTagsArr = [],
  playbackData = {},
  yspSdk,
  ssai,
  streamUrl,
}) => {
  return (
    (yspSdk &&
      ((assetTagsArr && assetTagsArr.includes('ssai')) ||
        (playbackData && playbackData.isSSAIEnabled))) ||
    !!streamUrl ||
    !!ssai
  );
};

export default isYspSdkEnable;
