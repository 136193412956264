/* eslint-disable no-plusplus */
/* eslint-disable no-else-return */
/* eslint-disable class-methods-use-this */
/*
 * COPYRIGHT 2020 YOSPACE TECHNOLOGIES LTD. ALL RIGHTS RESERVED.
 * The contents of this file are proprietary and confidential.
 * Unauthorised copying of this file, via any medium is strictly prohibited.
 */

import { PlaybackMode, PlaybackPolicyHandler } from '@yospace/admanagement-sdk';

export default class DefaultPlaybackPolicy extends PlaybackPolicyHandler {
  constructor() {
    super();
    this.playbackMode = null;
  }

  canChangeVolume(mute, playhead, timeline) {
    return true;
  }

  canClickThrough(url, playhead, timeline) {
    return true;
  }

  canPause(playhead, timeline) {
    return this.playbackMode !== PlaybackMode.LIVE;
  }

  canResize(fullscreen, playhead, timeline) {
    return true;
  }

  canResizeCreative(expand, playhead, timeline) {
    return true;
  }

  canSkip(playhead, timeline, duration) {
    if (this.playbackMode !== this.playbackMode.LIVE) {
      // We can never skip live adverts
      return -1;
    } else {
      let ad = this.isInAdvert(playhead, timeline);
      if (!ad) {
        return -1;
      }

      let skipOffset = ad.getSkipOffset();
      if (skipOffset === -1) {
        // We can only skip if the advert allows it
        return -1;
      }

      if (!ad.isActive()) {
        // We can skip an inactive advert
        skipOffset = 0;
      } else if (skipOffset >= 0) {
        // Calculate the difference between offset and playhead, unless negative
        skipOffset = Math.max(0, ad.getStart() + skipOffset - playhead);
      }

      // Finally, don't skip if we are in NLSO playback and any offset would put the playhead beyond the maximum position
      if (this.playbackMode === this.playbackMode.NLSO) {
        if (ad.getStart() + ad.getDuration() - playhead > duration) {
          skipOffset = -1;
        }
      }

      return skipOffset;
    }
  }

  canStop(playhead, timeline) {
    return true;
  }

  closestActiveBreakPriorTo(position, timeline) {
    let closest = null;
    for (let i = 0; i < timeline.length; ++i) {
      let br = timeline[i];
      if (br.getStart() + br.getDuration() < position && br.isActive()) {
        closest = br;
      }
    }

    return closest;
  }

  didSeek(from, to, timeline) {
    // If we sought forward in a VOD/NLSO stream, disable all adbreaks we sought past
    if (this.playbackMode !== this.playbackMode.LIVE && from < to) {
      this.setInactiveAllAdBreaksBetween(from, to, timeline);
    }
  }

  isInActiveBreak(playhead, timeline) {
    for (let i = 0; i < timeline.length; ++i) {
      let br = timeline[i];
      if (
        br.getStart() <= playhead &&
        playhead < br.getStart() + br.getDuration()
      ) {
        return br.isActive() ? br : null;
      }
    }

    return null;
  }

  isInAdvert(playhead, timeline) {
    for (let i = 0; i < timeline.length; ++i) {
      let br = timeline[i];
      if (
        br.getStart() <= playhead &&
        playhead < br.getStart() + br.getDuration()
      ) {
        for (let j = 0; j < br.getAdverts.length; ++j) {
          let ad = br.getAdverts()[j];
          if (
            ad.getStart() <= playhead &&
            playhead < ad.getStart() + ad.getDuration()
          ) {
            return ad;
          }
        }
      }
    }

    return null;
  }

  didSkip(from, to, timeline) {
    return this.didSeek(from, to, timeline);
  }

  setInactiveAllAdBreaksBetween(start, end, timeline) {
    for (let i = 0; i < timeline.length; ++i) {
      let br = timeline[i];
      if (
        br.isActive() &&
        br.getStart() >= start &&
        br.getStart() + br.getDuration() <= end
      ) {
        br.setInactive();
      }
    }
  }

  setPlaybackMode(mode) {
    this.playbackMode = mode;
  }

  willSeekTo(position, timeline) {
    let br = this.isInActiveBreak(position, timeline);
    let actual = position;
    if (br) {
      // If we're in an ad break, return from the break start
      actual = br.getStart();
    } else {
      // If not, find the closest break prior to the playhead
      br = this.closestActiveBreakPriorTo(position, timeline);
      if (br) {
        // If there is one, return the closest active break start.
        // If not, return the posuition pased in (i.e., we won't seek).
        actual = br.getStart();
      }
    }

    return actual;
  }
}
