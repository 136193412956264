import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import toLower from 'lodash/toLower';

const defaultNeedles = [
  'userName',
  'password',
  'AccessToken',
  'RefreshToken',
  'IdToken',
  'AuthToken',
  'Authorization',
  'drmData',
];

const defaultReplacer = 'hidden-PII';

const replaceNeedlesInHaystack = ({
  needles = [],
  replaceData = '',
}) => haystack => {
  const allNeedles = [...defaultNeedles, ...needles];
  const replacement = replaceData || defaultReplacer;
  const haystackObj = typeof haystack === 'object' ? haystack : { haystack };

  // Make lowercase on needles array
  const lowerCaseNeedles = map(allNeedles, toLower);

  const walkBackwards = obj =>
    mapValues(obj, (value, key) => {
      const isNestedHaystack = typeof value === 'object';

      // Make key lowercase
      const isMatchingNeedle = lowerCaseNeedles.includes(toLower(key));

      if (isNestedHaystack) {
        return walkBackwards(value);
      }

      if (isMatchingNeedle) {
        return replacement;
      }

      return value;
    });

  return walkBackwards(haystackObj);
};

export default replaceNeedlesInHaystack;
