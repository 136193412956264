import { events } from './events';
import { setIdToken } from './requestUtils';
import {
  DR_MODE_SIMULATION,
  SENDER_EXPIRED_SIMULATION,
  EXPIRED_TOKEN_ID,
} from './constants';
import senderPayload from './__tests__/dr-mode/senderPayload';

function modifyIfInSimulation(loadRequest) {
  if (DR_MODE_SIMULATION) {
    // eslint-disable-next-line no-param-reassign
    loadRequest = senderPayload;
    // console.log('>>> DR_MODE_SIMULATION', loadRequest);
  }
  if (SENDER_EXPIRED_SIMULATION) {
    // eslint-disable-next-line no-param-reassign
    loadRequest = setIdToken(EXPIRED_TOKEN_ID, loadRequest);
    // console.log('>>> SENDER_EXPIRED_SIMULATION', loadRequest);
  }
  return loadRequest;
}

class Request extends EventTarget {
  constructor(loadRequest, segmentService) {
    super();
    this.segmentService = segmentService;
    this.set(loadRequest);
    this.setEventListeners();
  }

  setEventListeners() {
    this.addEventListener(events.session.REFRESHED, ({ detail }) => {
      this.segmentService?.trackCustomEvent('sessionRefreshed', {
        eventDetails: { session: detail },
      });
      this.setSession(detail);
    });
  }

  raw() {
    return this.request;
  }

  set(loadRequest) {
    // eslint-disable-next-line no-param-reassign
    loadRequest = modifyIfInSimulation(loadRequest);
    this.request = loadRequest;
  }

  setSession(idToken) {
    this.request = setIdToken(idToken, this.request);
  }
}

export default Request;
