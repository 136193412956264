import { get, includes, isEmpty } from 'lodash';
import { types } from '../../stateManager';
import {
  adTypesMapping,
  prerollMidrollThreshold,
  videoTypes,
} from '../segment/segmentHelper';
import yospaceLog from './yospaceLog';
import getYSDuration from './utils/getYSDuration';

const yospaceSessionManagerCallback = ({
  player,
  playbackData = {},
  setState = () => {},
  getState = () => {},
  sessionManager,
  segmentService,
  logData,
}) => {
  let advertStartCount = 0;
  let advertEndCount = 0;

  function isNoAdPlayed() {
    return (
      segmentService?.isInAdBreak() &&
      advertStartCount === advertEndCount &&
      advertStartCount + advertEndCount === 0
    );
  }

  function isAdPlayedEven() {
    return !isNoAdPlayed() && advertStartCount === advertEndCount;
  }

  function outsideAdBreakLog() {
    if (!segmentService?.isInAdBreak()) {
      yospaceLog(logData, segmentService).error('outsideAdBreak', logData);
    }
  }

  function adBreakStartLog() {
    if (segmentService?.isInAdBreak()) {
      yospaceLog(logData, segmentService).error('unexpectedAdBreakStart', logData);
    }
  }

  function advertStartLog(isAdvert) {
    if (segmentService?.isInAdBreak() && isAdvert) {
      advertStartCount += 1;
    }
    outsideAdBreakLog();
  }

  function advertEndLog() {
    if (segmentService?.isInAdBreak()) {
      advertEndCount += 1;
    }
    outsideAdBreakLog();
  }

  function adBreakEndLog() {
    if (segmentService?.isInAdBreak()) {
      if (isNoAdPlayed()) {
        yospaceLog(logData, segmentService).error('emptyAdBreak', logData);
      }

      if (!isAdPlayedEven()) {
        yospaceLog(logData, segmentService).error('unevenAdBreak', logData);
      }

      advertStartCount = 0;
      advertEndCount = 0;
    } else {
      yospaceLog(logData, segmentService).error('unexpectedAdBreakEnd', logData);
    }
  }

  function getAdSchema(adType) {
    const currentAdvert = get(sessionManager, 'session.currentAdvert', {});

    const adBreak = get(currentAdvert, 'adBreak', {});
    const advert = get(currentAdvert, 'advert', {});
    const { id: assetId } = advert;
    let { adBreakIdentifier = '', adverts = [] } = adBreak;

    // yospace non live assets session  returns  for mid-roll  type as mid-roll-1/ mid-roll-2 so formatting it to mid-roll for segment purpose
    adBreakIdentifier =
      adBreakIdentifier && includes(adBreakIdentifier, 'mid')
        ? 'midroll'
        : adBreakIdentifier;

    const finalAdBreakType = get(
      adTypesMapping,
      adBreakIdentifier,
      get(adTypesMapping, adType),
    );
    const adSequence = get(advert, 'sequence', '');
    const adCount = adverts.length;

    return !isEmpty(currentAdvert)
      ? {
          assetId,
          position: adSequence,
          length: adCount,
          type: finalAdBreakType || adBreakIdentifier || adType,
          isFirstAd: adSequence === 1,
          isLastAd: adSequence === adCount,
        }
      : {};
  }

  function getAdCompletedPosition() {
    const {
      ADVERT_START_TIME: advertStartTime = 0,
      IS_LIVE: isLive,
      EPOCH_ADVERT_START: epochAdvertStart,
    } = getState();
    const currentAdDuration = get(
      sessionManager,
      ['session', 'currentAdvert', 'advert', 'container', 'duration'],
      0,
    );

    if (!currentAdDuration) {
      if (isLive) {
        const currentEpoch = Date.now();
        return Math.round((currentEpoch - epochAdvertStart) / 1000);
      }
      const currentTime = player ? player.getCurrentTime() : 0;
      return Math.round(currentTime > 0 ? currentTime - advertStartTime : 0);
    }
    return Math.round(currentAdDuration);
  }

  return {
    AdBreakStart: brk => {
      // console.log('=============AdBreakStart - brk=============', brk);
      adBreakStartLog();
      const { startPosition: adStartPosition = 0, adverts } = brk;
      const duration = getYSDuration('adverts')(brk);
      const { live } = playbackData;

      // There is a race condition, so instead we will ping player directly for position
      // const { CURRENT_TIME = 0 } = getState() || {};
      const CURRENT_TIME = player.getCurrentTime();
      // We need to adjust the duration as users can scrub into ad breaks for VOD content
      // i.e. user can enter ad break at 10sec out of the 30sec ad break; so duration should
      // be 20sec instead of 30sec
      let durationOffset = 0;
      if (!live && CURRENT_TIME > adStartPosition) {
        durationOffset = CURRENT_TIME - adStartPosition;
      }

      const actualDuration = duration - durationOffset;

      let breakType;
      if (adverts) {
        segmentService?.setVideoType({ type: videoTypes.ad });
        if (live) {
          const playbackStartTime = segmentService?.getPlaybackStartTime();
          const currentTimeEpoch = new Date().getTime();
          breakType =
            currentTimeEpoch - playbackStartTime < prerollMidrollThreshold
              ? 'preroll'
              : 'midroll';
        } else {
          breakType = CURRENT_TIME < 1 ? 'preroll' : 'midroll';
        }
      }

      setState({
        [types.AD_DURATION]: actualDuration > 0 ? actualDuration : duration,
        [types.AD_START_TIME]: CURRENT_TIME,
        [types.AD_REAL_START_TIME]: adStartPosition,
        [types.ADBREAK_START]: true,
        [types.ADBREAK_TYPE]: breakType,
      });
    },
    AdvertStart: () => {
      // console.log('=============AdvertStart=============');
      const { ADBREAK_TYPE: adType } = getState();
      const { session } = sessionManager;
      const brk = session && session.getCurrentBreak();
      let advert;
      if (brk) {
        const { adverts } = brk;
        if (adverts) {
          // Record playhead position if sequence 1
          advert = get(sessionManager, ['session', 'currentAdvert', 'advert']);
          if (advert) {
            // pass the adType for live assets as yosession manager does not return ad break identifier(preroll/postroll)
            const adSchema = getAdSchema(adType);
            if (!isEmpty(adSchema)) {
              setState({
                [types.EPOCH_ADVERT_START]: Date.now(), // Advert Epoch time stamp used to calculate position for advert playing
                [types.AD_SCHEMA]: adSchema,
                [types.ADVERT_START_TIME]: player.getCurrentTime(),
              });
              segmentService?.trackAdStarted({ position: 0 });
            }
          }
        }
      }
      advertStartLog(advert);
    },
    AdvertEnd: () => {
      // console.log('=============AdvertEnd=============');
      const {
        TOTAL_AD_WATCHED_DURATION: totalAdWatchedDuration,
        AD_SCHEMA: adSchema,
        IS_LIVE: isLive,
      } = getState();
      const isVideoContentStarted = segmentService?.getIsVideoContentStarted();
      advertEndLog();
      if (adSchema) {
        const { isLastAd } = adSchema;
        const adCompletedPosition = getAdCompletedPosition();
        segmentService?.trackAdCompleted({ position: adCompletedPosition });
        const adWatchDuration = totalAdWatchedDuration + adCompletedPosition;
        setState({
          [types.TOTAL_AD_WATCHED_DURATION]: adWatchDuration,
        });
        if (isLastAd && !isLive) {
          segmentService?.setVideoType({ type: videoTypes.content });
          if (!isVideoContentStarted) {
            segmentService?.handleStarted();
          }
        }
        setState({
          [types.AD_SCHEMA]: null,
          [types.ADVERT_START_TIME]: 0,
        });
      }
    },
    AdBreakEnd: () => {
      // console.log('=============AdBreakEnd=============');
      adBreakEndLog();
      segmentService?.setVideoType({ type: videoTypes.content });
      setState({
        [types.AD_DURATION]: 0,
        [types.AD_START_TIME]: 0,
        [types.AD_REAL_START_TIME]: 0,
        [types.ADBREAK_START]: false,
        [types.ADBREAK_TYPE]: null,
        [types.IS_PAUSED]: false,
        [types.AD_SCHEMA]: null,
      });
    },
    UpdateTimeline: () => {},
    AnalyticsFired: () => {},
  };
};
export default yospaceSessionManagerCallback;
