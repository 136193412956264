import replaceNeedlesInHaystack from '../../utils/replaceNeedlesInHaystack';

export default function createPlayerManagerData(
  pickDeviceDetails,
  playerManager,
) {
  function includeField(pickDetails, field, value) {
    if (!pickDetails) {
      return replaceNeedlesInHaystack({})(value);
    }
    return pickDetails.includes(field)
      ? replaceNeedlesInHaystack({})(value)
      : undefined;
  }
  return {
    playerManager: playerManager && {
      stats: includeField(pickDeviceDetails, 'stats', playerManager.getStats()),
      currentTimeSec: includeField(
        pickDeviceDetails,
        'currentTimeSec',
        playerManager.getCurrentTimeSec(),
      ),
      durationTime: includeField(
        pickDeviceDetails,
        'durationTime',
        playerManager.getDurationSec(),
      ),
      liveSeekableRange: includeField(
        pickDeviceDetails,
        'liveSeekableRange',
        playerManager.getLiveSeekableRange(),
      ),
      mediaInformation: includeField(
        pickDeviceDetails,
        'mediaInformation',
        playerManager.getMediaInformation(),
      ),
      playbackRate: includeField(
        pickDeviceDetails,
        'playbackRate',
        playerManager.getPlaybackRate(),
      ),
      playerState: includeField(
        pickDeviceDetails,
        'playerState',
        playerManager.getPlayerState(),
      ),
      preferredPlaybackRate: includeField(
        pickDeviceDetails,
        'preferredPlaybackRate',
        playerManager.getPreferredPlaybackRate(),
      ),
      startAbsoluteTime: includeField(
        pickDeviceDetails,
        'startAbsoluteTime',
        playerManager.getStartAbsoluteTime(),
      ),
      supportedMediaCommands: includeField(
        pickDeviceDetails,
        'supportedMediaCommands',
        playerManager.getSupportedMediaCommands(),
      ),
    },
  };
}
