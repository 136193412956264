/* eslint-disable */
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import xml2js from 'xml2js';
import { getPlaybackContentId } from '../playbackUtils';
import createPlayerManagerData from '../services/analytics/playerManagerData';

const prefix = 'MPD';

export const logManifest = async ({
  name,
  source,
  data = '',
  playerService,
  config,
  segmentStore,
  goodManifestStore,
  badManifestStore,
  segmentService,
}) => {
  const pickDeviceDetails = [
    'stats',
    'currentTimeSec',
    'liveSeekableRange',
    'playbackRate',
    'playerState',
  ];

  const { assetData, playbackData } = playerService.getData();
  const playbackDataUrl = getPlaybackContentId(playbackData);
  const beforeBroadcastEndTime = playerService
    .getAsset()
    .isBeforeBroadcastEndTime();

  const finalStreamUrl = playerService.getFinalContentUrl();
  const cdn = playerService.getCdn();
  const watchMode = playerService.getWatchMode();

  const currentPlayerSession = playerService.getSession();
  const currentPlayerSessionId =
    currentPlayerSession && currentPlayerSession.getId();

  const {
    baseUrl: manifestBaseUrl,
    location: manifestLocation,
    segmentTemplates: manifestSegmentTemplates,
  } = await parseManifest(data);

  const manifestRawEnabled = config.getConfig('manifestRaw');

  const context = cast.framework.CastReceiverContext.getInstance();
  const playerManager = context.getPlayerManager();

  segmentService?.trackCustomEvent('playerManifestHandler', {
    eventSource: source,
    eventDetails: {
      cdn,
      beforeBroadcastEndTime,
      watchMode,
      manifest: manifestRawEnabled ? data : null,
      manifestBaseUrl,
      manifestLocation,
      manifestSegmentTemplates,
      segmentRequests: segmentStore && segmentStore.getStore(),
      lastManifestGood: goodManifestStore && goodManifestStore.getStore(),
      lastManifestBad: badManifestStore && badManifestStore.getStore(),
      assetData,
      playbackData: {
        ...playbackData,
        items: null,
        url: playbackDataUrl,
      },
      finalStreamUrl,
      playerSessionId: currentPlayerSessionId,
      ...createPlayerManagerData(pickDeviceDetails, playerManager),
    },
  });
};

export const isManifestEnabled = ({
  configParameter,
  config,
  playerService,
}) => {
  // Enabled by specific config parameter
  const manifestConfigParameterEnabled = config.getConfig(configParameter);

  // Mandatory: disable for specific stream types
  const manifestStreamTypesExcluded = config.getConfig(
    'manifestStreamTypesExcluded',
  );
  const streamType = playerService.getAsset().getStreamType();
  const manifestStreamTypesDisabled =
    Array.isArray(manifestStreamTypesExcluded) &&
    manifestStreamTypesExcluded.includes(streamType);

  // Mandatory: enable for specific watch modes
  const manifestWatchModesIncluded = config.getConfig(
    'manifestWatchModesIncluded',
  );
  const watchMode = playerService.getWatchMode();
  const manifestWatchModesEnabled =
    Array.isArray(manifestWatchModesIncluded) &&
    manifestWatchModesIncluded.includes(watchMode);

  // Enabled via user id whitelisting
  const manifestUserIdsEnabled = config.getConfig('manifestUserIds');
  const { userData = {} } = playerService.getData();
  const userId = userData.analyticUserId;
  const manifestEnabledForUser =
    Array.isArray(manifestUserIdsEnabled) &&
    manifestUserIdsEnabled.includes(userId);

  return (
    !manifestStreamTypesDisabled &&
    manifestWatchModesEnabled &&
    (manifestConfigParameterEnabled || manifestEnabledForUser)
  );
};

export const isSegmentRequestEnabled = ({ config, player, playerService }) => {
  // Master flag to enable segment request logging
  const segmentRequestEnabled = config.getConfig('segmentRequest');

  // Option to only log when buffering occurs
  let segmentRequestAllowed = true;
  const segmentRequestBufferingOnly = config.getConfig(
    'segmentRequestBufferingOnly',
  );
  const playerBuffering = player && player.getState() === 'BUFFERING';
  if (segmentRequestBufferingOnly && !playerBuffering) {
    segmentRequestAllowed = false;
  }

  // Mandatory: disable for specific stream types
  const segmentStreamTypesExcluded = config.getConfig(
    'segmentStreamTypesExcluded',
  );
  const streamType = playerService.getAsset().getStreamType();
  const segmentStreamTypesDisabled =
    Array.isArray(segmentStreamTypesExcluded) &&
    segmentStreamTypesExcluded.includes(streamType);

  // Mandatory: enable for specific watch modes
  const segmentWatchModesIncluded = config.getConfig(
    'segmentWatchModesIncluded',
  );
  const watchMode = playerService.getWatchMode();
  const segmentWatchModesEnabled =
    Array.isArray(segmentWatchModesIncluded) &&
    segmentWatchModesIncluded.includes(watchMode);

  // Enabled via user id whitelisting
  const segmentRequestUserIdsEnabled = config.getConfig(
    'segmentRequestUserIds',
  );
  const { userData = {} } = playerService.getData();
  const userId = userData.analyticUserId;
  const segmentRequestEnabledForUser =
    Array.isArray(segmentRequestUserIdsEnabled) &&
    segmentRequestUserIdsEnabled.includes(userId);

  return (
    !segmentStreamTypesDisabled &&
    segmentWatchModesEnabled &&
    segmentRequestAllowed &&
    (segmentRequestEnabledForUser || segmentRequestEnabled)
  );
};

export const parseManifest = async (data = '') => {
  const manifestObj = (await parseString(data)) || {};
  const baseUrl = getBaseUrl(manifestObj);
  const location = getLocation(manifestObj);
  const segmentTemplates = getSegmentTemplatesFromPeriod(manifestObj);

  return {
    baseUrl,
    location,
    segmentTemplates,
  };
};

export const parseString = data => {
  // Ensure data is string
  const dataString = typeof data !== 'string' ? JSON.stringify(data) : data;

  const parser = new xml2js.Parser({
    explicitArray: false,
    mergeAttrs: true,
  });

  return parser
    .parseStringPromise(dataString)
    .then(result => {
      return result;
    })
    .catch(err => {
      // Failed
      return err;
    });
};

export const getBaseUrl = obj => {
  return get(obj, [prefix, 'BaseURL']);
};

export const getLocation = obj => {
  return get(obj, [prefix, 'Location']);
};

export const getPeriod = obj => {
  return get(obj, [prefix, 'Period']);
};

export const getKey = (obj, key) => {
  return get(obj, `${prefix}.${key}`);
};

export const getSegmentTemplatesFromPeriod = obj => {
  const periods = getPeriod(obj);
  if (periods) {
    const periodsArr = periods && !Array.isArray(periods) ? [periods] : periods;

    return periodsArr.map(period => {
      // adaptationSet should contain 2 items; one for video and another for audio
      const adaptationSet = get(period, 'AdaptationSet', []);

      let adapatationSetSegmentTemplates = {};
      forEach(adaptationSet, set => {
        const type = get(set, 'mimeType');
        const segmentTemplate = get(set, 'SegmentTemplate');
        adapatationSetSegmentTemplates[type] = [];

        // Yospace ads have their segment templates grouped into each Representation vs.
        // OS Main content has it in the root adaptationSet
        if (!segmentTemplate) {
          const representation = get(set, 'Representation', []);
          const representationArr =
            representation && !Array.isArray(representation)
              ? [representation]
              : representation;

          forEach(representationArr, rep => {
            const repSegmentTemplate = get(rep, 'SegmentTemplate');
            adapatationSetSegmentTemplates[type].push(repSegmentTemplate);
          });
        } else {
          adapatationSetSegmentTemplates[type].push(segmentTemplate);
        }
      });

      return adapatationSetSegmentTemplates;
    });
  }

  return null;
};

export const manifestContainsAd = async (data = '') => {
  const manifestObj = (await parseString(data)) || {};

  const periods = getPeriod(manifestObj);

  if (periods) {
    const periodsArr = periods && !Array.isArray(periods) ? [periods] : periods;
    let containsAd = false;

    forEach(periodsArr, period => {
      const periodId = get(period, 'id', '').toLowerCase();
      if (periodId && periodId.search('yo-') !== -1) {
        containsAd = true;
        return false;
      }
    });

    return containsAd;
  }

  return false;
};

export const getManifestAttributes = async ({ data = '', attributes = {} }) => {
  const manifestObj = (await parseString(data)) || {};
  let parsedAtrributes = {};

  forEach(attributes, (attValue, attKey) => {
    const attribResult = getKey(manifestObj, attValue);
    if (attribResult) {
      parsedAtrributes[attKey] = attribResult;
    }
  });

  return parsedAtrributes;
};
