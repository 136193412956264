export function createCustomEvent(event, detail) {
  return new CustomEvent(event, { detail });
}

export const events = {
  streamKicker: {
    TRY_KICK: 'TRY_KICK',
    KICKED: 'KICKED',
    ERROR: 'ERROR',
  },
  session: {
    REFRESHED: 'REFRESHED',
    STOP: 'STOP',
    ERROR: 'ERROR',
  },
  messageBus: {
    MESSAGE: 'MESSAGE',
  },
};
