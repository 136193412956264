/* eslint-disable */
import _ from 'lodash';
import { Player, PlayerService, StalledHandleService } from './services/player';
import { ERROR_CONSTANTS, StreamKicker } from './errorManager';
import Analytics from './Analytics';
import ConvivaAnalytics from './ConvivaAnalytics';
import MuxAnalytics from './MuxAnalytics';
import MessageBus from './MessageBus';
import {
  ANALYTICS_ID_CONVIVA,
  ANALYTICS_ID_MUX,
  MESSAGE_BUS,
  NODE_ENV,
  SEGMENT_KEY,
  apiUrls,
} from './constants';
import { createMetadata, isDRMode } from './requestUtils';

import {
  transitionToIdle,
  transitionToPlay,
  getCastPlayer,
  showTimeDuration,
  displayUnsupported,
} from './uiUtils';
import { events } from './events';
import Session from './Session';
import { createProgressUpdateResponse, getSeekTime } from './messageBusUtils';
// Deprecating in favour of YospaceService
// import yospaceService from './services/yospace/yospace';
import { getYSSourceUrl, isYspSdkEnable } from './services/yospace/utils';
import stateManager, { initialState, types } from './stateManager';
import { adCountDown, adControls } from './features';
import {
  getPlaybackContentId,
  getPlaybackItemData,
  getPlaybackStreamProtocol,
} from './playbackUtils';
import Config from './services/config/config';
import {
  // logManifest,
  parseManifest,
  isManifestEnabled,
  // isSegmentRequestEnabled,
  // manifestContainsAd,
  // getManifestAttributes,
} from './utils/manifestParser';
import store from './utils/storage';
import SegmentService from './services/segment/SegmentService';
import deviceInfo from './utils/deviceInfo';
import YospaceService from './services/yospace/YospaceService';
import YospaceServiceV3 from './services/yospace/yospaceServiceV3';
import isHevcSupported from './utils/isHevcSupported';

// Init Segment analytics script/sdk
if (SEGMENT_KEY) {
  const segmentScriptEl = document.createElement('script');

  segmentScriptEl.innerHTML = `
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${SEGMENT_KEY}";;analytics.SNIPPET_VERSION="4.15.3";
    analytics.load("${SEGMENT_KEY}");
    analytics.page();
    }}();
  `;

  document.head.appendChild(segmentScriptEl);
}
const castMediaPlayer = document.getElementsByTagName('cast-media-player')[0];
const playerElement = castMediaPlayer.getMediaElement();

const config = Config();
// Debug mode
// const castDebugLogger = cast.debug.CastDebugLogger.getInstance();
//
// // Enable debug logger and show a 'DEBUG MODE' overlay at top left corner.
// castDebugLogger.setEnabled(true);
//
// castDebugLogger.loggerLevelByEvents = {
//   'cast.framework.events.category.CORE': cast.framework.LoggerLevel.WARNING,
//   'cast.framework.events.category.DEBUG': cast.framework.LoggerLevel.WARNING,
//   'cast.framework.events.category.FINE': cast.framework.LoggerLevel.WARNING,
//   'cast.framework.events.category.REQUEST': cast.framework.LoggerLevel.WARNING,
// };
//
// // Show debug overlay
// castDebugLogger.showDebugLogs(true);

// Cast Reciever
const castReceiver = cast.framework.CastReceiverContext.getInstance();
// Cast Player
const playerManager = castReceiver.getPlayerManager();

// Player wrapper
const player = Player(playerManager, playerElement);

// Initilise State
const state = stateManager(initialState);
const { getState, setState, resetState, subscribe } = state;

const customNamespaces = {
  [MESSAGE_BUS]: cast.framework.system.MessageType.JSON,
};
const messageBus = new MessageBus();
const session = new Session();
const playerService = new PlayerService();
const streamKicker = new StreamKicker();
let yospaceService = null;

const segmentService = new SegmentService({
  player,
  getState,
  setState,
  yospace: yospaceService,
});

session.setSegmentService(segmentService);

(async () => {
  // Attempt to grab feature flags config
  // config.fetch('https://api-cdn.optussport.info/api/metadata/systemConfigs/chromecastConfig');
  await config.fetch();

  // Debug to indicate what api base url is being used
  const windowLocationSearchValue = window?.location?.search || '';
  windowLocationSearchValue.includes('showBaseApiUrl=1') &&
    console.log(apiUrls?.newML);

  const segmentEnabled = config.getConfig('segmentEnabled');
  segmentService?.setConfig(config);
  segmentService?.setSegmentEnabled(segmentEnabled);
  segmentService?.trackCustomEvent('appStart');
})();

const isUnsupported = () => {
  if (deviceInfo() === 'Chromecast 1') {
    displayUnsupported();
    segmentService?.trackDeviceUnsupported({
      session_id: Session.generateId(),
    });
    return true;
  }
  return false;
};

if (!isUnsupported()) {
  const analytics = new Analytics({
    // Initialise CONVIVA
    [ANALYTICS_ID_CONVIVA]: new ConvivaAnalytics({ playerManager }),
    // Initialise MUX
    [ANALYTICS_ID_MUX]: new MuxAnalytics({ playerManager }),
  });

  subscribe(() => {
    const state = getState();
    const {
      AD_START_TIME,
      AD_DURATION,
      CURRENT_TIME,
      ADBREAK_START,
      IS_PAUSED,
    } = state;

    // Early return from UI Treatment if yospace sdk is disabled
    // OR we have specifically disabled the UI treatment (adUiTreatment flag)
    const yspSdk = config.getConfig('yspSdk');
    const adUiTreatment = config.getConfig('adUiTreatment');
    if (!yspSdk || !adUiTreatment) {
      return;
    }

    // Rerender on each time increment
    adCountDown({
      start: AD_START_TIME,
      duration: AD_DURATION,
      time: CURRENT_TIME,
      active: ADBREAK_START,
    })('cast-media-player');
    adControls({ isPaused: IS_PAUSED, active: ADBREAK_START })(
      'cast-media-player',
    );
  });

  const conviva = analytics.get(ANALYTICS_ID_CONVIVA);

  // Segment storage
  const segmentStore = store();

  // "Good" manifest storage
  const goodManifestStore = store();

  // "Bad" manifest storage
  const badManifestStore = store();

  const muxAnalytics = analytics.get(ANALYTICS_ID_MUX);
  const stalledHandleService = StalledHandleService({
    player,
    playerService,
    session,
    muxAnalytics,
    conviva,
    transitionToIdle,
    config,
    segmentStore,
    goodManifestStore,
    badManifestStore,
    segmentService,
  });

  // Manifest interval
  const manifestInterval = () => {
    let interval;
    let logManifest = false;

    const setLogManifest = status => (logManifest = status);
    const getLogManifest = () => logManifest;
    const stop = () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    const start = ({ number }) => {
      stop();
      if (number > 0) {
        interval = setInterval(() => {
          setLogManifest(true);
        }, number);
      }
    };

    return {
      start,
      stop,
      setLogManifest,
      getLogManifest,
    };
  };
  const manifestInterval60 = manifestInterval();

  const playbackConfig = Object.assign(
    new cast.framework.PlaybackConfig(),
    playerManager.getPlaybackConfig(),
    {
      autoResumeDuration: 2,
      // autoResumeNumberOfSegments: 1,
      shakaConfig: {
        streaming: {
          bufferBehind: 30,
          bufferingGoal: 30,
          // bufferingGoal: 4,
          jumpLargeGaps: true,
          // smallGapLimit: 100,
          // rebufferingGoal: 2,
          retryParameters: {
            timeout: 5000,
            baseDelay: 800,
            fuzzFactor: 0.5,
            maxAttempts: 5,
            backoffFactor: 2,
          },
          stallSkip: 0.1,
        },
        drm: {
          retryParameters: {
            timeout: 20000,
            baseDelay: 800,
            fuzzFactor: 0.5,
            maxAttempts: 5,
            backoffFactor: 2,
          },
        },
        manifest: {
          retryParameters: {
            timeout: 20000,
            baseDelay: 800,
            fuzzFactor: 0.5,
            maxAttempts: 5,
            backoffFactor: 2,
          },
          // new hls object parameter to prevent cors/partial content request
          hls: {
            useFullSegmentsForStartTime: true,
          },
        },
        preferredVideoCodecs: ['hvc1', 'avc1'],
      },
      manifestHandler: async data => {
        // if (data) {
        //   const name = 'playerManifestHandler';
        //   const manifestBufferingEnabled = isManifestEnabled({
        //     configParameter: 'manifestBuffering',
        //     config,
        //     playerService,
        //   });

        //   const logManifestFromInterval = manifestInterval60.getLogManifest();
        //   const isPlayerBuffering = player && player.getState() === 'BUFFERING';
        //   const isBuffering = manifestBufferingEnabled && isPlayerBuffering;

        //   const manifestTimeEnabled = isManifestEnabled({
        //     configParameter: 'manifestTime',
        //     config,
        //     playerService,
        //   });
        //   if (manifestTimeEnabled) {
        //     const pickManifestDetails = {
        //       manifestPublishTime: 'publishTime',
        //       manifestUTCTiming: 'UTCTiming.value',
        //     };
        //     const manifestAttributes = await getManifestAttributes({
        //       data,
        //       attributes: pickManifestDetails,
        //     });

        //     segmentService?.trackCustomEvent(name, {
        //       eventSource: 'time',
        //       eventDetails: {
        //         ...manifestAttributes,
        //         deviceTimeEpoch: Date.now(),
        //       },
        //     });
        //   }

        //   let manifestHasAds = false;
        //   const manifestAdEnabled = isManifestEnabled({
        //     configParameter: 'manifestAd',
        //     config,
        //     playerService,
        //   });
        //   if (manifestAdEnabled) {
        //     manifestHasAds = await manifestContainsAd(data);
        //   }

        //   // log levels of buffering or interval; if both, buffering always takes precedence
        //   if (logManifestFromInterval || isBuffering || manifestHasAds) {
        //     let source = manifestHasAds ? 'ad' : '';
        //     if (isPlayerBuffering) {
        //       source += `${source !== '' ? '_' : ''}buffering`;
        //     } else if (logManifestFromInterval) {
        //       source += `${source !== '' ? '_' : ''}interval`;
        //     }

        //     await logManifest({
        //       name,
        //       source,
        //       data,
        //       playerService,
        //       config,
        //       segmentService,
        //     });

        //     if (logManifestFromInterval) {
        //       manifestInterval60.setLogManifest(false);
        //     }
        //   }

        //   // Logic for storing good / bad manifests to be logged out during any custom_stalled event
        //   const manifestGoodBad = isManifestEnabled({
        //     configParameter: 'manifestGoodBad',
        //     config,
        //     playerService,
        //   });
        //   if (manifestGoodBad) {
        //     const now = Date.now();

        //     if (!isPlayerBuffering) {
        //       // Good manifest
        //       goodManifestStore.addItem({
        //         time: now,
        //         manifest: data,
        //       });
        //     } else {
        //       // Bad manifest
        //       const existingGoodManifest = goodManifestStore.getLastItem();
        //       const existingBadManifest = badManifestStore.getItem();

        //       if (existingGoodManifest && existingBadManifest) {
        //         // If the existing good manifest's timestamp > existing bad manifest's timestamp
        //         // then we shall replace existing bad / store the current bad manifest
        //         const existingGoodManifestTime = existingGoodManifest.time;
        //         const existingBadManifestTime = existingBadManifest.time;

        //         if (
        //           existingGoodManifestTime &&
        //           existingBadManifestTime &&
        //           existingGoodManifestTime > existingBadManifestTime
        //         ) {
        //           badManifestStore.addItem({
        //             time: now,
        //             manifest: data,
        //           });
        //         }
        //       } else if (!existingBadManifest) {
        //         badManifestStore.addItem({
        //           time: now,
        //           manifest: data,
        //         });
        //       }
        //     }
        //   }
        // }
        return data;
      },
      segmentRequestHandler: data => {
        // if (data) {
        //   const { url: segmentRequestUrl } = data;
        //   const segmentRequestEnabled = isSegmentRequestEnabled({
        //     config,
        //     player,
        //     playerService,
        //   });

        //   // Store the segment requested
        //   segmentStore.addItem({
        //     time: Date.now(),
        //     url: segmentRequestUrl,
        //   });

        //   if (segmentRequestEnabled) {
        //     segmentService?.trackCustomEvent('playerSegmentRequestHandler');
        //   }
        // }

        return data;
      },
    },
  );

  messageBus.setSubscribers([playerService]);

  playerService.setPlaybackConfig(playbackConfig);

  const updateSeekableRange = ({ senderId, start, end }) => {
    const { responseText } = createProgressUpdateResponse({ start, end });
    castReceiver.sendCustomMessage(MESSAGE_BUS, senderId, responseText);
  };

  playerService.addEventListener(events.messageBus.MESSAGE, ({ detail }) => {
    const { senderId, payload } = detail;
    const { type, action, data } = payload;
    const liveSeekableRange = playerManager.getLiveSeekableRange();

    const { start = 0, end = 0 } = liveSeekableRange || {};

    if (type === 'progressUpdate' && action === 'get') {
      segmentService?.trackCustomEvent('playerMessageBusUpdateSeekableRange', {
        eventDetails: { start, end },
      });
      updateSeekableRange({ senderId, start, end });
      return;
    }

    if (type === 'progressUpdate' && action === 'set') {
      const { time } = data;
      const seekTo = getSeekTime({
        seekTo: time,
        within: liveSeekableRange,
      });

      playerManager.seek(seekTo);

      segmentService?.trackCustomEvent('playerMessageBusSeek', {
        eventDetails: {
          time,
          seekTo,
          start,
          end,
        },
      });
      return;
    }

    // Quality selection
    if (type === 'quality' && action === 'get') {
      const currentQuality = playerService.getPlayerQuality();

      segmentService?.trackCustomEvent('playerMessageBusUpdateQualityGet', {
        eventDetails: { quality: currentQuality },
      });

      castReceiver.sendCustomMessage(MESSAGE_BUS, senderId, {
        quality: currentQuality,
      });
    }

    if (type === 'quality' && action === 'set') {
      // Block any requests during ad break
      const { ADBREAK_START } = getState() || false;
      const adUiTreatment = config.getConfig('adUiTreatment');

      if (ADBREAK_START && adUiTreatment) {
        return;
      }

      const { quality = '' } = data;
      const loadRequest = session.getRequest().raw();

      const qualityLower = quality.toLowerCase();

      segmentService?.trackCustomEvent('playerMessageBusUpdateQualitySet', {
        eventDetails: { quality },
      });

      if (qualityLower !== '') {
        // --- Start CMAF support check
        // @TODO: refactor following block into util function as it is used in multiple areas
        // Note: We need to check cmaf whitelist as well because even though chromecast ultra can play hevc,
        // we are currently forcing it use avc ladder only/dash - this is due to yospace stalling issue.
        // We can remove this once stalling is resolved
        // const modelName = getModelName(loadRequest);
        // let chromecastModelName = '';
        // if (
        //   typeof modelName === 'string' &&
        //   modelName.toLowerCase() === 'chromecast'
        // ) {
        //   chromecastModelName = deviceInfo();
        // }

        // OSN-2259 - just retrieve underlying Chromecast device model name
        const isLive = playerService?.getAsset()?.isLive() || false;
        const chromecastModelName = deviceInfo();

        const cmafSupportedDevices =
          (config &&
            config.getConfig &&
            config.getConfig('cmafSupportedDevices')) ||
          [];

        const cmafCtrSupportedDevices =
          (config &&
            config.getConfig &&
            config.getConfig('cmafCtrSupportedDevices')) ||
          [];
        const cmaf = config && config.getConfig && config.getConfig('cmaf');
        const supportsCmaf =
          cmaf && cmafSupportedDevices.includes(chromecastModelName);
        const supportsCmafCtr =
          cmaf &&
          isLive &&
          cmafCtrSupportedDevices.includes(chromecastModelName);
        // -- End CMAF support check

        // Change to avc/hevc depending on current quality toggle
        // for hevc supported devices only and temp. cmaf whitelist
        // @TODO: potentially reintroduce isCmafSupported() which also checks chrome browser version in future
        // once yospace stalling issue is resolved
        if (isHevcSupported() && (supportsCmaf || supportsCmafCtr)) {
          // Fetch quality logic when to force hevc and avc
          const forceHevcQuality = config.getConfig('hevcHevcQuality');
          const forceAvcQuality = config.getConfig('hevcAvcQuality');

          let reloadStream = false;
          let updatedPlaybackConfig = playerService.getPlaybackConfig();
          const currentPreferredVideoCodecs = _.get(
            updatedPlaybackConfig,
            ['shakaConfig', 'preferredVideoCodecs'],
            [],
          );

          if (
            forceAvcQuality.includes(qualityLower) &&
            currentPreferredVideoCodecs[0] !== 'avc1'
          ) {
            playerService.updatePreferredVideoCodecs(['avc1', 'hvc1']);
            reloadStream = true;
          } else if (
            forceHevcQuality.includes(qualityLower) &&
            currentPreferredVideoCodecs[0] === 'avc1'
          ) {
            playerService.updatePreferredVideoCodecs(['hvc1', 'avc1']);
            reloadStream = true;
          }

          if (reloadStream) {
            // Need to update the position of where to start stream on reload
            loadRequest.reloadRequested = true;
            loadRequest.currentTime = player.getCurrentTime();

            playerManager.load(loadRequest);
          }
        }

        // Persist the quality
        playerService.setPlayerQuality(qualityLower);
      }
    }

    segmentService?.trackCustomEvent('playerMessageBusMessage', {
      eventDetails: detail,
    });
  });

  session.addEventListener(events.session.STOP, () => {
    segmentService?.trackCustomEvent('sessionStopped');
    streamKicker.stop();
    conviva.stop();
    muxAnalytics.stop();
    stalledHandleService.stop();
    manifestInterval60.stop();
    segmentStore.reset();
    goodManifestStore.reset();
    badManifestStore.reset();
    segmentService?.closeSession();
  });

  session.addEventListener(events.session.ERROR, error => {
    segmentService?.trackCustomEvent('sessionError', {
      eventDetails: { error },
    });
  });

  streamKicker.addEventListener(events.streamKicker.KICKED, e => {
    // Segment track here - Track chuck norris stream kick custom error event
    const {
      detail: { sessionTime = 0 },
    } = e;
    const maxPlaySessionTime = config.getConfig('maxPlaySessionTimeMs');
    let code = ERROR_CONSTANTS.CHUCK_NORRIS;
    if (sessionTime >= maxPlaySessionTime) {
      code = ERROR_CONSTANTS.ZOMBIE;
    }

    // const chuckNorrisError = {
    //     code: ERROR_CONSTANTS.CHUCK_NORRIS,
    //     message: 'user exceed maximum concurrent streams'
    // };
    segmentService?.handleError({
      error: {
        code,
      },
    });
    playerManager.stop();
    session.stop();
  });

  player.init(async loadRequest => {
    try {
      // Segment service
      const yspSdkV3 = config.getConfig('yspSdkV3');

      // Check if this is a retry request (OSN-1957: introduced with yospace init error -> vods)
      const { retryCountOverride: retryCount } = loadRequest;

      // Check if an active session exists, if so we must
      // trigger Segment to track this as user switched asset
      // for the existing session
      const sessionExists = session && session.getId();
      if (sessionExists && typeof retryCount === 'undefined') {
        segmentService?.handleUserInterruption({
          source: cast.framework.events.EndedReason.INTERRUPTED,
        });
      }

      // Start a brand new session including shutting down any
      // existing Yospace session manager that may still be running
      yospaceService && yospaceService.close();
      resetState();
      await session.start(loadRequest);
      await playerService.start(session);

      segmentService?.startSession({
        session,
        playerService,
      });

      // Handle identify user for Segment
      segmentService?.handleIdentify({ loadRequest });

      // Make Segment Service available
      playerService.setSegmentService(segmentService);

      // Most important trigger to populate asset, user, playback etc.
      const updatedRequest = await playerService.updateRequest({ config });

      const MUX = config.getConfig('mux');
      const MUX_DEBUG = config.getConfig('muxDebug');

      const { assetData, playbackData, userData } = playerService.getData();
      const isLive = playerService.getAsset().isLive();

      // const {
      //   customData: { modelName },
      // } = loadRequest;
      // let chromecastModelName = '';
      // if (
      //   typeof modelName === 'string' &&
      //   modelName.toLowerCase() === 'chromecast'
      // ) {
      //   chromecastModelName = deviceInfo();
      // }

      // OSN-2259 - just retrieve underlying Chromecast device model name
      const chromecastModelName = deviceInfo();

      const cmafSupportedDevices =
        (config &&
          config.getConfig &&
          config.getConfig('cmafSupportedDevices')) ||
        [];

      const cmafCtrSupportedDevices =
        (config &&
          config.getConfig &&
          config.getConfig('cmafCtrSupportedDevices')) ||
        [];

      const cmaf = config && config.getConfig && config.getConfig('cmaf');
      const supportsCmaf =
        cmaf && cmafSupportedDevices.includes(chromecastModelName);
      const supportsCmafCtr =
        cmaf && isLive && cmafCtrSupportedDevices.includes(chromecastModelName);

      // OSN-1440 - Added new rule which can disable yspSdk for live assets
      const yspSdk = config.getConfig('yspSdk');
      const yspSdkLive = config.getConfig('yspSdkLive');
      const yspSdkLiveCmaf = config.getConfig('yspSdkLiveCmaf');
      const isYspSdkEnabled =
        (!isLive && yspSdk) ||
        (isLive && yspSdkLive && !supportsCmaf) ||
        (isLive && yspSdkLiveCmaf && supportsCmaf) ||
        (isLive && yspSdkLiveCmaf && supportsCmafCtr);

      const isSsaiEnable = isYspSdkEnable({
        yspSdk,
        playbackData: playbackData || {},
      });

      segmentService.setIsSSAISDKEnabled(isYspSdkEnabled && isSsaiEnable);

      // Update TrackJS metadata for parameters which are ready to be extracted
      if (window.TrackJS && TrackJS.addMetadata && TrackJS.configure) {
        const { sessionId: castSessionId } =
          castReceiver.getApplicationData() || {};

        const { analyticUserId } = userData || {};
        const { id: assetId } = assetData || {};
        const playbackDataUrl = getPlaybackContentId(playbackData);
        const beforeBroadcastEndTime = playerService
          .getAsset()
          .isBeforeBroadcastEndTime();

        const watchMode = playerService.getWatchMode();
        const currentPlayerSession = playerService.getSession();
        const currentPlayerSessionId =
          currentPlayerSession && currentPlayerSession.getId();

        TrackJS.configure({ userId: analyticUserId });
        TrackJS.addMetadata('castSessionId', castSessionId);
        TrackJS.addMetadata('playerSessionId', currentPlayerSessionId);
        TrackJS.addMetadata('appName', process.env.PLAYER_NAME);
        TrackJS.addMetadata('appVersion', process.env.APP_VERSION);
        TrackJS.addMetadata('environment', process.env.NODE_ENV);
        TrackJS.addMetadata('platform', process.env.PLATFORM);
        TrackJS.addMetadata('assetId', assetId);
        TrackJS.addMetadata('beforeBroadcastEndTime', beforeBroadcastEndTime);
        TrackJS.addMetadata('watchMode', watchMode);
        TrackJS.addMetadata('playbackUrl', playbackDataUrl);
      }

      let updatedCastData = updatedRequest;
      let yospaceError = false;
      // if (isSsaiEnable) {
      if (isSsaiEnable && isYspSdkEnabled) {
        if (yspSdkV3) {
          const yspSuccessCallback = session => {
            // SegmentSession.trackYoSpaceEvent(this.props, this.state);
            updatedCastData = yospaceService.getUpdatedCastData();
            yospaceService && yospaceService.setYospaceReady();
          };

          const yspErrorCallback = reason => {
            yospaceError = reason || 'Error in Yospace initialisation';

            // We need to re-trigger playback for vod failures
            if (!isLive) {
              segmentService?.handleYospaceEvent({
                yospaceError,
              });

              // Need to force non-ssai/no yospace sdk on retry
              loadRequest.yspSdkOverride = false;
              loadRequest.retryCountOverride = 1;

              playerManager.load(loadRequest);

              return;
            }

            yospaceService && yospaceService.setYospaceReady();
            // SegmentSession.trackYoSpaceEvent(this.props, this.state);
          };

          await yospaceService.init({
            player,
            userData,
            assetData,
            playbackData,
            castData: updatedRequest,
            setState,
            getState,
            segmentService,
            yspSdkV3,
            yspSuccessCallback,
            yspErrorCallback,
            config,
          });
        } else {
          [yospaceError, updatedCastData] = await yospaceService.start({
            player,
            userData,
            assetData,
            playbackData,
            castData: updatedRequest,
            setState,
            getState,
            segmentService,
            config,
          });
        }
      } else if (isSsaiEnable) {
        // OSN-1440
        // We still need to update the stream url if for some reason yspSdk
        // has been disabled for live but the yospace stream url is returned

        const { protocol, url: sourceUrl } = getPlaybackItemData(playbackData);
        updatedCastData.media.contentUrl = getYSSourceUrl(
          protocol.toLowerCase(),
          sourceUrl,
          yspSdkV3,
        );
      }

      // Do not continue with load anymore as we should have triggered a retry from yspErrorCallback
      if (yospaceError && !isLive) {
        return;
      }

      setState({ [types.IS_LIVE]: isLive });

      // Debug to force OS91 loop plain manifest without yospace
      // updatedCastData.media.contentUrl = 'https://linear.aws.stagingoptusvideo.tv/v5/OptusSport91/xbtss/drm/hevc/cmaf/scte/manifest/manifest.m3u8';

      const {
        media: { contentUrl: finalContentUrl, contentId: finalContentId } = {},
      } = updatedCastData || {};
      playerService.setFinalContentUrl(finalContentUrl || finalContentId);
      const finalStreamUrl = playerService.getFinalContentUrl();

      // Track Segment Yospace init event
      if (isSsaiEnable && isYspSdkEnabled) {
        segmentService &&
          segmentService?.handleYospaceEvent({
            yospacePlaybackUrl: yospaceError ? '' : finalStreamUrl,
            yospaceError,
          });
      }

      const tags = playerService.getAsset().getCustomTags();
      // Update TrackJS metadata for final stream url and stream type
      if (window.TrackJS && TrackJS.addMetadata) {
        const cdn = playerService.getCdn();
        const { streamType } = tags || {};
        TrackJS.addMetadata('finalStreamUrl', finalStreamUrl);
        TrackJS.addMetadata('streamType', streamType);
        TrackJS.addMetadata('cdn', cdn);
      }

      const metadata = await createMetadata(loadRequest, { tags });
      const assetPlaybackArgs = playerService.getAsset().getAssetPlaybackArgs();

      // const duration = _.get(assetData, 'duration', 'N/A');

      // conviva.start({ ...metadata, duration });
      if (MUX) {
        muxAnalytics.start(
          {
            assetData,
            playbackArgs: assetPlaybackArgs,
            playbackData,
            userData,
            metadata,
            overrideData: {
              retryCount,
              isYspSdkEnabled: isSsaiEnable && isYspSdkEnabled,
            },
          },
          MUX_DEBUG,
        );
      }

      // Set availability window based on cmaf and whether started at live edge
      const playbackProtocol = getPlaybackStreamProtocol(playbackData) || '';
      if (
        playbackProtocol.toLowerCase() === 'cmaf' &&
        (playerService.shouldStartAtEdge(loadRequest) || isDRMode(loadRequest))
      ) {
        // console.log('>>>> setting AvailabilityWindowOverride to 290');
        playerService.updateManifestAvailabilityWindowOverride(290);
      } else {
        // console.log('>>>> setting AvailabilityWindowOverride to null so fallback to manifest default');
        playerService.updateManifestAvailabilityWindowOverride(null);
      }

      segmentService?.trackCustomEvent('playerManagerLoadRequestUpdated', {
        eventDetails: {
          finalStreamUrl,
          playbackData,
        },
      });

      return updatedCastData;
    } catch (err) {
      return castReceiver.stop();
    }
  });

  player.initSeekRequest(event => {
    const { ADBREAK_START } = getState() || false;
    const adUiTreatment = config.getConfig('adUiTreatment');

    if (ADBREAK_START && adUiTreatment) {
      return null;
    } else {
      return event;
    }
  });

  player.onPause(() => {
    setState({ [types.IS_PAUSED]: true });
  });

  player.onResume(() => {
    setState({ [types.IS_PAUSED]: false });
  });

  player.onPlaying(() => {
    setState({ [types.IS_PAUSED]: false });
  });

  player.onLoadStart(async () => {
    const loadRequest = session.getRequest().raw();
    const { assetData } = playerService.getData();

    const tags = playerService.getAsset().getCustomTags();
    const metadata = await createMetadata(loadRequest, { tags });
    const duration = _.get(assetData, 'duration', 'N/A');

    // Segment track here - Video Playback Started
    segmentService?.handlePlayerLoadStart();

    // Conviva start here
    const convivaEnabled = config.getConfig('conviva');
    if (convivaEnabled) {
      conviva.start({ ...metadata, duration });
    }

    // conviva.updateMetadata(metadata);
  });

  player.onPlayerLoadComplete(() => {
    const isLive = playerService.getAsset().isLive();
    const manifestIntervalEnabled = isManifestEnabled({
      configParameter: 'manifestInterval',
      config,
      playerService,
    });

    streamKicker.start(session);
    streamKicker.overrideAssetPayload = (currentPositionOverride = 0) => {
      const currentPosition =
        (currentPositionOverride || playerManager.getCurrentTimeSec()) * 1000;
      const viewProgress = isLive ? 0 : currentPosition;
      return { viewProgress };
    };

    transitionToPlay(isLive);
    // NOTE: LIVE assets got the durations wrong.
    // Due to this existing PROD issue, need to hide for now.
    showTimeDuration(!isLive);

    stalledHandleService.start();
    if (manifestIntervalEnabled) {
      // We will default to manifestIntervalUserId if we have turned it off
      // globally but enabled for whitelisted users
      const manifestIntervalValue =
        config.getConfig('manifestInterval') ||
        config.getConfig('manifestIntervalUserId') ||
        60000;
      manifestInterval60.start({ number: manifestIntervalValue });
    }

    segmentStore.reset();

    // Init the good and bad manifest stores
    const manifestGoodBad = isManifestEnabled({
      configParameter: 'manifestGoodBad',
      config,
      playerService,
    });
    if (manifestGoodBad) {
      goodManifestStore.init({ number: 2 });
      badManifestStore.init({ number: 1 });
    }
  });

  player.onTimeUpdate(time => {
    setState({ [types.CURRENT_TIME]: time?.currentPosition });
  });

  player.onMediaStatus(({ mediaStatus }) => {
    const { liveSeekableRange } = mediaStatus;
    if (liveSeekableRange) {
      const { start, end } = liveSeekableRange;
      // TODO: Need to decide if we need to show seekable range updates
      // log({
      //   eventName: 'player_manager_media_status_update_seekable_range',
      //   eventDetails: { start, end }
      // });
      updateSeekableRange({ start, end });
    }
  });

  player.onMediaFinished(({ currentPosition, endedReason }) => {
    const shouldTransitionToIdle = [
      cast.framework.events.EndedReason.STOPPED,
      cast.framework.events.EndedReason.ERROR,
      cast.framework.events.EndedReason.END_OF_STREAM,
    ].includes(endedReason);
    if (shouldTransitionToIdle) {
      transitionToIdle();
    }
    let viewProgressPosition = currentPosition;
    if (endedReason === cast.framework.events.EndedReason.END_OF_STREAM) {
      viewProgressPosition = 0; //set viewProgress = 0 to start the video from the beginning
    }
    streamKicker.tryKick({ viewProgressPosition });

    // Do not stop session for interrupted ended reason as that is related to a switch in asset
    // If we do a stop here, there is a race condition where this will run after the switch
    // in asset's session is created, that is:
    // 1. Switch asset
    // 2. new player.init with switched asset load request -> create new session
    // 3. onMediaFinished runs due to interruption -> session is destroyed if it runs into here
    if (shouldTransitionToIdle) {
      session.stop();
    }
  });

  player.onProgressUpdate(() => {
    const state = getState();
    const { ADBREAK_START } = state;
    const castPlayer = getCastPlayer();

    //TODO:
    // hide timeline / native pause button when ad break starts // toggle pause / play (add elements from dom util) // are we in ad break then show pause / play

    const displayStatus = castPlayer.getAttribute('displaystatus');
    if (ADBREAK_START && displayStatus) {
      castPlayer.setAttribute('displaystatus', 'false');
    }
  });

  player.onError(async error => {
    const { assetData, playbackData, userData = {} } = playerService.getData();
    const playbackDataUrl = getPlaybackContentId(playbackData);
    const beforeBroadcastEndTime = playerService
      .getAsset()
      .isBeforeBroadcastEndTime();

    const finalStreamUrl = playerService.getFinalContentUrl();
    const cdn = playerService.getCdn();
    const watchMode = playerService.getWatchMode();
    const currentPlayerSession = playerService.getSession();
    const currentPlayerSessionId =
      currentPlayerSession && currentPlayerSession.getId();

    const logPayload = {
      eventName: 'player_manager_error',
      error,
      eventDetails: {
        cdn,
        beforeBroadcastEndTime,
        watchMode,
        assetData,
        playbackData: {
          ...playbackData,
          items: null,
          url: playbackDataUrl,
        },
        finalStreamUrl,
        playerSessionId: currentPlayerSessionId,
      },
      userData,
      showDeviceDetails: true,
    };

    const manifestOnErrorEnabled = isManifestEnabled({
      configParameter: 'manifestOnError',
      config,
      playerService,
    });
    const manifestRawEnabled = config.getConfig('manifestRaw');

    if (manifestOnErrorEnabled) {
      const resManifestData = await playerService.getRawManifest(
        finalStreamUrl,
      );
      const {
        baseUrl: manifestBaseUrl,
        location: manifestLocation,
        segmentTemplates: manifestSegmentTemplates,
      } = await parseManifest(resManifestData);

      logPayload.manifest = manifestRawEnabled ? resManifestData : null;
      logPayload.manifestBaseUrl = manifestBaseUrl;
      logPayload.manifestLocation = manifestLocation;
      logPayload.manifestSegmentTemplates = manifestSegmentTemplates;
    }

    // log(logPayload);
    segmentService &&
      segmentService?.trackCustomEvent('playerManagerError', logPayload);

    // Segment track error event
    const detailedErrorCode = _.get(error, 'detailedErrorCode', 'GENERIC');
    const errorReason = _.get(error, 'reason', 'GENERIC_ERROR');
    const errorObj = _.get(error, 'error', {});

    segmentService?.handleError({
      error: {
        code: detailedErrorCode,
        message: errorReason,
        errorObj,
      },
    });

    const playerState = playerManager.getPlayerState();
    const isIdle = playerState === cast.framework.messages.PlayerState.IDLE;
    if (!isIdle) {
      playerManager.stop();
    }

    conviva.sendError(error);
    // Not necessary to manually track these errors, Mux automatically collects these errors
    // muxAnalytics.sendError(error);
    session.stop();
    transitionToIdle();
  });

  castReceiver.addEventListener(
    cast.framework.system.EventType.READY,
    async () => {
      const yspSdkV3 = config.getConfig('yspSdkV3');
      if (yspSdkV3) {
        yospaceService = new YospaceServiceV3();
      } else {
        yospaceService = new YospaceService();
      }
      segmentService?.setYospaceService(yospaceService);

      // Init the segment store
      const segmentStoreLimit = config.getConfig('segmentStoreLimit');
      if (segmentStoreLimit > 0) {
        segmentStore.init({ number: segmentStoreLimit });
      }

      segmentService?.trackCustomEvent('contextReady');
      transitionToIdle();

      // Start Segment player event listeners
      segmentService?.startPlayerListeners();
    },
  );

  castReceiver.addEventListener(
    [
      cast.framework.system.DisconnectReason.ERROR,
      cast.framework.system.DisconnectReason.UNKNOWN,
    ],
    error => {
      // Segment track here - Track Player closed (Due to unknown error encountered)
      // Note: May not be needed

      segmentService?.trackCustomEvent('contextError');
    },
  );

  castReceiver.addEventListener(
    [
      cast.framework.system.EventType.SHUTDOWN,
      cast.framework.system.DisconnectReason.ERROR,
      cast.framework.system.DisconnectReason.UNKNOWN,
    ],
    event => {
      // Segment track here - Track Player closed (Due to Graceful uncast)
      segmentService?.handleUserInterruption({ source: 'STOPPED' });

      // TODO: Note - There is no guarantee this event will be logged
      // as the the API call will be cancelled upon SHUTDOWN event.
      segmentService?.trackCustomEvent('contextShutdown');
      session.stop();
    },
  );

  castReceiver.addCustomMessageListener(MESSAGE_BUS, event => {
    const { type, senderId, data: payload } = event;
    messageBus.broadcast({ senderId, type, payload });
  });

  // Current Shaka Player used as of 23rd May 2024
  const shakaVersionOverride = '4.3.4';

  castReceiver.start({
    touchScreenOptimizedApp: true,
    playbackConfig,
    customNamespaces,
    // useShakaForHls: true,
    // Disable/comment out to use latest shaka player supported by CAF SDK
    shakaVersion: shakaVersionOverride,
    // Shaka Player override
    // shakaUrl: 'https://ajax.googleapis.com/ajax/libs/shaka-player/3.0.12/shaka-player.compiled.js',
  });

  const isDevelopment = NODE_ENV === 'development' || NODE_ENV === 'local';
  if (isDevelopment) {
    const castPlayer = getCastPlayer();
    playerManager.addEventListener(
      cast.framework.events.EventType.PROGRESS,
      () => {
        if (castPlayer.getAttribute('displaystatus') === 'false') {
          castPlayer.setAttribute('displaystatus', 'true');
        }
      },
    );

    console.log('Shaka Version Override - ', shakaVersionOverride);
  }
}
