/* eslint-disable */
import _ from 'lodash';
import {
  PLATFORM,
  APP_VERSION,
  MUX_CUSTOMER_KEY,
  ANALYTIC_DEFAULT_VENUE_MODE,
} from './constants';
import { ERROR_CONSTANTS } from './errorManager';
import { validateMux } from './muxUtils';
import deviceInfo from './utils/deviceInfo';

const collateMuxMetadata = ({
  assetData = {},
  playbackArgs = {},
  playbackData = {},
  userData = {},
  metadata = {},
  overrideData = {},
}) => {
  const isLoggedIn = _.get(metadata, 'tags.isLoggedIn');
  const analyticUserId = _.get(metadata, 'tags.analyticUserId');
  const viewerUserID = isLoggedIn ? analyticUserId : -1;
  const modelName = _.get(metadata, 'tags.modelName');
  const modelNameParsed = modelName
    ? typeof modelName === 'string' && modelName.replace(/\s/g, '')
    : null;
  const connectionType = _.get(metadata, 'tags.connectionType');
  const subscriptions = _.get(userData, 'subscriptions', []);
  const subPropertyId = _.get(subscriptions, '0.segmentTag');
  const videoTitle = _.get(assetData, 'title');
  const videoType = _.get(assetData, 'type');
  const videoId = _.get(assetData, 'id');
  const channelId = _.get(assetData, 'channel.id', videoId);
  const duration = _.get(assetData, 'duration');
  const videoContentType = _.get(
    assetData,
    'categoryId',
    _.get(assetData, 'categorySlug'),
  );
  const parsedDeviceName = deviceInfo();
  const isChromecastLegacy = _.includes(['Chromecast 1'], parsedDeviceName)
    ? 'chromecast_legacy'
    : false;

  const deviceId = _.get(playbackArgs, 'deviceId');
  const competitionTitle = _.get(assetData, 'match.competition.title');
  const playbackItem = _.get(playbackData, 'items.item.0', {});
  const encodingType = _.get(playbackItem, 'encodingType');
  const videoJobID = _.get(playbackItem, 'videoJobID');
  const videoLanguage = _.get(playbackItem, 'videoLanguage');
  const videoCdn = _.get(playbackItem, 'selectedCDN');
  const streamType = _.get(playbackData, 'streamType');
  const videoOrigin = _.get(playbackItem, 'videoOrigin');
  const ssaiProvider = _.get(playbackItem, 'ssaiProvider');
  // 21-Apr-2021: streamType should have this following calculation done in middlelayer
  // const isSSAIEnabled = _.get(playbackData, 'isSSAIEnabled', false);
  // const ssaiValue = value => (value ? '_ssai' : '');
  // const collatedStream = streamType
  //   ? `${streamType}${ssaiValue(isSSAIEnabled)}`
  //   : false;

  const retryCount = _.get(overrideData, 'retryCount', 0);
  const isSsaiSdkEnabled = _.get(overrideData, 'isYspSdkEnabled', false);

  const muxData = {
    env_key: MUX_CUSTOMER_KEY,
    video_id: videoType === 'linear-channel' ? channelId : videoId,
    video_title: videoTitle,
    viewer_user_id: viewerUserID,
    experiment_name: 'ShakaPlayer',
    page_type: videoType,
    player_init_time: Date.now(),
    // OSN-1165 - chromecast gen 1 devices should be separated into different player name
    player_name: isChromecastLegacy || PLATFORM,
    player_version: `${PLATFORM}_${APP_VERSION}${
      modelNameParsed ? `_${modelNameParsed.toLowerCase()}` : ''
    }`,
    video_cdn: videoCdn,
    video_content_type: videoContentType,
    video_duration: duration,
    video_encoding_variant: encodingType,
    video_language_code: videoLanguage,
    video_variant_id: videoJobID,
    video_stream_type: streamType,
    video_producer: competitionTitle,
    viewer_connection_type: connectionType,
    view_session_id: deviceId,
    sub_property_id: subPropertyId,
    custom_1: retryCount,
    custom_3: isSsaiSdkEnabled,
    custom_4: ANALYTIC_DEFAULT_VENUE_MODE,
    custom_5: videoOrigin,
    custom_6: ANALYTIC_DEFAULT_VENUE_MODE,
    custom_7: ssaiProvider,
  };

  if (isChromecastLegacy) {
    muxData.player_software_version = isChromecastLegacy;
  }

  return muxData;
};

class MuxAnalytics {
  constructor({ playerManager }) {
    this.playerManager = playerManager;
    this.muxInstance = null;
  }

  inSession() {
    return this.muxInstance != null;
  }

  start(props = {}, debug) {
    if (!validateMux()) return;
    this.stop();

    const data = collateMuxMetadata(props);

    // Testing setting new parameters:
    // minimumRebufferDuration: 1000,
    // sustainedRebufferThreshold: 2000
    // const parsedDeviceName = deviceInfo();
    // const isGoogleTv = parsedDeviceName === 'Chromecast with Google TV';
    // const minimumRebufferDuration = !isGoogleTv ? 1000 : null;
    // const sustainedRebufferThreshold = !isGoogleTv ? 2000 : null;
    const minimumRebufferDuration = 1000;
    const sustainedRebufferThreshold = 2000;

    initChromecastMux(this.playerManager, {
      debug: debug, // true: show logs || false: hide logs
      data: {
        ...data,
      },
      ...(minimumRebufferDuration && { minimumRebufferDuration }),
      ...(sustainedRebufferThreshold && { sustainedRebufferThreshold }),
    });

    // Mux is in session
    this.muxInstance = true;
  }

  stop() {
    if (!this.inSession()) {
      return;
    }
    if (typeof this.playerManager.muxListener !== undefined) {
      this.playerManager.mux.destroy(this.playerManager);
      this.muxInstance = null;
    }
  }

  sendError(err) {
    if (!this.inSession()) {
      return;
    }

    const errorCodeMapper = name =>
      ({
        // Custom stalled
        [ERROR_CONSTANTS.CUSTOM_STALLED]: 101,
        // Custom stalled start
        [ERROR_CONSTANTS.CUSTOM_STALLED_START]: 102,
        // Stream kicked
        [ERROR_CONSTANTS.CHUCK_NORRIS]: 103,
        // Session time exceeded kicked
        [ERROR_CONSTANTS.ZOMBIE]: 104,
        // All other errors that are not specified
        [ERROR_CONSTANTS.GENERIC_ERROR]: 100,
      }[name || null]);

    // Default to generic error 100
    const getCode = _.get(
      err,
      'detailedErrorCode',
      ERROR_CONSTANTS.GENERIC_ERROR,
    );
    const getReason = _.get(err, 'reason');
    const getMessage = _.get(
      err,
      'message',
      'General playback error. Playback is stopped.',
    );
    const getSeverity = _.get(err, 'severity');
    // Check if errorCodeMapper returns null, if it does send 100
    const playerErrorCode = errorCodeMapper(getCode) || 100;

    if (playerErrorCode !== 103 && playerErrorCode !== 104) {
      // Construct Error Message
      const playerErrorMessage = [
        'errorCode:',
        playerErrorCode,
        '| code:',
        getCode,
        '| reason:',
        getReason,
        '| message:',
        getMessage,
        '| severity:',
        getSeverity,
      ].join(' ');

      this.playerManager.mux.emit('error', {
        player_error_code: playerErrorCode,
        player_error_message: playerErrorMessage,
      });
    }
  }
}

export default MuxAnalytics;
