const isHevcSupported = () => {
  // https://developers.google.com/cast/docs/media#video_codecs
  try {
    const context = cast.framework.CastReceiverContext.getInstance();

    return context.canDisplayType('video/mp4; codecs=hev1.1.6.L93.B0');
  } catch (e) {
    // do nothing
  }
  return false;
};

export default isHevcSupported;
