import addStyle from './addStyle';
import addTextNode from './addTextNode';
import addAttribute from './addAttribute';
import pipe from '../utils/pipe';

const appendChild = domTarget => child => {
  domTarget.appendChild(child.topNode);
  return child;
};

const generateDomNodes = (haystack = []) => {
  const result = haystack.map(({ attr, style, text, elem }) =>
    pipe(
      addAttribute(attr),
      addStyle(style),
      addTextNode(text),
    )(document.createElement(elem)),
  );

  return result;
};

const nestAllElements = ([firstNode, ...otherNodes]) => {
  return otherNodes.reduce(
    (result, currentNode) => {
      result.previousNode.appendChild(currentNode);
      return { ...result, previousNode: currentNode };
    },
    { topNode: firstNode, previousNode: firstNode },
  );
};

const factory = target =>
  pipe(generateDomNodes, nestAllElements, appendChild(target));

export default factory;
