const showPause = isActive => target => {
  const element = target.querySelector('#ad__controls');
  if (!isActive && element) {
    if (element.style.display === 'none') {
      return;
    }
    element.style.setProperty('display', 'none');
  }
  if (isActive && element) {
    if (element.style.display === 'block') {
      return;
    }
    element.style.setProperty('display', 'block');
  }
};

export default showPause;
