/* eslint-disable class-methods-use-this */
import { get, includes, isEmpty } from 'lodash';
import { AnalyticEventObserver } from '@yospace/admanagement-sdk';
import {
  adTypesMapping,
  prerollMidrollThreshold,
  videoTypes,
} from '../segment/segmentHelper';
import yospaceLog from './yospaceLog';
import { types } from '../../stateManager';

export default class YospaceAnalyticEventObserver extends AnalyticEventObserver {
  constructor({
    player,
    playbackData,
    setState,
    getState,
    sessionManager: session,
    segmentService,
    logData,
  }) {
    super();
    this.session = session;
    this.segmentService = segmentService;
    this.player = player;
    this.getState = getState;
    this.setState = setState;
    this.playbackData = playbackData;
    this.adsPlayingClassName = 'info-playing';
    this.playContentClassName = 'play-content';
    this.prerollMidrollThreshold = 5000; // milliseconds
    this.logData = logData;
    this.advertStartCount = 0;
    this.advertEndCount = 0;
    this.learnMoreEl = '';
  }

  isNoAdPlayed() {
    return (
      this.segmentService?.isInAdBreak() &&
      this.advertStartCount === this.advertEndCount &&
      this.advertStartCount + this.advertEndCount === 0
    );
  }

  isAdPlayedEven = () => {
    return (
      !this.isNoAdPlayed() && this.advertStartCount === this.advertEndCount
    );
  };

  outsideAdBreakLog = () => {
    if (!this.segmentService?.isInAdBreak()) {
      yospaceLog(this.logData, this.segmentService).error('outsideAdBreak', this.logData);
    }
  };

  adBreakStartLog = () => {
    if (this.segmentService?.isInAdBreak()) {
      yospaceLog(this.logData, this.segmentService).error('unexpectedAdBreakStart', this.logData);
    }
  };

  advertStartLog = isAdvert => {
    if (this.segmentService?.isInAdBreak() && isAdvert) {
      this.advertStartCount++;
    }
    this.outsideAdBreakLog();
  };

  advertEndLog = () => {
    if (this.segmentService?.isInAdBreak()) {
      this.advertEndCount++;
    }
    this.outsideAdBreakLog();
  };

  adBreakEndLog = () => {
    if (this.segmentService?.isInAdBreak()) {
      if (this.isNoAdPlayed()) {
        yospaceLog(this.logData, this.segmentService).error('emptyAdBreak', this.logData);
      }

      if (!this.isAdPlayedEven()) {
        yospaceLog(this.logData, this.segmentService).error('unevenAdBreak', this.logData);
      }

      this.advertStartCount = 0;
      this.advertEndCount = 0;
    } else {
      yospaceLog(this.logData, this.segmentService).error('unexpectedAdBreakEnd', this.logData);
    }
  };

  getAdSchema = (currentAdvert, adType) => {
    const { identifier, yospaceIdentifier } = currentAdvert;
    const assetId = `${identifier}_YO_${yospaceIdentifier}`;
    const currentAdBreak = get(this.session, 'currentAdBreak', '');
    let adBreakIdentifier = get(currentAdBreak, 'identifier', '');

    // yospace non live assets session  returns  for mid-roll  type as mid-roll-1/ mid-roll-2 so formatting it to mid-roll for segment purpose
    adBreakIdentifier =
      adBreakIdentifier && includes(adBreakIdentifier, 'mid')
        ? 'midroll'
        : adBreakIdentifier;

    const finalAdBreakType = get(
      adTypesMapping,
      adBreakIdentifier,
      get(adTypesMapping, adType),
    );
    const adSequence = get(currentAdvert, 'sequence', '');
    const adCount = currentAdBreak.adverts.length;

    return !isEmpty(currentAdvert)
      ? {
          assetId,
          position: adSequence,
          length: adCount,
          type: finalAdBreakType || adBreakIdentifier || adType,
          isFirstAd: adSequence === 1,
          isLastAd: adSequence === adCount,
        }
      : {};
  };

  getAdCompletedPosition = () => {
    const {
      ADVERT_START_TIME: advertStartTime = 0,
      IS_LIVE: isLive,
      EPOCH_ADVERT_START: epochAdvertStart,
    } = this.getState();
    const currentAdDuration = get(
      this.session,
      ['currentAdvert', 'advert', 'container', 'duration'],
      0,
    );

    if (!currentAdDuration) {
      if (isLive) {
        const currentEpoch = Date.now();
        return Math.round((currentEpoch - epochAdvertStart) / 1000);
      }
      const currentTime = this.player ? this.player.getCurrentTime() : 0;
      return Math.round(currentTime > 0 ? currentTime - advertStartTime : 0);
    }
    return Math.round(currentAdDuration);
  };

  /**
   * Called when an advertisement break starts
   */
  onAdvertBreakStart(brk = {}) {
    // console.log('############# ADBREAK START #############', brk);
    if (!brk) {
      return;
    }

    this.adBreakStartLog();
    const { startMillis = 0, adverts, duration: adbreakDuration } = brk;
    const duration = adbreakDuration / 1000;
    const adStartPosition = startMillis / 1000;
    const { live } = this.playbackData;

    // There is a race condition, so instead we will ping player directly for position
    // const { CURRENT_TIME = 0 } = getState() || {};
    const CURRENT_TIME = this.player.getCurrentTime();
    // We need to adjust the duration as users can scrub into ad breaks for VOD content
    // i.e. user can enter ad break at 10sec out of the 30sec ad break; so duration should
    // be 20sec instead of 30sec
    let durationOffset = 0;
    if (!live && CURRENT_TIME > adStartPosition) {
      durationOffset = CURRENT_TIME - adStartPosition;
    }

    const actualDuration = duration - durationOffset;

    let breakType;
    if (adverts) {
      this.segmentService?.setVideoType({ type: videoTypes.ad });
      if (live) {
        const playbackStartTime = this.segmentService?.getPlaybackStartTime();
        const currentTimeEpoch = new Date().getTime();
        breakType =
          currentTimeEpoch - playbackStartTime < prerollMidrollThreshold
            ? 'preroll'
            : 'midroll';
      } else {
        breakType = CURRENT_TIME < 1 ? 'preroll' : 'midroll';
      }
    }

    this.setState({
      [types.AD_DURATION]: actualDuration > 0 ? actualDuration : duration,
      [types.AD_START_TIME]: CURRENT_TIME,
      [types.AD_REAL_START_TIME]: adStartPosition,
      [types.ADBREAK_START]: true,
      [types.ADBREAK_TYPE]: breakType,
    });
  }

  /**
   * Called when playback of an advertisement starts
   */
  onAdvertStart(advert) {
    // console.log('############# ADVERT START #############', advert);
    const { ADBREAK_TYPE: adType } = this.getState();
    if (advert) {
      // Record playhead position if sequence 1
      // pass the adType for live assets as yosession manager does not return ad break identifier(preroll/postroll)
      const adSchema = this.getAdSchema(advert, adType);
      if (!isEmpty(adSchema)) {
        this.setState({
          [types.EPOCH_ADVERT_START]: Date.now(), // Advert Epoch time stamp used to calculate position for advert playing
          [types.AD_SCHEMA]: adSchema,
          [types.ADVERT_START_TIME]: this.player.getCurrentTime(),
        });
        this.segmentService?.trackAdStarted({ position: 0 });
      }
    }
    this.advertStartLog(advert);
  }

  /**
   * Called when playback of an advertisement ends
   */
  onAdvertEnd() {
    // console.log('############# ADVERT END #############');
    const {
      TOTAL_AD_WATCHED_DURATION: totalAdWatchedDuration,
      AD_SCHEMA: adSchema,
      IS_LIVE: isLive,
    } = this.getState();
    const isVideoContentStarted = this.segmentService?.getIsVideoContentStarted();
    this.advertEndLog();
    if (adSchema) {
      const { isLastAd } = adSchema;
      const adCompletedPosition = this.getAdCompletedPosition();
      this.segmentService?.trackAdCompleted({ position: adCompletedPosition });
      const adWatchDuration = totalAdWatchedDuration + adCompletedPosition;
      this.setState({
        [types.TOTAL_AD_WATCHED_DURATION]: adWatchDuration,
      });
      if (isLastAd && !isLive) {
        this.segmentService?.setVideoType({ type: videoTypes.content });
        if (!isVideoContentStarted) {
          this.segmentService?.handleStarted();
        }
      }
      this.setState({
        [types.AD_SCHEMA]: null,
        [types.ADVERT_START_TIME]: 0,
      });
    }
  }

  /**
   * Called when an advertisement break ends
   */
  onAdvertBreakEnd() {
    // console.log('############# AD BREAK END #############');

    this.adBreakEndLog();
    this.segmentService?.setVideoType({ type: videoTypes.content });
    this.setState({
      [types.AD_DURATION]: 0,
      [types.AD_START_TIME]: 0,
      [types.AD_REAL_START_TIME]: 0,
      [types.ADBREAK_START]: false,
      [types.ADBREAK_TYPE]: null,
      [types.IS_PAUSED]: false,
      [types.AD_SCHEMA]: null,
    });
  }

  /**
   * Indicates that a VMAP payload was received and processed from the Central Streaming Manager during playback
   * of a stream in respect of upcoming adverts. A client can read the adBreak update from the Session.
   */
  onAnalyticUpdate() {
    // console.log('=====Analytic update=====');
  }

  /**
   * Called when a tracking URL is called against the specified Advert
   */
  onTrackingEvent(type, urls) {
    // console.log('=====Tracking event=====', type, urls);
  }
}
