function assertKey() {
  throw new Error(
    'Must provide the analytics identifier (i.e. "conviva-analytics").',
  );
}

function assertAnalytics() {
  throw new Error(
    'Must provide the specific analytics object (i.e. "new ConvivaAnalytics").',
  );
}

class Analytics {
  analytics = {};

  constructor(analytics = {}) {
    this.analytics = analytics;
  }

  use(key = assertKey(), analytics = assertAnalytics()) {
    this.analytics[key] = analytics;
  }

  get(key = assertKey()) {
    return this.analytics[key];
  }

  getAll() {
    return Object.entries(this.analytics);
  }

  startAll(data) {
    this.getAll().forEach(([key]) => this.get(key).start(data));
  }

  resumeAll() {
    this.getAll().forEach(([key]) => this.get(key).resume());
  }

  stopAll() {
    this.getAll().forEach(([key]) => this.get(key).stop());
  }
}

export default Analytics;
