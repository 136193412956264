import Http from './Http';
import {
  getAssetMetaDataUrl,
  getPlaybackUrlArgs,
  isFreeAssetFromRequest,
  isFreeAssetFromAccessGroups,
  playbackUrls,
  getPlaybackFlag,
  isPlaybackLive,
  getPlaybackLicenseUrl,
  getPlaybackLicenseDrmData,
  getPlaybackContentId,
  isFreeAssetFromCustomData,
  getPlaybackStreamType,
  getPlaybackIsSSAIEnabled,
  getPlaybackDrmProtected,
} from './playbackUtils';
import { getIdToken, isDRMode, getDRModeUrl } from './requestUtils';
import { apiUrls, DR_MODE_SIMULATION } from './constants';
import { isDash } from './urlUtils';
import drModeAssets from './__tests__/dr-mode/assets-chromecast';

class Asset {
  constructor() {
    this.playbackArgs = null;
  }

  getMetadata() {
    return this.assetData || {};
  }

  getPlaybackData() {
    return this.playbackData || {};
  }

  async updateAssetData(loadRequest) {
    try {
      const assetMetaDataUrl = getAssetMetaDataUrl(loadRequest);
      this.assetData = await Http.get(assetMetaDataUrl);
    } catch (err) {
      // TODO: Remove this if falling back to /web on 404 is regarded as a bug.
      // Asset is 404. Attempt to asset from another source.
      const assetMetaDataUrl = getAssetMetaDataUrl(loadRequest, 'web');
      this.assetData = await Http.get(assetMetaDataUrl);
    }
  }

  async updatePlaybackData({ loadRequest, segmentService, config }) {
    if (isDRMode(loadRequest)) {
      this.drModeAssets = await Http.get(apiUrls.drModeAssetsChromecastUrl)
        // This catch statement may not be necessary as long as there is a guarantee
        // that the DR Mode Assets URL always exist.
        .catch(() => (DR_MODE_SIMULATION ? drModeAssets : {}));
      this.playbackData = {};
      return;
    }
    this.playbackData = {};
    const idToken = getIdToken(loadRequest);

    // Get Asset data
    const isFreeAsset = await this.isFreeAsset(loadRequest);
    const isLive = this.isLive();

    // Fire off Playback Requested here as earliest possible for asset data available
    segmentService?.handlePlaybackRequested();

    const playbackArgs = getPlaybackUrlArgs(
      { ...loadRequest, isFreeAsset, isLive },
      config,
    );
    const playbackUrl = isFreeAsset ? playbackUrls.free : playbackUrls.general;

    // Get Playback data
    const resPlaybackData = await Http.get(playbackUrl(playbackArgs), {
      headers: Http.createAuthHeaders(idToken),
      segmentParams: {
        segmentService,
        segmentEvent: isFreeAsset
          ? 'apiMlFreePlayback'
          : 'apiMlGeneralPlayback',
      },
    });

    segmentService?.trackCustomEvent('playerManagerUpdatePlaybackData', {
      eventDetails: {
        playbackUrl: playbackUrl(playbackArgs),
        playbackArgs,
        resPlaybackData,
      },
    });

    this.playbackArgs = playbackArgs;
    this.playbackData = resPlaybackData?.playback || {};

    this.drModeAssets = null;
  }

  getAssetPlaybackArgs() {
    if (!this.playbackArgs) {
      return null;
    }
    return this.playbackArgs;
  }

  async isFreeAsset(loadRequest) {
    if (!this.assetData) {
      await this.updateAssetData(loadRequest);
    }

    if (isFreeAssetFromCustomData(loadRequest)) {
      return true;
    }

    const isFree = isFreeAssetFromRequest(loadRequest);
    if (isFree !== undefined) {
      return isFree;
    }
    const { accessGroups } = this.assetData;
    return isFreeAssetFromAccessGroups(accessGroups);
  }

  getMatchData() {
    if (!this.assetData) {
      return null;
    }
    const { match } = this.assetData;
    return match;
  }

  getAssetLive() {
    if (!this.assetData) {
      return null;
    }

    const { isLive } = this.assetData;
    return isLive;
  }

  getFlag() {
    return getPlaybackFlag(this.getPlaybackData());
  }

  isLive() {
    return Object.keys(this.getPlaybackData()).length === 0
      ? this.getAssetLive()
      : isPlaybackLive(this.getPlaybackData());
  }

  getLicenseUrl() {
    return getPlaybackLicenseUrl(this.getPlaybackData());
  }

  getLicenseDrmData() {
    return getPlaybackLicenseDrmData(this.getPlaybackData());
  }

  getStreamType() {
    return getPlaybackStreamType(this.getPlaybackData());
  }

  getIsSSAIEnabled() {
    return getPlaybackIsSSAIEnabled(this.getPlaybackData());
  }

  getDRModeAssetsMatchingDashUrl(drModeUrl) {
    return this.drModeAssets.filter(({ video }) => {
      const { dash } = video || {};
      return dash === drModeUrl;
    });
  }

  getDRModeUrl(loadRequest) {
    const drModeUrl = getDRModeUrl(loadRequest);
    if (!isDash(drModeUrl)) {
      return drModeUrl;
    }
    if (!this.drModeAssets) {
      return drModeUrl;
    }
    // Additional rules applied for picking up the DR Mode URL
    const [drModeAsset] = this.getDRModeAssetsMatchingDashUrl(drModeUrl);
    const { hls = drModeUrl } = drModeAsset || {};
    return hls;
  }

  getPlaybackUrl(loadRequest) {
    if (isDRMode(loadRequest)) {
      return this.getDRModeUrl(loadRequest);
    }
    return getPlaybackContentId(this.getPlaybackData());
  }

  getPlaybackDrmProtected() {
    return getPlaybackDrmProtected(this.getPlaybackData());
  }

  getCustomTags() {
    const { publishStartDate } = this.getMetadata();
    const { homeTeam = {}, awayTeam = {} } = this.getMatchData() || {};
    const playbackStreamType = this.getStreamType();
    // const playbackIsSSAIEnabled = this.getIsSSAIEnabled();
    // const finalPlaybackStreamType = `${playbackStreamType}${
    //   playbackIsSSAIEnabled ? '_ssai' : ''
    // }`;

    return {
      OptusFarm: this.getFlag(),
      pubDate: publishStartDate,
      homeTeamId: homeTeam.id,
      awayTeamId: awayTeam.id,
      streamType: playbackStreamType,
    };
  }

  isBeforeBroadcastEndTime() {
    const { broadcastEndTime } = this.getMetadata();
    const currentTime = Date.now();

    return broadcastEndTime ? currentTime < broadcastEndTime : true;
  }
}

export default Asset;
