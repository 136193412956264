/* eslint-disable */
const initialState = {
  ADBREAK_START: false,
  IS_PAUSED: false,
  AD_DURATION: 0,
  AD_REMAINING: 0,
  AD_START_TIME: 0,
  AD_REAL_START_TIME: 0,
  ADVERT_START_TIME: 0,
  CURRENT_TIME: 0,
  TOTAL_AD_WATCHED_DURATION: 0,
  ADBREAK_TYPE: '',
  AD_SCHEMA: null,
  IS_LIVE: false,
  EPOCH_ADVERT_START: 0,
  LIVE_PAUSED_EPOCH_TIME: 0, //used to fix position values in seek_start event which is being triggered after 'playback resume' event
};

const types = {
  ADBREAK_START: 'ADBREAK_START',
  ADVERT_START: 'ADVERT_START',
  ADVERT_END: 'ADVERT_END',
  ADBREAK_END: 'ADBREAK_END',
  UPDATE_TIMELINE: 'UPDATE_TIMELINE',
  ANALYTICS_FIRED: 'ANALYTICS_FIRED',
  AD_DURATION: 'AD_DURATION',
  CURRENT_TIME: 'CURRENT_TIME',
  AD_START_TIME: 'AD_START_TIME',
  AD_REAL_START_TIME: 'AD_REAL_START_TIME',
  AD_REMAINING: 'AD_REMAINING',
  ADBREAK_TYPE: 'ADBREAK_TYPE',
  ADVERT_START_TIME: 'ADVERT_START_TIME',
  IS_PAUSED: 'IS_PAUSED',
  IS_LIVE: 'IS_LIVE',
  AD_SCHEMA:'AD_SCHEMA',
  EPOCH_ADVERT_START: 'EPOCH_ADVERT_START',
  TOTAL_AD_WATCHED_DURATION: 'TOTAL_AD_WATCHED_DURATION',
  LIVE_PAUSED_EPOCH_TIME: 'LIVE_PAUSED_EPOCH_TIME',
};

const stateManager = initialState => {
  let currentState = initialState;

  const subscribers = new Set();

  const updateState = newState => {
    currentState = newState;
    subscribers.forEach(callback => callback(newState));
  };

  const subscribe = callback => {
    subscribers.add(callback);
    return () => subscribers.delete(callback);
  };

  const setState = value => {
    updateState({ ...currentState, ...value });
  };

  const resetState = () => {
    updateState(initialState);
  };

  const getState = () => currentState;

  return {
    setState,
    getState,
    subscribe,
    resetState,
    getSubscriptions: () => [...subscribers.values()],
  };
};

export { initialState, types };
export default stateManager;
