const getYSSourceFile = isLive => {
  const result = {};
  let file;

  const sourceLive =
    'https://csm-e-optus-eb.tls1.yospace.com/csm/extlive/optusauprd01,LOOP-DASH.mpd?yo.up=https://test-edgecache.optusnet.com.au/v3/OptusSport1/pri/drm/avc/dash/scte/&yo.ap=https://test-edgecache.optusnet.com.au/ysp-creative/&yo.po=1';

  const sourceVod =
    'https://csm-e-optusvodpreprod-eb.tls1.yospace.com/csm/access/221676830/aHR0cHM6Ly9zdGFnaW5nLXJlZGlyZWN0b3Iub3B0dXNuZXQuY29tLmF1L3ZvZC1vcHR1cy1kL291dC92MS8wZTcyMDAzOTNhYTU0MTkxYWJjOTUxNDVkOWE4MDk3OS82YWM4YmE4Y2QyMjY0YTE3YWQ2MGI2ZDgxZGM5NTdhNS83MDc4OGMxYjQ4MDA0MmE1YmE1OGU5ODAwZmRjYzZiZC9tYW5pZmVzdC5tcGQ=';

  if (isLive) {
    file = /\?/.test(sourceLive)
      ? `${sourceLive}&yo.ac=true`
      : `${sourceLive}?yo.ac=true`;
  } else {
    file = /\?/.test(sourceVod)
      ? `${sourceVod}&yo.ac=true`
      : `${sourceVod}?yo.ac=true`;
  }

  if (file) {
    result.file = file;
  }

  return result;
};

export default getYSSourceFile;
