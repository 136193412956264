/* eslint-disable */
/*
 * ___USAGE:___
 * input: node (ex: use query selector)
 * output: node
 *
 * addStyle({ color: 'red', 'background-color': 'green'})(document.querySelector('thing'))
 * __Note:__
 * Accepts only css styles (hypen-case)
 */

const addStyle = (style = {}) => nodeElem => {
  if (!nodeElem || !Object.keys(style).length) {
    return;
  }
  const node = nodeElem;
  Object.entries(style).forEach(([prop, val]) => {
    const [value, prim = ''] = val.split('!');
    node.style.setProperty(prop, value, prim);
  });
  return node;
};

export default addStyle;
