import { SESSION_REFRESH_TIME_CHECK_BEFORE_EXPIRY, apiUrls } from './constants';
import {
  getIdToken,
  getRefreshToken,
  canUseProtectedAPIs,
} from './requestUtils';
import Token from './Token';
import Http from './Http';
import { createCustomEvent, events } from './events';
import Request from './Request';

// Copied from v2 logic but with eslint fixes
function generateSessionId() {
  let d = new Date().getTime();
  const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  const uuid = template.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

class Session extends EventTarget {
  sessionTime = 0;
  segmentService = null;

  static generateId() {
    return generateSessionId();
  }

  getId() {
    return this.id;
  }

  getRequest() {
    return this.request;
  }

  setRequest(loadRequest) {
    this.request = new Request(loadRequest, this.segmentService);
  }

  setSegmentService(segmentService) {
    this.segmentService = segmentService;
  }

  start(loadRequest) {
    if (loadRequest) {
      this.setRequest(loadRequest);
      this.stop();
      this.id = Session.generateId();
      if (canUseProtectedAPIs(loadRequest)) {
        const idToken = getIdToken(loadRequest);
        return this.setupNewTimeout(idToken, 0);
      }
    }
    return this.request;
  }

  setupNewTimeout(idToken, tokenRefreshTime) {
    const token = new Token(idToken, this.segmentService, tokenRefreshTime);

    // Build in a short circuit check here to ensure there is a valid expires from the token or else an
    // infinite loop of refreshing will occur
    if (typeof token.expiry() === 'undefined') {
      this.segmentService?.trackCustomEvent('sessionTokenInvalidNoExpires', {
        eventDetails: {
          idToken,
        },
      });
      return;
    }

    const refreshTime = token.getTimeoutBeforeExpiry(
      SESSION_REFRESH_TIME_CHECK_BEFORE_EXPIRY,
    );
    if (token.isExpired()) {
      this.segmentService?.trackCustomEvent('sessionTokenExpired', {
        eventDetails: {
          idToken,
        },
      });
      return this.tryRefresh();
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.tryRefresh, refreshTime);
    return this.request;
  }

  stop() {
    if (this.refreshTimeout) {
      this.id = null;
      clearTimeout(this.refreshTimeout);
    }
    this.dispatchEvent(createCustomEvent(events.session.STOP));
  }

  setSessionTime(sessionTime) {
    this.sessionTime = sessionTime;
  }

  getSessionTime() {
    return this.sessionTime;
  }

  handleRefresh(refreshResponse = {}) {
    const { AuthenticationResult } = refreshResponse;
    const { IdToken = null } = AuthenticationResult || {};
    this.getRequest().dispatchEvent(
      createCustomEvent(events.session.REFRESHED, IdToken),
    );
    return this.setupNewTimeout(IdToken, Date.now());
  }

  handleError = err => {
    this.dispatchEvent(createCustomEvent(events.session.ERROR, err));
  };

  tryRefresh = async () => {
    const loadRequest = this.request.raw();
    const idToken = getIdToken(loadRequest);
    const refreshToken = getRefreshToken(loadRequest);
    try {
      const response = await Http.post(apiUrls.refreshTokenUrl, {
        headers: Http.createAuthHeaders(idToken),
        body: { refreshToken },
      });
      return this.handleRefresh(response);
    } catch (err) {
      this.stop();
      this.handleError(err);
    }
    return null;
  };
}

export default Session;
