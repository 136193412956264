import moment from 'moment';
import 'moment-timezone';
import replaceNeedlesInHaystack from '../../utils/replaceNeedlesInHaystack';

export const timeFormat = 'YYYY-MM-DD HH:mm:ss';
export const prerollMidrollThreshold = 5000;

export const getTimezoneOffset = () => {
  const timezone = moment.tz(moment.tz.guess()).format('Z');

  const plusMinus = timezone.substring(0, 1);
  const timezoneOffset = moment.duration(timezone.substring(1)).asMinutes();

  return plusMinus === '+' ? timezoneOffset : -timezoneOffset;
};

export const segmentEvents = {
  videoPlaybackStarted: 'Video Playback Started',
  videoContentStarted: 'Video Content Started',
  videoContentPlaying: 'Video Content Playing',
  videoAdPlaying: 'Video Ad Playing',
  videoAdStarted: 'Video Ad Started',
  videoAdCompleted: 'Video Ad Completed',
  videoContentCompleted: 'Video Content Completed',
  videoPlaybackPaused: 'Video Playback Paused',
  videoPlaybackResumed: 'Video Playback Resumed',
  videoPlaybackCompleted: 'Video Playback Completed',
  videoPlaybackInterrupted: 'Video Playback Interrupted',
  videoPlaybackBufferStarted: 'Video Playback Buffer Started',
  videoPlaybackBufferCompleted: 'Video Playback Buffer Completed',
  videoPlaybackSeekStarted: 'Video Playback Seek Started',
  videoPlaybackSeekCompleted: 'Video Playback Seek Completed',
  videoPlaybackRequested: 'Video Playback Requested',
  yospaceInit: 'Yospace Init',
  yospaceError: 'Yospace Error',
  videoPlaybackError: 'Video Playback Error',
  apiMlPlayback: 'Api ML Playback',
  deviceUnsupported: 'Device Unsupported',
  appStart: 'App Start', // fires before chromecastConfig is loaded
  contextReady: 'Context Ready', // fires before chromecastConfig is loaded
  contextError: 'Context Error', // fires before chromecastConfig is loaded
  contextShutdown: 'Context Shutdown',
  sessionStopped: 'Session Stopped', // fires once before chromecastConfig is loaded
  sessionError: 'Session Error', // fires before chromecastConfig is loaded
  sessionRefreshed: 'Session Refreshed',
  sessionTokenExpired: 'Session Token Expired',
  sessionTokenUnexpectedToken: 'Session Token Unexpected Error',
  sessionTokenSetExpiry: 'Session Token Set Expiry',
  sessionTokenInvalidNoExpires: 'Session Token Invalid No Expires',
  streamKickerKicked: 'Stream Kicker Kicked',
  playerManifestHandler: 'Player Manifest Handler',
  playerSegmentRequestHandler: 'Player Segment Request Handler',
  playerLoadRequestTimeTooBig: 'Player Load Request Time Too Big',
  playerMessageBusSeek: 'Player Message Bus Seek',
  playerMessageBusMessage: 'Player Message Bus Message',
  playerMessageBusUpdateSeekableRange: 'Player Message Bus Update Seekable Range',
  playerMessageBusUpdateQualityGet: 'Player Message Bus Update Quality Get',
  playerMessageBusUpdateQualitySet: 'Player Message Bus Update Quality Set',
  playerManagerError: 'Player Manager Error',
  playerManagerUpdatePlaybackData: 'Player Manager Update Playback Data',
  playerManagerLoadRequestUpdated: 'Player Manager Load Request Updated',
};

export const segmentEventTypes = {
  video: 'video',
  ssai: 'ssai',
  sdk: 'sdk',
  api: 'api',
};

export const videoTypes = {
  content: 'content',
  ad: 'ad',
};

export const adTypesMapping = {
  preroll: 'pre-roll',
  pre: 'pre-roll',
  mid: 'mid-roll',
  midroll: 'mid-roll',
  post: 'post-roll',
  postroll: 'post-roll',
};

export const segmentEventCategory = {
  videoStartFailure: 'vsf',
  videoPlaybackFailure: 'vpf',
  errorBeforeVideoStart: 'ebvs',
  player: 'player',
  user: 'user',
  api: 'api',
  generalPlayback: 'os_ml_general_playback_api',
  freePlayback: 'os_ml_free_playback_api',
  heartbeat: 'heartbeat',
  trickplay: 'trick-play',
  buffer: 'buffer',
  adStarted: 'ad-started',
  adEnded: 'ad-ended',
  playerInitialised: 'player-initialized',
  contentStarted: 'content-started',
  contentEnded: 'content-ended',
  contentCompleted: 'content-completed',
  playerDestroyed: 'player-destroyed',
  yospace: 'yospace_init',
  videoPlayerKicked: 'vpk',
};

export const SegmentPropertyExclusionList = {
  LIVE: {
    [segmentEvents.videoAdStarted]: [
      'global_from_position',
      'global_to_position',
    ],
    [segmentEvents.videoAdCompleted]: [
      'global_from_position',
      'global_to_position',
    ],
  },
  VOD: {},
};

export const PlaybackPositionOverrideList = {
  LIVE: [
    segmentEvents.videoPlaybackBufferCompleted,
    segmentEvents.videoPlaybackBufferStarted,
    segmentEvents.videoPlaybackError,
    segmentEvents.videoPlaybackInterrupted,
    segmentEvents.videoPlaybackPaused,
    segmentEvents.videoPlaybackResumed,
    segmentEvents.videoPlaybackSeekStarted,
    segmentEvents.videoPlaybackSeekCompleted,
    segmentEvents.videoPlaybackFailure,
    segmentEvents.videoPlaybackPlayerError,
    segmentEvents.videoPlaybackStarted,
    segmentEvents.videoPlaybackCompleted,
  ],
  VOD: [
    segmentEvents.videoPlaybackBufferCompleted,
    segmentEvents.videoPlaybackBufferStarted,
    segmentEvents.videoPlaybackError,
    segmentEvents.videoPlaybackInterrupted,
    segmentEvents.videoPlaybackPaused,
    segmentEvents.videoPlaybackResumed,
    segmentEvents.videoPlaybackSeekStarted,
    segmentEvents.videoPlaybackSeekCompleted,
    segmentEvents.videoPlaybackFailure,
    segmentEvents.videoPlaybackPlayerError,
    segmentEvents.videoPlaybackStarted,
    segmentEvents.videoPlaybackCompleted,
  ],
};

export const SegmentErrorMapping = {
  videoPlaybackFailure: { type: 'stream', message: 'video playback failure' },
  videoStartFailure: { type: 'stream', message: 'video start failure' },
  videoPlaybackYoSpaceError: {
    type: 'sdk',
    message: 'yospace init error',
  },
  videoPlaybackApiError: {
    type: 'api',
    message: 'api fetch error',
  },
  videoPlaybackPlayerError: {
    type: 'player',
    message: 'player error',
  },
  videoPlayerKillInterrupt: { type: 'user', message: 'user switched asset' },
  videoPlayerKillStop: { type: 'user', message: 'user closed player' },
  videoPlayerKicked: {
    type: 'chuck-norris-kick',
    message: 'user exceed maximum concurrent streams',
  },
  videoPlayerZombie: {
    type: 'zombie-kick',
    message: 'user exceed maximum stream session time',
  },
};

export const SegmentEventMapping = {
  videoPlaybackApiError: {
    event: {
      name: segmentEvents.videoPlaybackError,
      type: segmentEventTypes.video,
      category: segmentEventCategory.api,
    },
  },
  videoPlaybackYoSpaceError: {
    event: {
      name: segmentEvents.videoPlaybackError,
      type: segmentEventTypes.video,
      category: segmentEventCategory.yospace,
    },
  },
  videoPlaybackPlayerError: {
    event: {
      name: segmentEvents.videoPlaybackError,
      type: segmentEventTypes.video,
      category: segmentEventCategory.player,
    },
  },
  videoPlaybackFailure: {
    event: {
      name: segmentEvents.videoPlaybackInterrupted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.videoPlaybackFailure,
    },
  },
  yospaceInit: {
    event: {
      name: segmentEvents.yospaceInit,
      type: segmentEventTypes.sdk,
      category: segmentEventCategory.yospace,
    },
  },
  apiMlFreePlayback: {
    event: {
      name: segmentEvents.apiMlPlayback,
      type: segmentEventTypes.api,
      category: segmentEventCategory.freePlayback,
    },
  },
  apiMlGeneralPlayback: {
    event: {
      name: segmentEvents.apiMlPlayback,
      type: segmentEventTypes.api,
      category: segmentEventCategory.generalPlayback,
    },
  },
  videoPlaybackRequested: {
    event: {
      name: segmentEvents.videoPlaybackRequested,
      type: segmentEventTypes.video,
      category: segmentEventCategory.user,
    },
  },
  videoPlaybackStarted: {
    event: {
      name: segmentEvents.videoPlaybackStarted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.playerInitialised,
    },
  },
  videoPlaybackCompleted: {
    event: {
      name: segmentEvents.videoPlaybackCompleted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.playerDestroyed,
    },
  },
  videoContentStarted: {
    event: {
      name: segmentEvents.videoContentStarted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.contentStarted,
    },
  },
  videoContentCompleted: {
    event: {
      name: segmentEvents.videoContentCompleted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.contentEnded,
    },
  },
  videoContentPlaying: {
    event: {
      name: segmentEvents.videoContentPlaying,
      type: segmentEventTypes.video,
      category: segmentEventCategory.heartbeat,
    },
  },
  videoPlaybackBufferStarted: {
    event: {
      name: segmentEvents.videoPlaybackBufferStarted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.buffer,
    },
  },
  videoPlaybackBufferCompleted: {
    event: {
      name: segmentEvents.videoPlaybackBufferCompleted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.buffer,
    },
  },
  videoPlaybackPaused: {
    event: {
      name: segmentEvents.videoPlaybackPaused,
      type: segmentEventTypes.video,
      category: segmentEventCategory.trickplay,
    },
  },
  videoPlaybackResumed: {
    event: {
      name: segmentEvents.videoPlaybackResumed,
      type: segmentEventTypes.video,
      category: segmentEventCategory.trickplay,
    },
  },
  videoPlaybackSeekStarted: {
    event: {
      name: segmentEvents.videoPlaybackSeekStarted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.trickplay,
    },
  },
  videoPlaybackSeekCompleted: {
    event: {
      name: segmentEvents.videoPlaybackSeekCompleted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.trickplay,
    },
  },
  videoAdStarted: {
    event: {
      name: segmentEvents.videoAdStarted,
      type: segmentEventTypes.ssai,
      category: segmentEventCategory.adStarted,
    },
  },
  videoAdCompleted: {
    event: {
      name: segmentEvents.videoAdCompleted,
      type: segmentEventTypes.ssai,
      category: segmentEventCategory.adEnded,
    },
  },
  videoAdPlaying: {
    event: {
      name: segmentEvents.videoAdPlaying,
      type: segmentEventTypes.ssai,
      category: segmentEventCategory.heartbeat,
    },
  },
  videoStartFailure: {
    event: {
      name: segmentEvents.videoPlaybackInterrupted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.videoStartFailure,
    },
  },
  videoPlayerKicked: {
    event: {
      name: segmentEvents.videoPlaybackInterrupted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.videoPlayerKicked,
    },
  },
  videoPlayerZombie: {
    event: {
      name: segmentEvents.videoPlaybackInterrupted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.videoPlayerKicked,
    },
  },
  videoPlayerKillInterrupt: {
    event: {
      name: segmentEvents.videoPlaybackInterrupted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.errorBeforeVideoStart,
    },
  },
  videoPlayerKillStop: {
    event: {
      name: segmentEvents.videoPlaybackInterrupted,
      type: segmentEventTypes.video,
      category: segmentEventCategory.errorBeforeVideoStart,
    },
  },
};

export const getEventTrackingProps = key => {
  const commonEventProps = SegmentEventMapping[key]
    ? SegmentEventMapping[key].event
    : {};

  return {
    ...commonEventProps,
  };
};

export const getHttpRequestResponsePayloadForApi = (
  apiUrl,
  apiRequestParam,
  apiResponse,
) => {
  const now = moment().format(timeFormat);

  const traversedResponse = apiResponse
    ? replaceNeedlesInHaystack({})(apiResponse)
    : {};

  const traversedRequestParams = apiRequestParam
    ? replaceNeedlesInHaystack({})(apiRequestParam)
    : {};

  const traversedHeaders = apiResponse
    ? replaceNeedlesInHaystack({})(apiResponse.headers)
    : {};

  const httpPayload = apiResponse
    ? {
        success: !apiResponse.errorStatus,
        request: {
          url: apiUrl,
          header: JSON.stringify(traversedRequestParams.headers) || {},
          requestTime: now,
        },
        response: {
          status: apiResponse.status || apiResponse.errorStatus,
          body: JSON.stringify(traversedResponse),
          header: JSON.stringify(traversedHeaders),
        },
      }
    : {};

  return httpPayload;
};

export const getErrorPropsForApi = errorStatus => {
  return errorStatus
    ? {
        error: {
          type: SegmentErrorMapping.videoPlaybackApiError.type,
          message: errorStatus,
        },
      }
    : {};
};
