/* eslint-disable */
const timeFormatter = timeInSeconds => {
  if (!timeInSeconds || timeInSeconds <= 0) {
    return '00:00';
  }
  const timestamp = Number(timeInSeconds.toFixed(0)) || 0;
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = timestamp % 60;

  const showHours = hours ? hours.toString().padStart(2, '0') + `:` : ``;
  const format =
    showHours +
    minutes.toString().padStart(2, '0') +
    `:` +
    seconds.toString().padStart(2, '0');
  return format;
};

export default timeFormatter;
