export default function yospaceLog(logData, segmentService) {
  function error(type) {
    const types = {
      default: {
        error: 'Yospace error',
      },
      init: {
        error: 'Yospace error | init error',
      },
      timeout: {
        error: 'Yospace error | timeout',
      },
      emptyAdBreak: {
        error: 'Yospace error | empty Ad break',
      },
      outsideAdBreak: {
        error: 'Yospace error | advert event outside Ad break',
      },
      unevenAdBreak: {
        error:
          'Yospace error | uneven advert start and advert end events within ad break',
      },
      unexpectedAdBreakStart: {
        error: 'Yospace error | unexpected AdBreakStart event during ad break',
      },
      unexpectedAdBreakEnd: {
        error:
          'Yospace error | unexpected AdBreakEnd event outside of ad break',
      },
    };

    const { error: typeError } = types[type || 'default'];

    segmentService?.trackCustomEvent('yospaceError', { error: typeError });
  }

  return {
    error,
  };
}
