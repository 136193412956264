import {
  STREAM_KICKING_INTERVAL,
  APP_VERSION,
  PLATFORM,
  apiUrls,
} from '../constants';
import ERROR_CONSTANTS from './constants';
import { createCustomEvent, events } from '../events';
import {
  getMediaCustomData,
  getIdToken,
  canUseProtectedAPIs,
  getContentId,
} from '../requestUtils';
import Http from '../Http';
import deviceInfo from '../utils/deviceInfo';

class StreamKicker extends EventTarget {
  start(session) {
    this.setSession(session);
    if (this.session) {
      this.request = this.session.getRequest();
    }
    if (this.request) {
      this.stop();
      const loadRequest = this.request.raw();
      if (canUseProtectedAPIs(loadRequest)) {
        this.interval = setInterval(this.tryKick, STREAM_KICKING_INTERVAL, {});
      }
    }
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  setSession(session) {
    this.session = session;
  }

  createAssetPayload = (loadRequest, viewProgressPosition) => {
    const contentId = getContentId(loadRequest);
    const {
      assetType,
      assetTitle,
      categoryTitle,
      videoSource,
    } = getMediaCustomData(loadRequest);
    const overrideAssetPayload =
      typeof this.overrideAssetPayload === 'function'
        ? this.overrideAssetPayload(viewProgressPosition)
        : this.overrideAssetPayload;

    return {
      asset: {
        id: contentId,
        title: assetTitle,
        type: assetType,
        category: categoryTitle,
        source: videoSource,
        viewProgress: 0,
        cdn: 'optus',
        ...overrideAssetPayload,
      },
    };
  };

  createDevicePayload = loadRequest => {
    const now = new Date().toISOString();
    const { castDeviceVersion, castDeviceId } = getMediaCustomData(loadRequest);
    const overrideDevicePayload =
      typeof this.overrideDevicePayload === 'function'
        ? this.overrideDevicePayload(loadRequest)
        : this.overrideDevicePayload;
    return {
      device: {
        osVersion: castDeviceVersion,
        deviceId: castDeviceId,
        deviceModel: deviceInfo(),
        deviceTime: now,
        network: {
          type: 'wifi',
        },
        ...overrideDevicePayload,
      },
    };
  };

  createBody = (loadRequest, viewProgressPosition) => {
    const sessionId = this.session.getId();
    return {
      sessionId,
      app: {
        version: APP_VERSION,
        platform: PLATFORM,
      },
      videoPlayer: {
        type: 'chromecast',
        version: '2.0',
      },
      ...this.createDevicePayload(loadRequest),
      ...this.createAssetPayload(loadRequest, viewProgressPosition),
    };
  };

  handleKick = response => {
    this.dispatchEvent(
      createCustomEvent(events.streamKicker.TRY_KICK, response),
    );

    const { data } = response;
    const { isKicked } = data.item;
    if (isKicked) {
      const sessionTime = this.session.getSessionTime();
      this.stop();
      this.dispatchEvent(
        createCustomEvent(events.streamKicker.KICKED, {
          response,
          sessionTime,
        }),
      );
    }
  };

  handleError = err => {
    this.dispatchEvent(createCustomEvent(events.streamKicker.ERROR, err));
  };

  tryKick = async ({ viewProgressPosition = 0 }) => {
    try {
      const loadRequest = this.request.raw();
      const idToken = getIdToken(loadRequest);
      const response = await Http.post(apiUrls.sessionManager, {
        headers: Http.createAuthHeaders(idToken),
        body: this.createBody(loadRequest, viewProgressPosition),
      });
      this.handleKick(response);
    } catch (err) {
      this.handleError(err);
    }
  };

  static createError() {
    return {
      detailedErrorCode: ERROR_CONSTANTS.CHUCK_NORRIS,
      reason: 'kicked',
      message:
        'You have exceeded the maximum number of concurrent streams. Playback is stopped.',
      severity: 'SEVERITY_WARNING',
    };
  }

  static createZombieError() {
    return {
      detailedErrorCode: ERROR_CONSTANTS.ZOMBIE,
      reason: 'kicked',
      message:
        'You have exceed the maximum stream session time. Playback is stopped.',
      severity: 'SEVERITY_WARNING',
    };
  }
}

export default StreamKicker;
