/* eslint-disable */
import queryString from 'query-string';
import Http from '../../Http';
import Asset from '../../Asset';
import { isWatchModeLive, isDRMode, getWatchMode } from '../../requestUtils';
import { getAnalyticUserId, getUserDetails } from '../../userUtils';
import { STALLED_TIMEOUT } from '../../constants';
import { ERROR_CONSTANTS } from '../../errorManager';
import { logManifest, isManifestEnabled } from '../../utils/manifestParser';

function setStartAtEdge(loadRequest) {
  // Live Streaming is in Alpha but still putting in the line below.
  // Source: https://developers.google.com/cast/docs/caf_receiver/live
  // eslint-disable-next-line no-param-reassign
  loadRequest.media.streamType = cast.framework.messages.StreamType.LIVE;
  // Use Infinity to start at live edge
  // Source: https://developers.google.com/cast/docs/player#frequently-asked-questions
  // eslint-disable-next-line no-param-reassign
  loadRequest.currentTime = Infinity;
}

// This is copied over from v2 logic
function workAroundForHugeCurrentTime(loadRequest, segmentService) {
  // fix issue when play live stream on client then cast, the cast is not working
  if (loadRequest.currentTime === 9223372036854776) {
    // console.log('>>> workaround currentTime - currentTime is to big, set to 0');
    segmentService?.trackCustomEvent('playerLoadRequestTimeTooBig');
    // see https://developers.google.com/cast/docs/reference/receiver/cast.receiver.MediaManager.PreloadRequestData#currentTime
    // eslint-disable-next-line no-param-reassign
    loadRequest.currentTime = undefined;
  }
}

class PlayerService extends EventTarget {
  setPlayerQuality(quality) {
    this.quality = quality || 'auto';
  }

  getPlayerQuality() {
    return this.quality || 'auto';
  }

  setSegmentService(segment) {
    this.segmentService = segment;
  }

  getSegmentService() {
    return this.segmentService;
  }

  setPlaybackConfig(playbackConfig) {
    this.playbackConfig = playbackConfig;
  }

  getPlaybackConfig() {
    return this.playbackConfig;
  }

  setSession(session) {
    this.session = session;
  }

  async start(session) {
    this.setSession(session);
    if (this.session) {
      this.request = this.session.getRequest();
    }
  }

  getSession() {
    return this.session;
  }

  async updateRequest({ config }) {
    this.asset = new Asset();
    const loadRequest = this.request.raw();

    // Get User data
    const [userData] = await getUserDetails(loadRequest);
    this.userData = userData;
    this.updateCustomData(loadRequest, this.userData);

    // Get Playback and Asset data
    await this.asset.updatePlaybackData({
      loadRequest: loadRequest,
      segmentService: this.segmentService,
      config,
    });
    this.updateLicense(loadRequest);
    this.updateStartPoint(loadRequest);
    this.updateContentId(loadRequest);
    this.updateQuality();
    this.session.setRequest(loadRequest);

    this.setWatchMode(loadRequest);
    return loadRequest;
  }

  getData() {
    const { userData } = this;
    return { ...this.asset, userData };
  }

  getAsset() {
    return this.asset;
  }

  getPlaybackData() {
    return this.getAsset().getPlaybackData();
  }

  shouldStartAtEdge(loadRequest) {
    return this.getAsset().isLive() && isWatchModeLive(loadRequest);
  }

  // eslint-disable-next-line class-methods-use-this
  updateQuality() {
    // Commenting out the code for now as it might have contributed to issues for some users
    // this.playbackConfig.initialBandwidth = 5928 * 1000; // TODO: Hardcode for now...
  }

  updateLicense(loadRequest) {
    const drmProtected = this.getAsset().getPlaybackDrmProtected();
    if (!drmProtected) {
      return;
    }

    if (isDRMode(loadRequest)) {
      return;
    }
    const licenseUrl = this.getAsset().getLicenseUrl();
    if (!licenseUrl) {
      return;
    }
    this.playbackConfig.licenseUrl = licenseUrl;
    // Chromecast always uses Widevine
    this.playbackConfig.protectionSystem =
      cast.framework.ContentProtection.WIDEVINE;
    this.playbackConfig.licenseRequestHandler = request => {
      const drmData = this.getAsset().getLicenseDrmData();
      if (drmData) {
        request.headers['X-AxDRM-Message'] = drmData;
      }
    };
  }

  updatePreferredVideoCodecs(codecs) {
    this.playbackConfig.shakaConfig.preferredVideoCodecs = codecs || [
      'avc1',
      'hvc1',
    ];
  }

  updateManifestAvailabilityWindowOverride(availabilityWindow) {
    this.playbackConfig.shakaConfig.manifest.availabilityWindowOverride =
      availabilityWindow || NaN;
  }

  updateStartPoint(loadRequest) {
    workAroundForHugeCurrentTime(loadRequest, this.segmentService);

    // Check if we need to resume at certain dvr time due to reloading of stream due to quality codec change
    // so we shouldn't update the currentTime anymore for live
    if (loadRequest.reloadRequested) {
      return;
    }

    // In DR Mode, all assets are live
    if (this.shouldStartAtEdge(loadRequest) || isDRMode(loadRequest)) {
      setStartAtEdge(loadRequest);
    }
  }

  updateContentId(loadRequest) {
    // eslint-disable-next-line no-param-reassign
    loadRequest.media.contentUrl = this.getAsset().getPlaybackUrl(loadRequest);
    // TODO: Testing... DRMODE
    if (isDRMode(loadRequest)) {
      // Assign content type as DASH even though underlying stream is HLS to force Shaka Player
      // as Media Player has audio sync issues with our stream -- this is the current setup
      // when DR stream is configured into CMS Asset
      // eslint-disable-next-line no-param-reassign
      loadRequest.media.contentType = 'application/dash+xml';

      // These are required if we specify content type as HLS which will use Media Player (MPL)
      // which currently has audio sync issues (audio is behind video)
      // loadRequest.media.contentType = 'application/vnd.apple.mpegurl';
      // loadRequest.media.hlsSegmentFormat = cast.framework.messages.HlsSegmentFormat.TS;
      // loadRequest.media.hlsVideoSegmentFormat = cast.framework.messages.HlsVideoSegmentFormat.MPEG2_TS;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updateCustomData(loadRequest, userData) {
    const analyticUserId = getAnalyticUserId(loadRequest, userData);
    // eslint-disable-next-line no-param-reassign
    loadRequest.media.customData.analyticUserId = analyticUserId;

    return loadRequest;
  }

  setFinalContentUrl(url) {
    this.finalContentUrl = url;
  }

  getFinalContentUrl() {
    return this.finalContentUrl || '';
  }

  getCdn(url) {
    const finalUrl = url || this.getFinalContentUrl();
    const params = queryString.parse(finalUrl);
    const yoUp = params && params['yo.up'];
    const cdnStreamUrl = yoUp || finalUrl;

    return cdnStreamUrl.search('akamaized') !== -1 ? 'akamai' : 'optus';
  }

  setWatchMode(loadRequest) {
    const mode = getWatchMode(loadRequest);

    this.playbackWatchMode = mode;
  }

  getWatchMode() {
    return this.playbackWatchMode;
  }

  async getRawManifest(manifestUrl, options = {}) {
    const url = manifestUrl || this.getFinalContentUrl();
    const defaultOptions = {
      text: true,
    };
    if (options.timeout) {
      defaultOptions.timeout = options.timeout;
    }
    if (options.text) {
      defaultOptions.text = options.text;
    }

    try {
      const data = await Http.get(url, defaultOptions);
      return data;
    } catch (error) {
      return error;
    }
  }
}

export function StalledHandleService({
  player,
  playerService,
  session,
  conviva,
  muxAnalytics,
  transitionToIdle,
  config,
  segmentStore,
  goodManifestStore,
  badManifestStore,
  segmentService,
}) {
  let timer;
  let poller;
  let lastPosition = 0;
  let sessionTime = 0;

  function stop() {
    if (timer) {
      clearTimeout(timer);
    }

    if (poller) {
      clearInterval(poller);
    }

    // Reset values
    lastPosition = 0;
    sessionTime = 0;
  }

  function start() {
    stop();
    poller = setInterval(() => {
      const playerState = player.getState();
      const currentPosition = Number.parseFloat(
        player.getCurrentTime(),
      ).toFixed(2);

      const isPaused = playerState === 'PAUSED';
      const isPlaying = lastPosition !== currentPosition && !isPaused;
      // console.log(`${lastPosition}---${currentPosition}---${isPaused}`);
      if (isPlaying) {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          // We need to see whether the stall occurred on start or during playback
          // Our rule: If the user has requested < 10 segments then it is fail at start
          const segmentRequests = segmentStore && segmentStore.getStore();
          const segmentRequestsLength =
            segmentRequests && segmentRequests.length;
          const customStallType =
            segmentRequestsLength < 10
              ? ERROR_CONSTANTS.CUSTOM_STALLED_START
              : ERROR_CONSTANTS.CUSTOM_STALLED;

          const trackJsCustomStalledEnabled = config.getConfig(
            'trackJsCustomStalled',
          );
          if (trackJsCustomStalledEnabled && window.TrackJS && TrackJS.track) {
            // Manually force an error so it gets pushed to TrackJS
            TrackJS.track(customStallType);
          }

          const manifestCustomStalledEnabled = isManifestEnabled({
            configParameter: 'manifestCustomStalled',
            config,
            playerService,
          });

          if (manifestCustomStalledEnabled) {
            // Manually http ping for manifest and log here vs already logging via manifestHandler in app.js
            // log level here will be custom_stalled (app.js has buffering and interval)
            const finalStreamUrl = playerService.getFinalContentUrl();
            if (finalStreamUrl) {
              const resManifestData = await playerService.getRawManifest(
                finalStreamUrl,
                {
                  timeout: 30000,
                },
              );

              const name = 'player_manifest_handler';
              const source = customStallType.toLowerCase();

              await logManifest({
                name,
                source,
                data: resManifestData,
                playerService,
                config,
                segmentStore,
                goodManifestStore,
                badManifestStore,
                segmentService,
              });
            }
          }

          const isIdle =
            playerState === cast.framework.messages.PlayerState.IDLE;
          if (!isIdle) {
            player.stop();
          }

          muxAnalytics.sendError({
            detailedErrorCode: [customStallType],
            message: 'playback video failure due to video stalled',
          });

          conviva.sendError({
            detailedErrorCode: [customStallType],
            message: 'playback video failure due to video stalled',
          });

          session.stop();
          transitionToIdle();
        }, STALLED_TIMEOUT);
      }

      if (isPaused) {
        clearTimeout(timer);
      }

      // if (playerState === cast.framework.messages.PlayerState.BUFFERING) {
      //   // Log when buffering
      // }

      lastPosition = currentPosition;

      // Session kickout workaround after sessionTime > x milliseconds
      sessionTime += 250;
      session.setSessionTime(sessionTime);
      const maxPlaySessionTime = config.getConfig('maxPlaySessionTimeMs');
      if (sessionTime >= maxPlaySessionTime) {
        // Segment track here - Track Zombie custom error event
        segmentService?.handleError({
          error: {
            code: ERROR_CONSTANTS.ZOMBIE,
          },
        });

        const isIdle = playerState === cast.framework.messages.PlayerState.IDLE;
        if (!isIdle) {
          player.stop();
        }

        // OSN-1261 - Removed due to noise and impact on Chromecast scores in Conviva/Mux
        // muxAnalytics.sendError({
        //   detailedErrorCode: [ERROR_CONSTANTS.ZOMBIE],
        //   message: 'playback video failure due to session time exceeded',
        //   reason: 'kicked',
        // });
        //
        // conviva.sendError({
        //   detailedErrorCode: [ERROR_CONSTANTS.ZOMBIE],
        //   message: 'playback video failure due to session time exceeded',
        //   reason: 'kicked',
        // });

        session.stop();
        transitionToIdle();
      }
    }, 250);
  }

  return {
    start,
    stop,
  };
}

export default PlayerService;
