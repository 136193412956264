import _ from 'lodash';
import moment from 'moment';
import {
  PLAYER_NAME,
  VENDOR,
  PLATFORM,
  ENV,
  APP_VERSION,
  MUX_NAME,
  MUX_VERSION,
  VIDEO_PLAYER_NAME,
  VIDEO_PLAYER_VERSION,
  segmentEnabled as SEGMENT_ENABLED,
  ANALYTIC_DEFAULT_VENUE_MODE,
} from '../../constants';
import {
  segmentEvents,
  SegmentErrorMapping,
  getTimezoneOffset,
  getEventTrackingProps,
  getErrorPropsForApi,
  getHttpRequestResponsePayloadForApi,
  videoTypes,
  SegmentPropertyExclusionList,
  PlaybackPositionOverrideList,
} from './segmentHelper';
import { getAnalyticUserId } from '../../userUtils';
import { getDeviceProps } from '../analytics/analyticsFactory';
import { userAgentInfo } from '../../uiUtils';
import deviceInfo from '../../utils/deviceInfo';
import { types } from '../../stateManager';
import Session from '../../Session';

class SegmentService extends EventTarget {
  heartbeatInterval = 60 * 1000;
  defaultHeartbeatAllowed = false;
  videoType = null;
  defaultVideoType = videoTypes.content;
  globalPosition = 0;
  globalFromPosition = 0;
  globalToPosition = 0;
  isPlaybackStarted = false;
  isFirstFrameRendered = false;
  isPlayingStarted = false;
  isVideoContentStarted = false;
  isSSAISDKEnabled = false;
  playbackStartTime = 0;
  globalIsBuffering = false;
  segmentEnabled = SEGMENT_ENABLED;

  constructor({ player, getState, setState, yospace }) {
    super();
    this.player = player;
    this.getState = getState;
    this.setState = setState;
    this.yospaceService = yospace;
  }

  setConfig = config => {
    this.config = config;
  };

  setVolume = ({ volume }) => {
    this.currentVolume = volume;
  };

  setBitrate = ({ rate }) => {
    this.currentBitrate = rate;
  };

  setQuality = ({ quality }) => {
    this.currentQuality = quality;
  };

  setPlaybackStarted = ({ started }) => {
    this.isPlaybackStarted = started;
  };

  setFirstFrameRendered = ({ rendered }) => {
    this.isFirstFrameRendered = rendered;
  };

  setIsPlayingStarted = ({ started }) => {
    this.isPlayingStarted = started;
  };

  setGlobalIsBuffering = ({ isBuffering }) => {
    this.globalIsBuffering = isBuffering;
  };

  setHeartbeatAllowed = ({ allowed }) => {
    this.heartbeatAllowed = allowed;
  };

  setVideoType = ({ type }) => {
    this.videoType = type;
  };

  setIsVideoContentStarted = isStarted => {
    this.isVideoContentStarted = isStarted;
  };

  setPlaybackStartTime = startTime => {
    this.paybackStartTime = startTime;
  };

  setYospaceService = yspService => {
    this.yospaceService = yspService;
  };

  setSegmentEnabled = segmentEnabled => {
    this.segmentEnabled = segmentEnabled;
  };

  setIsSSAISDKEnabled = isSSAISDKEnabled => {
    this.isSSAISDKEnabled = isSSAISDKEnabled;
  };

  // setYsSessionManage = ysSessionManager => {
  //   this.ysSessionManager = ysSessionManager;
  // };

  getIsVideoContentStarted = () => this.isVideoContentStarted;

  getPlaybackStartTime = () => this.playbackStartTime;

  getIsInInterval = () => this.eventPostInterval;

  isInAdBreak = () => this.videoType === videoTypes.ad;

  startSession = ({ session, playerService }) => {
    if (!this.segmentEnabled) {
      return;
    }
    this.session = session;
    this.playerService = playerService;

    this.setPlaybackStarted({ started: false });
    this.setIsVideoContentStarted(false);
    this.setFirstFrameRendered({ rendered: false });
    this.setIsPlayingStarted({ started: false });
    this.setGlobalIsBuffering({ isBuffering: false });
    this.setIsSSAISDKEnabled(false);

    this.clearInterval();
    this.setHeartbeatAllowed({ allowed: this.defaultHeartbeatAllowed });
    this.setVideoType({ type: this.defaultVideoType });
  };

  closeSession = () => {
    if (!this.segmentEnabled) {
      return;
    }
    this.setPlaybackStarted({ started: false });
    this.setFirstFrameRendered({ rendered: false });
    this.setIsPlayingStarted({ started: false });
    this.setGlobalIsBuffering({ isBuffering: false });
    this.setIsVideoContentStarted(false);

    this.clearInterval();
    this.resetPositions();
    this.setHeartbeatAllowed({ allowed: this.defaultHeartbeatAllowed });
    this.setVideoType({ type: this.defaultVideoType });

    this.session = null;
    this.playerService = null;
  };

  startInterval = () => {
    if (this.segmentEnabled && this.session && this.heartbeatAllowed) {
      this.clearInterval();

      this.eventPostInterval = window.setInterval(
        this.trackHeartbeatEvent,
        this.heartbeatInterval,
        // Test if this is required
        // this.getCommonTrackingProps
      );
    }
  };

  clearInterval = () => {
    if (this.segmentEnabled && this.eventPostInterval) {
      window.clearInterval(this.eventPostInterval);
      this.eventPostInterval = null;
    }
  };

  // Segment Video Content/Ad Playing event
  trackHeartbeatEvent = () => {
    const commonTrackingProps = this.getCommonTrackingProps({});

    this.setToPosition();

    const eventKey = this.isInAdBreak()
      ? 'videoAdPlaying'
      : 'videoContentPlaying';
    const trackEventName = segmentEvents[eventKey];

    // @TODO for SSAI: Override, customise props required here
    const props = {
      ...commonTrackingProps,
      event: getEventTrackingProps(eventKey),
      ...this.getPositions(),
    };

    this.trackEvent({
      event: trackEventName,
      props,
    });

    this.setFromPosition();
  };

  trackEvent = ({ event, props = {} }) => {
    if (!this.segmentEnabled) {
      return;
    }
    const segmentEventBlackList =
      (this.config && this.config.getConfig('segmentDisableConfig')) || [];

    if (segmentEventBlackList.indexOf(event) > -1) {
      return;
    }

    const { session_id, livestream } = props;
    const streamType = livestream ? 'LIVE' : 'VOD';
    const exclusionList = _.get(
      SegmentPropertyExclusionList,
      [streamType, event],
      [],
    );

    // the position value should be global position for all Video Playback * events during ads
    const shouldOverridePosition =
      _.indexOf(PlaybackPositionOverrideList[streamType], event) > -1;
    if (shouldOverridePosition) {
      props.position = this.globalPosition;
    }

    exclusionList.forEach(e => delete props[e]);
    if (window.analytics && event && session_id) {
      window.analytics.track(event, props);
    }
  };

  trackFinalHeartbeat = () => {
    this.clearInterval();
    this.trackHeartbeatEvent();
  };

  stopHeartbeat = () => {
    this.trackFinalHeartbeat();

    this.closeSession();
  };

  resetPositions = () => {
    this.globalFromPosition = 0;
    this.globalToPosition = 0;
  };

  // We should always set global from position === global to position
  // but there are unique cases where we need to set it to another
  // position such as on seek start events where we set it to the
  // requested seek position
  setFromPosition = position => {
    this.globalFromPosition =
      typeof position !== 'undefined' ? position : this.globalToPosition;
  };

  setPosition = ({ position, livestream }) => {
    // Note: For live assets we should calculate the
    // current timestamp in seconds + the current position
    // (the position should be negative when we have DVR)

    // @TODO: To check live position value here as it could be a positive number
    const globalPosition = livestream
      ? Math.round(new Date().getTime() / 1000 + position)
      : Math.round(position);
    this.globalPosition = globalPosition;
    return globalPosition;
  };

  setToPosition = () => {
    this.globalToPosition = this.globalPosition;
  };

  getPositions = () => {
    return {
      global_from_position: this.globalFromPosition,
      global_to_position: this.globalToPosition,
    };
  };

  isInAdBreakMode = isLive => {
    const ysSessionManager =
      this.yospaceService && this.yospaceService.getSessionManager();
    let isInAnAdvert = false;

    // only for vod assets check if its in advert mode by verifying asset timeline mode
    if (
      ysSessionManager &&
      !isLive &&
      this.player &&
      this.player.getCurrentTime
    ) {
      const { session = null } = ysSessionManager;
      if (session) {
        const { timeline = null } = session;
        const position = this.player.getCurrentTime();

        const assetTimeLine = timeline && timeline.getElementAtTime(position);
        isInAnAdvert = assetTimeLine && assetTimeLine.type === 'advert';
      }
    }

    return isInAnAdvert;
  };

  getAdTrackingProps = (currentPosition = 0) => {
    const ysSessionManager =
      this.yospaceService && this.yospaceService.getSessionManager();
    const {
      ADVERT_START_TIME: advertStartPosition = 0,
      EPOCH_ADVERT_START: epochAdvertStart = 0,
      IS_LIVE: isLive,
      AD_SCHEMA: adSchema,
    } = this.getState();

    let adProps = {};

    if (this.isInAdBreak() && ysSessionManager) {
      const { session = null } = ysSessionManager;
      if (session) {
        let currentAdPlayingPosition = 0;
        if (isLive) {
          const currentEpoch = Date.now();
          const adDurationWatchedSeconds =
            (currentEpoch - epochAdvertStart) / 1000;
          currentAdPlayingPosition = adDurationWatchedSeconds;
        } else {
          currentAdPlayingPosition =
            currentPosition > 0 ? currentPosition - advertStartPosition : 0;
        }
        adProps = {
          position: Math.round(currentAdPlayingPosition),
          ad_break: adSchema,
        };
      }
    }
    return adProps;
  };

  getCommonTrackingProps = ({ customProps = {} }) => {
    const { session_id: customSessionId = '' } = customProps;
    const session_id =
      (this.session && this.session.getId()) || customSessionId;
    const {
      TOTAL_AD_WATCHED_DURATION: totalAdWatchedDuration,
    } = this.getState();
    if (session_id) {
      const loadRequest = this.session && this.session.getRequest();
      const content_or_ad = this.videoType;
      const { assetData = {}, playbackData = {}, userData = {} } =
        (this.playerService && this.playerService.getData()) || {};

      // User
      const userLoggedIn = !_.isEmpty(userData);
      const userId = _.get(
        userData,
        'analyticUserId',
        getAnalyticUserId((loadRequest && loadRequest.raw()) || {}),
      );
      const userType = userLoggedIn
        ? _.get(userData, 'type')
        : 'logged-out-user';
      const userTags = _.get(userData, 'userSegmentTags');

      const userProps = {
        userId,
        userTags,
        userType,
        venueName: ANALYTIC_DEFAULT_VENUE_MODE,
      };

      // Playback
      const playbackLive = _.get(playbackData, 'live');
      const playbackVideoStreamType = _.get(playbackData, 'streamType');
      const playbackDrmProtected = _.get(playbackData, 'drmProtected');
      const playbackMaxStreams = _.get(
        playbackData,
        'streamRule.maxSubscriptionStreams',
      );
      const playbackIsSSAIEnabled = _.get(playbackData, 'isSSAIEnabled');

      const playbackItem = _.get(playbackData, 'items.item.0', {});
      const playbackUrl = _.get(playbackItem, 'url');
      const playbackProtocol = _.get(playbackItem, 'protocol', '');
      const playbackLicenseUrl = _.get(playbackItem, 'license.@uri');
      const playbackFlag = _.get(playbackItem, 'flag');
      const playbackCdn = _.get(playbackItem, 'selectedCDN');
      const ssaiProvider = _.get(playbackItem, 'ssaiProvider', '');
      const videoOrigin = _.get(playbackItem, 'videoOrigin', '');

      // Comes from either pb response or yospace sdk
      const streamUrl =
        (this.playerService && this.playerService.getFinalContentUrl()) ||
        playbackUrl;

      const playbackProps = !_.isEmpty(playbackItem)
        ? {
            streamUrl,
            pbApiStreamUrl: playbackUrl,
            sourceType: playbackProtocol,
            ssaiProvider,
            videoOrigin,
            drm: {
              url: playbackLicenseUrl,
              drmProtected: playbackDrmProtected,
            },
            videoStreamType: playbackVideoStreamType,
            isSSAISDKEnabled: this.isSSAISDKEnabled,
            isSsaiEnabled: playbackIsSSAIEnabled,
            live: playbackLive,
            cmsStreamConfig: playbackFlag,
            cdn: playbackCdn,
            streamKickingRule: playbackMaxStreams,
          }
        : {};

      // Asset
      const fieldsToPick = [
        'id',
        'title',
        'isLive',
        'isInTransition',
        'type',
        'duration',
        'publishStartDate',
        'updatedAt',
        'channel',
        'broadcastStartTime',
        'broadcastEndTime',
        'tags',
        'source',
        'isSSAIEnabled',
      ];
      const assetToTrack = _.pick(assetData, fieldsToPick);
      const assetId = assetToTrack['id'];

      // Source of truth should come from playback response if available else fallback to asset metadata
      const livestream = !_.isEmpty(playbackData)
        ? playbackLive
        : assetToTrack['isLive'];
      const isSSAIEnabledAsset = !_.isEmpty(playbackData)
        ? playbackIsSSAIEnabled
        : assetToTrack['isSSAIEnabled'];
      assetToTrack['isLive'] = livestream;
      assetToTrack['duration'] = livestream ? -1 : assetToTrack['duration'];

      // Asset
      const assetProps = !_.isEmpty(assetToTrack)
        ? {
            ...assetToTrack,
          }
        : {};

      // Device
      const userAgentData = userAgentInfo();
      const deviceData = getDeviceProps(
        (loadRequest && loadRequest.raw()) || {},
      );
      const deviceId = _.get(deviceData, 'deviceId');
      // Hardcode to Google as we cannot retrieve the device make
      const deviceMake = VENDOR;
      // const deviceModel = _.get(deviceData, 'modelName', '');
      const deviceTime = moment.utc(new Date()).format();
      const osName = _.get(deviceData, 'senderOS');
      const osVersion = _.get(deviceData, 'senderVersion');
      const advertConsent = false;
      const advertId = _.get(deviceData, 'advertId', 'NA');
      const advertIdType = '';
      const browserName = 'Chrome';
      const browserVersion = [
        _.get(userAgentData, 'major', 0),
        _.get(userAgentData, 'minor', 0),
        _.get(userAgentData, 'patch', 0),
      ].join('.');

      const deviceProps = {
        deviceId,
        deviceMake,
        deviceModel: deviceInfo(),
        deviceTime,
        osName,
        osVersion,
        advertConsent,
        advertId,
        advertIdType,
        browserName,
        browserVersion,
      };

      // Application
      const applicationProps = {
        appName: PLAYER_NAME,
        appVersion: APP_VERSION,
        environment: ENV,
        platform: PLATFORM,
        appMode: ANALYTIC_DEFAULT_VENUE_MODE,
      };

      // Mux
      const muxProps = {
        muxPluginName: MUX_NAME,
        muxPluginVersion: MUX_VERSION,
      };

      // Video Player
      const videoPlayerProps = {
        playerName: VIDEO_PLAYER_NAME,
        playerVersion: VIDEO_PLAYER_VERSION,
      };

      // Playback specific attributes
      // prettier-ignore
      let positionFloat = livestream
        ? (this.player && this.player.getDvrTime ? this.player.getDvrTime() : 0)
        : (this.player && this.player.getCurrentTime ? this.player.getCurrentTime() : 0);

      let position = this.setPosition({
        position: positionFloat,
        livestream,
      });

      const adTrackingProps = this.getAdTrackingProps(position);
      const { position: adPosition = 0 } = adTrackingProps;
      if (livestream) {
        position = this.isInAdBreak() ? adPosition : this.globalPosition;
      } else {
        positionFloat = Math.round(positionFloat - totalAdWatchedDuration);
        position = this.isInAdBreak() ? adPosition : positionFloat;
      }

      const playedDuration =
        this.player &&
        this.player.getDuration &&
        parseInt(this.player.getDuration(), 10);
      const total_length = livestream ? 0 : playedDuration;

      const sound = this.currentVolume || 'NA';
      const bitrate = this.currentBitrate || 'NA';
      const quality = this.currentQuality || 'NA';

      return {
        session_id,
        asset_id: assetId,
        // content_asset_id,
        position,
        total_length,
        content_length: total_length,
        content_or_ad,
        ad_enabled: isSSAIEnabledAsset,
        frameRate: 30,
        full_screen: true,
        sound,
        bitrate,
        quality,
        livestream,
        timezone_offset: getTimezoneOffset(),
        user: userProps,
        asset: assetProps,
        playback: playbackProps,
        device: deviceProps,
        application: applicationProps,
        mux_plugin: muxProps,
        video_player: videoPlayerProps,
        ...adTrackingProps,
        ...customProps,
      };
    }

    return {};
  };

  // Segment Identify
  handleIdentify = ({ loadRequest }) => {
    if (window.analytics) {
      const sessionLoadRequest = this.session && this.session.getRequest();
      const defaultLoadRequest =
        loadRequest || (sessionLoadRequest && sessionLoadRequest.raw());
      if (defaultLoadRequest) {
        const userId = getAnalyticUserId(defaultLoadRequest);
        window.analytics.identify(userId);
      }
    }
  };

  // Segment Video Playback Requested event
  handlePlaybackRequested = () => {
    const props = {
      ...this.getCommonTrackingProps({}),
      event: getEventTrackingProps('videoPlaybackRequested'),
      ...this.getPositions(),
    };

    this.trackEvent({
      event: segmentEvents.videoPlaybackRequested,
      props,
    });
  };

  // Segment API event (i.e. Api ML Playback)
  handleApiEvent = ({ apiUrl, segmentEventName, requestParams, response }) => {
    const httpProps = getHttpRequestResponsePayloadForApi(
      apiUrl,
      requestParams,
      response,
    );

    const eventProps = getEventTrackingProps(segmentEventName);
    const segmentName = eventProps ? eventProps.name : '';
    this.trackEvent({
      event: segmentName,
      props: {
        ...this.getCommonTrackingProps({}),
        event: eventProps,
        http: httpProps,
      },
    });
  };

  // Segment API error event
  handleApiErrorEvent = ({ segmentEventName, errorStatus }) => {
    const eventProps = getEventTrackingProps(segmentEventName);
    const errorProps = getErrorPropsForApi(errorStatus);

    this.trackEvent({
      event: eventProps.name,
      props: {
        ...this.getCommonTrackingProps({}),
        event: eventProps,
        ...errorProps,
      },
    });
  };

  // Segment Yospace event
  handleYospaceEvent = ({ yospacePlaybackUrl, yospaceError }) => {
    const { playbackData = {} } =
      (this.playerService && this.playerService.getData()) || {};
    const playbackItem = _.get(playbackData, 'items.item.0', {});
    const playbackUrl = _.get(playbackItem, 'url');

    // prettier-ignore
    const yospaceErrorProps = yospaceError ? {
      message: yospaceError || SegmentErrorMapping.videoPlaybackYoSpaceError.message
    } : {};

    const yospaceProps = {
      success: yospacePlaybackUrl && !yospaceError,
      playbackApiStreamUrl: playbackUrl,
      yospaceInitOutputUrl: yospacePlaybackUrl || '',
    };
    // Not sure if this one is required as it's tracked in subsequent Video Playback Error event
    if (yospaceError) {
      yospaceProps.error = yospaceErrorProps;
    }

    const eventProps = getEventTrackingProps('yospaceInit');

    this.trackEvent({
      event: eventProps.name,
      props: {
        ...this.getCommonTrackingProps({}),
        event: eventProps,
        yospace: yospaceProps,
      },
    });

    // Track as Video Playback Error if yospace error encountered
    if (yospaceError) {
      this.trackEvent({
        event: segmentEvents.videoPlaybackError,
        props: {
          ...this.getCommonTrackingProps({}),
          event: getEventTrackingProps('videoPlaybackYoSpaceError'),
          error: yospaceErrorProps,
        },
      });
    }
  };

  // Segment Video Playback Started event
  handlePlaybackSessionStart = () => {
    if (!this.isPlaybackStarted) {
      this.setPlaybackStarted({ started: true });
      this.setPlaybackStartTime(new Date().getTime());

      const commonTrackingProps = this.getCommonTrackingProps({});

      // We need this for instances whereby the player starts at nonzero for vods (resume events)
      this.setToPosition();
      this.setFromPosition();

      this.trackEvent({
        event: segmentEvents.videoPlaybackStarted,
        props: {
          ...commonTrackingProps,
          event: getEventTrackingProps('videoPlaybackStarted'),
        },
      });

      // Allow heartbeat to be started from now on
      this.setHeartbeatAllowed({ allowed: true });

      // @TODO: check if we need to start heartbeat with first frame or not (when content/ad started)
      if (!this.getIsInInterval()) {
        this.startInterval();
      }
    }
  };

  // handlePlayerLoadComplete = () => {
  //   this.handlePlaybackSessionStart();
  // };

  handlePlayerLoadStart = () => {
    this.handlePlaybackSessionStart();
  };

  // Handle First Frame loaded
  handleFirstFrame = () => {
    this.handlePlaybackSessionStart();

    if (!this.isFirstFrameRendered) {
      this.setFirstFrameRendered({ rendered: true });
      const { IS_LIVE } = this.getState();
      const advertMode = this.isInAdBreakMode(IS_LIVE);
      if (!advertMode && !this.isVideoContentStarted) {
        this.handleStarted();
      }
    }
  };

  // Segment Video Content/Ad Started event
  handleStarted = () => {
    this.setIsVideoContentStarted(true);
    this.trackEvent({
      event: segmentEvents['videoContentStarted'],
      props: {
        ...this.getCommonTrackingProps({}),
        event: getEventTrackingProps('videoContentStarted'),
        ...this.getPositions(),
      },
    });
  };

  // Segment error events such as Video Playback Interrupted or Video Playback Error
  handleError = ({ error = {} }) => {
    const commonTrackingProps = this.getCommonTrackingProps({});
    const { session_id } = commonTrackingProps;

    if (session_id && !_.isEmpty(error)) {
      this.setToPosition();

      const {
        code = 'GENERIC',
        message = 'GENERIC_ERROR',
        errorObj = {},
      } = error;

      const finalMessage = `
        ${code}
        ${message ? `|${message}` : ''}
        ${errorObj ? `|${JSON.stringify(errorObj)}` : ''}
      `;

      // Video error experienced before playback starts i.e. setup error
      if (!this.isPlaybackStarted) {
        this.trackEvent({
          event: segmentEvents.videoPlaybackError,
          props: {
            ...commonTrackingProps,
            event: getEventTrackingProps('videoPlaybackPlayerError'),
            error: {
              type: SegmentErrorMapping.videoPlaybackPlayerError.type,
              message: finalMessage,
            },
          },
        });
      } else {
        switch (code) {
          case 'CHUCK_NORRIS': {
            this.trackEvent({
              event: segmentEvents.videoPlaybackInterrupted,
              props: {
                ...commonTrackingProps,
                ...this.getPositions(),
                error: SegmentErrorMapping.videoPlayerKicked,
                event: getEventTrackingProps('videoPlayerKicked'),
              },
            });
            break;
          }
          case 'ZOMBIE': {
            this.trackEvent({
              event: segmentEvents.videoPlaybackInterrupted,
              props: {
                ...commonTrackingProps,
                ...this.getPositions(),
                error: SegmentErrorMapping.videoPlayerZombie,
                event: getEventTrackingProps('videoPlayerZombie'),
              },
            });
            break;
          }
          default: {
            // First Manifest/chunks loading err or vpn
            const eventKey = !this.isFirstFrameRendered
              ? 'videoStartFailure'
              : 'videoPlaybackFailure';

            const errorType = !this.isFirstFrameRendered
              ? SegmentErrorMapping.videoStartFailure.type
              : SegmentErrorMapping.videoPlaybackFailure.type;

            const props = {
              ...commonTrackingProps,
              method: JSON.stringify(error),
              ...this.getPositions(),
              error: {
                type: errorType,
                message: finalMessage,
              },
              event: getEventTrackingProps(eventKey),
            };

            this.trackEvent({
              event: segmentEvents.videoPlaybackInterrupted,
              props,
            });
          }
        }
      }
    }

    this.closeSession();
  };

  // Segment interruption events (user uncasted, user switched assets, user stopped asset)
  handleUserInterruption = ({
    source = cast.framework.events.EndedReason.STOPPED,
  }) => {
    const commonTrackingProps = this.getCommonTrackingProps({});

    if (this.isPlaybackStarted && this.isFirstFrameRendered) {
      // Normal playback and any interruption event
      this.stopHeartbeat();
      this.trackEvent({
        event: segmentEvents.videoPlaybackCompleted,
        props: {
          ...commonTrackingProps,
          event: getEventTrackingProps('videoPlaybackCompleted'),
        },
      });
    } else {
      // EBVS situations
      // 1. Playback hasn't started so Video Playback Error
      // 2. Playback has started but first frame hasn't been triggered
      const segmentEvent = !this.isPlaybackStarted
        ? segmentEvents.videoPlaybackError
        : segmentEvents.videoPlaybackInterrupted;
      // Source of the ebvs will determine what
      // specific segment event should be used for
      // event and error mapping
      const segmentMappingEvent =
        source === cast.framework.events.EndedReason.STOPPED
          ? 'videoPlayerKillStop'
          : 'videoPlayerKillInterrupt';
      this.trackEvent({
        event: segmentEvent,
        props: {
          ...commonTrackingProps,
          ...this.getPositions(),
          error: SegmentErrorMapping[segmentMappingEvent],
          event: {
            ...getEventTrackingProps(segmentMappingEvent),
            name: segmentEvent,
          },
        },
      });
    }
  };

  // Segment Video Playback Paused event
  handlePause = ({ ended }) => {
    if (!ended) {
      const commonTrackingProps = this.getCommonTrackingProps({});

      this.setToPosition();

      const { IS_LIVE } = this.getState();
      if (IS_LIVE) {
        this.setState({
          [types.LIVE_PAUSED_EPOCH_TIME]: this.globalToPosition,
        });
      }

      const props = {
        ...commonTrackingProps,
        event: getEventTrackingProps('videoPlaybackPaused'),
        ...this.getPositions(),
      };

      this.trackEvent({
        event: segmentEvents.videoPlaybackPaused,
        props,
      });

      this.clearInterval();

      this.setFromPosition();
    }
  };

  // Segment Video Playback Resumed event
  // Note: Resume that occurs from user action
  handleResume = () => {
    const commonTrackingProps = this.getCommonTrackingProps({});

    this.setToPosition();

    this.trackEvent({
      event: segmentEvents.videoPlaybackResumed,
      props: {
        ...commonTrackingProps,
        event: getEventTrackingProps('videoPlaybackResumed'),
      },
    });

    this.setFromPosition();

    // Restart heartbeat
    if (!this.getIsInInterval()) {
      this.startInterval();
    }
  };

  // Segment Video Playback Buffer Started and Completed events
  // Note: Resume from buffering will occur here so we must restart the heartbeat when that occurs
  //       but only if the player is not in PAUSED state
  handleBuffer = ({ isBuffering }) => {
    const playerState = this.player.getState();
    const commonTrackingProps = this.getCommonTrackingProps({});

    if (isBuffering) {
      // Video Playback Buffer Started
      this.setToPosition();

      const props = {
        ...commonTrackingProps,
        event: getEventTrackingProps('videoPlaybackBufferStarted'),
        ...this.getPositions(),
      };

      this.trackEvent({
        event: segmentEvents.videoPlaybackBufferStarted,
        props,
      });
      this.clearInterval();
      this.setFromPosition();
    } else if (this.globalIsBuffering && !isBuffering) {
      // Video Playback Buffer Completed
      this.setToPosition();

      this.trackEvent({
        event: segmentEvents.videoPlaybackBufferCompleted,
        props: {
          ...commonTrackingProps,
          event: getEventTrackingProps('videoPlaybackBufferCompleted'),
        },
      });

      this.setFromPosition();

      // Restart heartbeat if resuming from state that wasn't paused
      if (playerState !== 'PAUSED') {
        if (!this.getIsInInterval()) {
          this.startInterval();
        }
      }
    }

    this.setGlobalIsBuffering({ isBuffering });
  };

  // Segment Video Playback Seek Started event
  handleSeeking = ({ seekPosition }) => {
    const commonTrackingProps = this.getCommonTrackingProps({});
    const { livestream } = commonTrackingProps;
    const { LIVE_PAUSED_EPOCH_TIME } = this.getState();

    this.setToPosition();

    // We need to ensure the position is relative
    // for live dvr streams
    const seekPositionAdjusted = livestream
      ? (this.player && this.player.getDvrTime(seekPosition)) || seekPosition
      : seekPosition;
    const finalSeekPosition = livestream
      ? Math.round(new Date().getTime() / 1000 + seekPositionAdjusted)
      : Math.round(seekPositionAdjusted);
    const props = {
      ...commonTrackingProps,
      ...this.getPositions(),
      seek_position: finalSeekPosition,
      event: getEventTrackingProps('videoPlaybackSeekStarted'),
    };

    if (LIVE_PAUSED_EPOCH_TIME) {
      props.global_from_position = LIVE_PAUSED_EPOCH_TIME;
      this.globalPosition = LIVE_PAUSED_EPOCH_TIME;
      this.setState({ [types.LIVE_PAUSED_EPOCH_TIME]: 0 });
    }

    this.trackEvent({
      event: segmentEvents.videoPlaybackSeekStarted,
      props,
    });

    this.clearInterval();

    this.setFromPosition(finalSeekPosition);
  };

  // Segment Video Playback Seek Completed event
  handleSeeked = () => {
    const commonTrackingProps = this.getCommonTrackingProps({});

    this.setToPosition();

    const props = {
      ...commonTrackingProps,
      event: getEventTrackingProps('videoPlaybackSeekCompleted'),
    };

    this.trackEvent({
      event: segmentEvents.videoPlaybackSeekCompleted,
      props,
    });

    this.setFromPosition();

    const playerState = this.player.getState();
    if (playerState !== 'PAUSED') {
      if (!this.getIsInInterval()) {
        this.startInterval();
      }
    }
  };

  // Segment Video Content/Ad Completed event
  handleCompleted = () => {
    this.trackEvent({
      event: segmentEvents.videoContentCompleted,
      props: {
        ...this.getCommonTrackingProps({}),
        event: getEventTrackingProps('videoContentCompleted'),
      },
    });
  };

  // Segment Video Playback Completed event
  handlePlaybackCompleted = () => {
    this.trackFinalHeartbeat();
    this.trackEvent({
      event: segmentEvents.videoPlaybackCompleted,
      props: {
        ...this.getCommonTrackingProps({}),
        event: getEventTrackingProps('videoPlaybackCompleted'),
      },
    });
  };

  trackAdStarted = customProps => {
    const commonTrackingProps = this.getCommonTrackingProps({ customProps });
    const { livestream } = commonTrackingProps;
    !livestream && this.setToPosition();
    const props = {
      event: getEventTrackingProps('videoAdStarted'),
      ...this.getPositions(),
      ...commonTrackingProps,
    };
    this.trackEvent({ event: segmentEvents.videoAdStarted, props });
    !livestream && this.setFromPosition();
  };

  trackAdCompleted = customProps => {
    const commonTrackingProps = this.getCommonTrackingProps({ customProps });
    const { livestream } = commonTrackingProps;
    !livestream && this.setToPosition();
    const props = {
      event: getEventTrackingProps('videoAdCompleted'),
      ...this.getPositions(),
      ...commonTrackingProps,
    };
    this.trackEvent({ event: segmentEvents.videoAdCompleted, props });
    !livestream && this.setFromPosition();
  };

  trackDeviceUnsupported = customProps => {
    const commonTrackingProps = this.getCommonTrackingProps({ customProps });
    const props = {
      event: getEventTrackingProps('deviceUnsupported'),
      ...commonTrackingProps,
    };
    this.trackEvent({ event: segmentEvents.deviceUnsupported, props });
  };

  trackCustomEvent = (eventName, customProps = {}) => {
    if (!this.segmentEnabled) {
      return;
    }
    customProps.session_id = Session.generateId();
    const commonTrackingProps = this.getCommonTrackingProps({ customProps });
    const props = {
      event: getEventTrackingProps(eventName),
      ...commonTrackingProps,
    };
    this.trackEvent({
      event: segmentEvents[eventName],
      props,
    });
  };

  // All player behaviour handlers
  // Note: Some of the listeners are reused in app.js due to interdependencies
  startPlayerListeners = () => {
    if (!this.segmentEnabled) {
      return;
    }
    // Player's media has changed
    this.player.onMediaStatus(event => {
      const mediaStatus = _.get(event, 'mediaStatus', {});
      const volume = _.get(mediaStatus, 'volume.level', 1);
      const muted = _.get(mediaStatus, 'volume.muted', false);

      this.setVolume({ volume: muted ? 0 : parseInt(volume * 100, 10) });
    });

    // Player has received volume change request
    // @TODO: This requires further testing - no volume events are coming through
    this.player.setVolumeRequest(event => {
      console.log('!!!! set volume request >>', event);
      return event;
    });

    // Player's bitrate has changed
    this.player.onBitrateChanged(event => {
      const playerStats = this.player.getStats();
      const bitrate =
        _.get(event, 'totalBitrate') || _.get(playerStats, 'streamBandwidth');
      const height = _.get(playerStats, 'height');

      if (bitrate) {
        this.setBitrate({ rate: bitrate });
      }

      if (height && bitrate) {
        const bitrateKps = parseInt(bitrate / 1000, 10);
        this.setQuality({ quality: `${height}p (${bitrateKps} kps)` });
      }
    });

    // Player's finished loading media and ready to play
    // Note: This one is too late
    // this.player.onPlayerLoadComplete(() => {
    //   this.handlePlayerLoadComplete();
    // });

    // Player's first frame loaded
    this.player.onFirstFrame(() => {
      this.handleFirstFrame();
    });

    // Player pause
    this.player.onPause(event => {
      this.handlePause(event);
    });

    // Player has actually began playing
    this.player.onPlaying(() => {
      if (!this.isPlayingStarted) {
        this.setIsPlayingStarted({ started: true });
      }
    });

    // Player play
    // Usually when playback is resumed after user pauses
    this.player.onPlay(event => {
      // We should only track this in middle of actual playback
      if (this.isPlayingStarted) {
        this.handleResume();
      }
    });

    // Player buffering
    this.player.onBuffering(event => {
      if (this.isPlaybackStarted) {
        this.handleBuffer(event);
      }
    });

    // Player seeking
    this.player.onSeeking(({ currentPosition }) => {
      if (this.isPlaybackStarted) {
        this.handleSeeking({ seekPosition: currentPosition });
      }
    });

    // Player seeked
    this.player.onSeeked(event => {
      if (this.isPlaybackStarted) {
        this.handleSeeked();
      }
    });

    // Player ended
    this.player.onEnded(event => {
      this.handleCompleted();
      this.handlePlaybackCompleted();

      this.closeSession();
    });

    // Player received stop request
    this.player.onRequestStop(event => {
      this.handleUserInterruption({ source: 'STOPPED' });
    });
  };
}

export default SegmentService;
