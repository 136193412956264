import Http from './Http';
import { apiUrls } from './constants';
import {
  getUserId,
  getIdToken,
  getMediaCustomData,
  isLoggedIn,
} from './requestUtils';

export async function getUserDetails(loadRequest) {
  let resData;
  let error;

  if (isLoggedIn(loadRequest)) {
    try {
      const userId = getUserId(loadRequest);
      const idToken = getIdToken(loadRequest);

      const { data: { item = {} } = {} } = await Http.get(
        `${apiUrls.userDetails}/${userId}`,
        {
          headers: Http.createAuthHeaders(idToken),
        },
      );

      resData = item;
    } catch (err) {
      error = err;
    }
  } else {
    error = true;
  }
  return [resData, error];
}

export function getAnalyticUserId(loadRequest, userData) {
  const {
    senderDeviceId,
    deviceId,
    castDeviceId,
    analyticUserId,
  } = getMediaCustomData(loadRequest);
  if (analyticUserId) {
    return analyticUserId;
  }
  const userId = getUserId(loadRequest);
  const fallbackSenderId = senderDeviceId || deviceId || castDeviceId;
  const fallbackUserId = isLoggedIn(loadRequest) ? userId : fallbackSenderId;
  let result;

  if (userData) {
    result = userData?.analyticUserId || fallbackUserId;
  } else {
    result = fallbackUserId;
  }

  return result;
}
