import { decode } from 'js-base64';

class Token {
  isUsersSystemTimeAhead = false;
  currentTokenCreateTime = 0;

  constructor(token, segmentService = null, tokenRefreshTime) {
    this.segmentService = segmentService;
    this.set(token);
    // console.log('>>> Token will expire on', new Date(this.expiry() * 1000));
    this.segmentService?.trackCustomEvent('sessionTokenSetExpiry', {
      eventDetails: {
        tokenExpiry: new Date(this.getExpiryMs()),
      },
    });

    // check if user's system time is greater than token's expiry time
    if (Date.now() > this.getExpiryMs()) {
      this.currentTokenCreateTime = tokenRefreshTime;
      this.isUsersSystemTimeAhead = true;
    }
  }

  set(token) {
    this.token = token;
    this.setPayload(token);
  }

  setPayload(token) {
    try {
      const [, payload] = token.split('.');
      this.payload = JSON.parse(decode(payload));
    } catch (e) {
      this.segmentService?.trackCustomEvent('sessionTokenUnexpectedToken', {
        eventDetails: {
          token,
          error: e,
        },
      });

      this.payload = {};
    }
  }

  get() {
    return this.token;
  }

  getPayload() {
    return this.payload;
  }

  isExpired() {
    return this.expiresFromNow() <= 0;
  }

  getExpiryMs() {
    return this.expiry() * 1000;
  }

  expiry() {
    const { exp } = this.payload;
    return exp;
  }

  issuedAt() {
    const { iat } = this.payload;
    return iat;
  }

  getRefreshTokenValidTimeMs() {
    const refreshTokenValidTime = (this.expiry() - this.issuedAt()) * 1000;
    if (
      !refreshTokenValidTime ||
      isNaN(refreshTokenValidTime) ||
      refreshTokenValidTime <= 0
    ) {
      return 10800 * 1000;
    }
    return refreshTokenValidTime;
  }

  expiresFromNow() {
    const currentTime = Date.now();

    if (this.isUsersSystemTimeAhead) {
      return (
        this.currentTokenCreateTime +
        this.getRefreshTokenValidTimeMs() -
        currentTime
      );
    }
    return this.getExpiryMs() - currentTime;
  }

  getTimeoutBeforeExpiry(timeCheckBeforeExpiry) {
    return this.expiresFromNow() - timeCheckBeforeExpiry;
  }
}

export default Token;
