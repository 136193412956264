const deviceInfo = () => {
  // https://developers.google.com/cast/docs/media#video_codecs
  try {
    const { hardwareConcurrency, userAgent } = window.navigator;
    const context = cast.framework.CastReceiverContext.getInstance();

    // Chromecast with Google TV supports 'H.264 High Profile, level 5.1'
    if (context.canDisplayType('video/mp4; codecs="avc1.640033'))
      return 'Chromecast with Google TV';
    // Android Devices with Chromecast built-in
    if (userAgent.includes('Android')) return 'Chromecast Android';
    // Chromecast Ultra supports 'HEVC main profile, level 3.1'
    if (context.canDisplayType('video/mp4; codecs=hev1.1.6.L93.B0'))
      return 'Chromecast Ultra';
    // 3rd generation Chromecast supports 'H.264 high profile, level 4.2'
    if (context.canDisplayType('video/mp4; codecs=avc1.64002A'))
      return 'Chromecast 3';
    // 2nd and 1st generation Chromecast can be differentiated by hardwareConcurrency
    if (hardwareConcurrency === 2) return 'Chromecast 2';
    if (hardwareConcurrency === 1) return 'Chromecast 1';
  } catch (e) {
    // do nothing
  }
  return 'Chromecast';
};

export default deviceInfo;
