export function disableBGScroll() {
  document.body.style.overflow = 'hidden';
}

export function enableBGScroll() {
  document.body.style.overflow = '';
}

export const getSelectorStyle = (indexValue, containerIndex) => {
  let result;
  let initialTop;
  let initialMargin;
  let initialTotal;
  let height = 0;

  if (containerIndex === 0) {
    initialTop = 72;
    initialMargin = 16;
    initialTotal = 144;
    height = 144;
  }

  if (containerIndex === 1) {
    initialTop = 144;
    initialMargin = 32;
    initialTotal = 288;
    height = 320;
  }

  if (containerIndex === 2) {
    initialTop = 288;
    initialMargin = 64;
    initialTotal = 288 * 2;
    height = 640;
  }

  if (indexValue === 0) {
    result = { top: initialTop, right: '-16px', height: `${height}px` };
  } else {
    const currentTotal = initialTotal * indexValue;
    const topValue =
      currentTotal + initialTop + (initialMargin + 10 * (indexValue - 2));
    result = { top: `${topValue}px`, right: '-16px', height: `${height}px` };
  }

  return result;
};

export const showConnectorLine = (compId, selectedRound, containerIndex) => {
  switch (compId) {
    default:
    case 5:
      return selectedRound !== 'R16' && containerIndex === 0;
    case 6:
      return selectedRound !== 'R32' && containerIndex === 0;
  }
};

export const getParameterByName = (name, url = window.location.href) => {
  const newName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getManifestType = url => {
  const sourceStreamTypes = ['dash', 'hls'];
  const fileExtension = url.split(/#|\?/)[0].split('.').pop();
  return fileExtension === 'm3u8' ? sourceStreamTypes[1] : sourceStreamTypes[0];
};

export const checkIsNeededId3Type = type => {
  const id3Types = ['YMID', 'YDUR', 'YSEQ', 'YTYP', 'YCSP'];
  return id3Types.includes(type);
};
