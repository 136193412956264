const getYSSourceUrl = (protocol, sourceUrl, yspSdkV3) => {
  // const tempYoLsFix = `&yo.ls=${encodeURIComponent('2,3,5,6')}`;
  const yoPoRequired = protocol === 'dash' ? 'yo.po=1' : '';
  if (yoPoRequired) {
    return /\?/.test(sourceUrl)
      ? `${sourceUrl}&${yoPoRequired}`
      : `${sourceUrl}?${yoPoRequired}`;
  }

  return sourceUrl;
};

export default getYSSourceUrl;
