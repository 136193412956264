const nodes = [
  {
    elem: 'div',
    attr: 'ad__wrapper',
    text: '',
    style: {
      position: 'relative',
      'max-height': '100%',
      'max-width': '100%',
      height: '100vh',
      width: '100%',
    },
  },
  {
    elem: 'div',
    attr: 'ad__overlay',
    text: '',
    style: {
      position: 'absolute',
      bottom: '0px',
      left: '-8px',
      width: 'calc(100% + 16px)',
      height: '100%',
      maxHeight: 'calc(35% + 7px)',
      'object-fit': 'contain',
      'background-image':
        'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5))',
    },
  },
  {
    elem: 'div',
    attr: 'ad__content',
    text: '',
    style: {
      left: '5%',
      bottom: '5%',
      'background-color': 'transparent',
      position: 'absolute',
      width: '230px',
      height: '56px',
    },
  },
  {
    elem: 'div',
    attr: 'ad__text',
    style: {
      color: 'white',
      'font-size': '32px',
      'line-height': 'normal',
      'text-align': 'left',
      'font-family': 'Maven Pro, sans-serif',
    },
  },
];

export default nodes;
