/* eslint-disable */
const customElement = name => (callback = () => {}) => {
  class CustomElement extends HTMLElement {
    constructor() {
      super();
      this.attachShadow({ mode: 'open' });
    }
  }

  customElements.get(name) || customElements.define(name, CustomElement);
  const ref = document.querySelector(name);
  if (!ref) {
    return;
  }
  const refRoot = ref.shadowRoot;
  callback(refRoot);
};

export default customElement;
