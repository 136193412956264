const showNativeControls = isActive => target => {
  const element = target.querySelector(
    '.foreground .overlay .controls .controlsPlayPause',
  );
  if (!isActive && element) {
    if (element.style.display === 'none') {
      return;
    }
    element.style.setProperty('display', 'none');
  }
  if (isActive && element) {
    if (element.style.display === 'block') {
      return;
    }
    element.style.setProperty('display', 'block');
  }
};

export default showNativeControls;
