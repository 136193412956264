const validateNode = attr => target => {
  if (!attr || !target) {
    return false;
  }
  const targetNode = target.querySelector(`#${attr}`);
  // If target node does not exist continue
  if (!targetNode) {
    return false;
  }
  return true;
};

export default validateNode;
