const getYSDuration = prop => obj => {
  const items = obj[prop];
  if (!items || !items.length) {
    return 0;
  }
  const result = items.reduce((acc, curr) => {
    return acc + curr.duration;
  }, 0);
  return result;
};

export default getYSDuration;
